
import NavMenuItemModel from "../../../../models/NavMenuItemModel";
import SubMenu from "./SubMenu";
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import LinkDataTypeEnum from "../../../../enums/LinkDataTypeEnum";
import { Fragment } from "react";

interface IMenuItem extends NavMenuItemModel{
    index: Readonly<number>,
    activeIndex: Readonly<number> | Readonly<null>,
    setActiveIndex?: { (index: number | null): void },
    indexCount: Readonly<number>,
}

export default function MenuItem (props : IMenuItem){
    const {index, activeIndex, label, links, promoContent, callToAction,
        titleLink, setActiveIndex = (index: number | null) => undefined} = props || {};

    const isActive = (index === activeIndex);
    const toggleOn = () => setActiveIndex(index);
    const toggleOff = () => setActiveIndex(null);
    const onMouseOver = (e:React.MouseEvent)=>{
        const isTouchScreen = 'ontouchstart' in window;
        if (!isTouchScreen) {
            toggleOn();
        }
    }
    const hasSubmenu = !!links && links?.length > 0;
    const isCTAButton = !hasSubmenu && !titleLink;
    const onClick = (e:React.UIEvent)=>{
        if(hasSubmenu){
            e.preventDefault();
            if(index !== activeIndex){
                toggleOn();
            }
            else if(index === activeIndex){
                toggleOff();
            }
        }
    }
    let smartLink = new SmartLinkModel({
        type: isCTAButton ? LinkTypeEnum.PrimaryButton : LinkTypeEnum.Default,
        linkText:label,
        url: hasSubmenu ? '' : (titleLink?.url || callToAction?.url || '')
    });
    return (
        <Fragment>
            <li className={`menu_item ${hasSubmenu ? 'hasSubMenu' : ''} ${isActive ? 'focus' : ''}`} role="menuitem" id={`menuItem-${index}`} 
                onFocus={()=>{
                    setActiveIndex(index);
                }}
            >
                <SmartLink aria-controls="resources-menu" aria-haspopup="true"
                   {...{...smartLink, dataType: hasSubmenu ? LinkDataTypeEnum.Scripted : smartLink.dataType, className:`menu_link ${hasSubmenu ? 'noclick' : ''}`, onMouseOver, onClick}}
                />

                {hasSubmenu &&
                    <SubMenu {...{isActive, label, onMouseOver, index, links, activeIndex, promoContent, titleLink, callToAction}} />
                }
            </li>
        </Fragment>
    );
}

