import { useEffect, useRef, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import countLines from "../_helpers/countLines";
import {Document} from "@contentful/rich-text-types";
import TruncateText from "./TruncateText";

interface IDynamicClamp{
    content: Document | null | undefined,
    windowWidth: number,
    maxLines?: number,
    parentShouldReadMore:Function
}
const addListLines = (el: Element, numberOfLines: number, maxLines: number, setSubIndex: Function, setDamper: Function) => {
    let _updatedOnce = false;
    for (var i = 0; i < el.childNodes.length; i++) {
        const subBlockLines = countLines(el.childNodes[i] as Element); 
        numberOfLines = numberOfLines + subBlockLines + 1;
        const linesOverBy = numberOfLines - maxLines;
        if(linesOverBy >= 0 && !_updatedOnce){
            setSubIndex(i);
            setDamper(maxLines/numberOfLines);
            _updatedOnce = true;
        }
    }
    return numberOfLines;
}
// Outer component measures where the truncation should occur

const DynamicClamp = ({content, maxLines = 4, windowWidth, parentShouldReadMore}: IDynamicClamp)=>{

    const divRef = useRef<HTMLDivElement>(null);
    const [shouldReadMore, setShouldReadMore] = useState(false);
    const [blockIndex, setBlockIndex] = useState(0);
    const [subBlockIndex, setSubBlockIndex] = useState(maxLines); // List items can never be greater than the max
    const [dampingRatio, setDampingRatio] = useState(1);

    useEffect(()=>{
        
        const lineCount = countLines(divRef.current);

        if(lineCount > maxLines){
            // Measure each block level item to determine which block to cut
            let someLines = 0
            const { childNodes } = divRef.current?.firstChild ?? {childNodes:[]};
            for (var i = 0; i < childNodes.length; i++) {
                const htmlElement = childNodes[i] as Element;
                if(htmlElement.nodeName === "UL"){
                    if(someLines > 0){
                        // ACCOUNT FOR SPACE IN BEWTEEN P AND UL
                        someLines += 1;
                        if(someLines - maxLines === 0){
                            setBlockIndex(i -1);
                            break;
                        }
                    }
                    const listLines = addListLines(htmlElement, someLines, maxLines, setSubBlockIndex, setDampingRatio);
                    someLines += listLines;
                    if(someLines - maxLines >= 0){
                        setBlockIndex(i);
                        break;
                    }
                }else{
                    someLines += countLines(htmlElement);
                    const linesOverBy = someLines - maxLines;
                    if(linesOverBy >= 0){
                        setBlockIndex(i);
                        setDampingRatio(maxLines/someLines);
                        break;
                    }
                }
            }
            parentShouldReadMore(true);
            setShouldReadMore(true);
        }else{
            parentShouldReadMore(false);
            setShouldReadMore(false);
        }
    },[setShouldReadMore, windowWidth, maxLines, parentShouldReadMore]);   
    return <div ref={divRef}>
        {shouldReadMore ? 
        <TruncateText {...{windowWidth, blockIndex, subBlockIndex, maxLines, dampingRatio}}>
            { content && documentToReactComponents(content, ContentfulReactRenderOptions)}
        </TruncateText> 
        : 
        <div>
            { content && documentToReactComponents(content, ContentfulReactRenderOptions)}
        </div>}
    </div>
}

export default DynamicClamp;