import ScriptedDomIdsEnum from "../../../../enums/ScriptedDomIdsEnum";

interface headerDomRefs{
    alerts: HTMLElement | null,
    desktopHeader: HTMLElement | null,
    mobileHeader: HTMLElement | null,
    toolbar: HTMLElement | null
}

export default function getHeaderDomRefs():headerDomRefs{
    return{
        alerts: document.getElementById(ScriptedDomIdsEnum.Alerts),
        desktopHeader: document.getElementById(ScriptedDomIdsEnum.DesktopHeader),
        mobileHeader: document.getElementById(ScriptedDomIdsEnum.MobileHeader),
        toolbar: document.getElementById(ScriptedDomIdsEnum.Toolbar)
    }
}