import IClientServerModel from "../interfaces/IClientServerModel";
import ContentBlockModel from "./ContentBlockModel";
import ContentTypesEnum from "../enums/ContentTypesEnum";
import CalculatorTopicModel from "./CalculatorTopicModel";

export default class CalculatorModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public readonly contentType?: ContentTypesEnum | undefined;
    public readonly calculatorCode?: string | undefined | null;
    public readonly contentBlock?: ContentBlockModel | undefined;
    public readonly header?: string;
    public readonly topicCalculator: CalculatorTopicModel

    constructor(calculatorModel: Readonly<CalculatorModel> | undefined | null){
        const {htmlId = "", id, header="", topicCalculator, contentType, contentBlock} = calculatorModel || {};
        this.htmlId = htmlId;
        this.id = id;
        this.contentType = contentType;
        this.header = header;
        this.topicCalculator = new CalculatorTopicModel(topicCalculator);
        this.contentBlock = contentBlock;
    }
}
