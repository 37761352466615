import CategoryCardListModel from "./CategoryCardListModel";
import PromotionModel from "./PromotionModel";
import CompareTableModel from "./CompareTableModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import TestimonialCarouselModel from "./TestimonialCarouselModel";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import DisclosureItemModel from "./DisclosureItemModel";
import CalculatorModel from "./CalculatorModel";
import IEnrichment from "../interfaces/IEnrichment";

export default class ComparePageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly title?: string;
    public readonly calculator?: CalculatorModel | null;
    public readonly hero?: PromotionModel | null  | undefined;
    public readonly compareTable?: CompareTableModel | null | undefined;
    public readonly testimonials?: null | Readonly<TestimonialCarouselModel>;
    public readonly relatedTools?: Readonly<CategoryCardListModel> | null | undefined;
    public readonly disclosures?: DisclosureItemModel[];


    constructor(props:Readonly<ComparePageModel> | null | undefined) {
        const {htmlId, hero, compareTable, testimonials, templateType, relatedTools, 
            navigationTheme, title, description, enrichments = [], keywords = [], disclosures = [], calculator} = props || {};
        this.templateType = templateType || null;
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description;
        this.title = title;
        this.htmlId = htmlId;
        this.calculator = new CalculatorModel(calculator);
        this.hero = new PromotionModel(hero ?? null);
        this.compareTable = new CompareTableModel(compareTable);
        this.testimonials = new TestimonialCarouselModel(testimonials);
        this.relatedTools = new CategoryCardListModel(relatedTools ?? null);
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
    }
}

