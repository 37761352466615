import { MapEnrichmentNames } from "./MapEnrichmentNames";

export type Criteria = {
    l: string;
    op: string;
    r: string;
}

export const MapPersonalizationArray = (crit: Criteria[]): string => {
    if(!crit) return "";
    let response = "";
    let i = 0;
    crit.forEach(item => {
        i++;
        let name = MapEnrichmentNames(item.l);
        if (response !== "") {
            response = response + `, ${name} ${item.op} ${item.r}`
        } else {
            response = `${name} ${item.op} ${item.r}`
        }      
    });

    return response;
}