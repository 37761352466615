import ImageListItemModel from "./ImageListItemModel";
import IClientServerModel from "../interfaces/IClientServerModel";
import ContentBlockModel from "./ContentBlockModel";

export default class ImageListModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly content: ImageListItemModel[];
    public readonly contentBlock?: ContentBlockModel | null | undefined;
    public readonly header?: string | undefined;
    public readonly subheader?: string | undefined;
    public readonly id?: string | undefined;
    constructor(props:ImageListModel | null | undefined){
        const {htmlId = "",  content = [], header, subheader, id} = props || {};
        this.htmlId = htmlId;
        this.header = header;
        this.subheader = subheader;
        this.id = id;
        this.content = content?.map(item => new ImageListItemModel(item));
    }
}