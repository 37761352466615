import ImageModel from "./ImageModel";
import LinkTypeEnum from "../enums/LinkTypeEnum";
import LinkDataTypeEnum from "../enums/LinkDataTypeEnum";
import LinkTargetValueEnum from "../enums/LinkTargetValueEnum";


export type clickEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>;

class SmartLinkModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly icon?: ImageModel | null = null;
    public readonly linkText?: string | null = null;
    public readonly defaultLinkText?: string | null = "";
    public readonly url?: string = "";
    public readonly type?: LinkTypeEnum = LinkTypeEnum.Default;
    public readonly dataType?: LinkDataTypeEnum;
    public readonly htmlId?: string | undefined = "";
    public readonly dataCTA?: string | undefined = "";
    public readonly accessibilityDescription?: string = "";
    public readonly showRedirectModal?: boolean = false;
    public readonly externalLink?: boolean = false;
    public readonly target?: LinkTargetValueEnum = LinkTargetValueEnum.Default;
    public readonly tabIndex?: number | undefined;
    public readonly onClick?: (event: clickEvent) => void | undefined;

    constructor(contentfulLinkModel: Readonly<SmartLinkModel> | undefined | null) {

        if(!!contentfulLinkModel) {
            const {icon, linkText, defaultLinkText, htmlId, url, type, accessibilityDescription, showRedirectModal, target, tabIndex, externalLink} = contentfulLinkModel || {};
            const isTelephoneNumber = (url || "").startsWith("tel:");
            const isEmail = (url || "").startsWith("mailto:");
            const isFullUrl = ["http://", "https://", "ftp://", "ftps://"].some(prefix => (url || "").startsWith(prefix));
            const isModal = (url || "").startsWith("modal:");
            const isAnchorLink = (url || "").startsWith("#");
            const isRelativeUrl = !(isTelephoneNumber || isEmail || isFullUrl || isModal || isAnchorLink);

            if(isTelephoneNumber){
                this.dataType = LinkDataTypeEnum.Phone;
            }
            else if (isAnchorLink){
                this.dataType = LinkDataTypeEnum.AnchorLink;
            }
            else if(isEmail){
                this.dataType = LinkDataTypeEnum.Email;
            }
            else if(isFullUrl){
                this.dataType = LinkDataTypeEnum.FullURL;
            }
            else if(isRelativeUrl){
                this.dataType = LinkDataTypeEnum.RelativeURL;
                this.onClick = (e:React.UIEvent)=>{
                    // @ts-ignore
                    window.dataLayer?.push({
                        event: 'page_view',
                        pageUrl: url,
                        linkText: defaultLinkText || linkText
                    });
                };
            }
            else if(isModal){
                this.dataType = LinkDataTypeEnum.ModalLink;
            }
            else{
                this.dataType = LinkDataTypeEnum.Unspecified;
            }

            this.icon = !!icon ? new ImageModel(icon) : null;
            this.linkText = linkText;
            this.defaultLinkText = defaultLinkText;
            this.url = url;
            this.htmlId = htmlId;
            this.type = type || LinkTypeEnum.Default;
            this.accessibilityDescription = accessibilityDescription;
            this.showRedirectModal = showRedirectModal || externalLink;
            // @ts-ignore
            if(target === "Current Window" || target === LinkTargetValueEnum.CurrentTab) {
                this.target = LinkTargetValueEnum.CurrentTab;
            }
            // @ts-ignore
            else if(target === "New Tab" || target === LinkTargetValueEnum.NewTab) {
                this.target = LinkTargetValueEnum.NewTab;
            }
            else {
                this.target = LinkTargetValueEnum.Default;
            }
            this.tabIndex = tabIndex;
        }
    }
}

export default SmartLinkModel;