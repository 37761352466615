
import styles from './Feature.module.scss';
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import FeatureModel from "../../../models/FeatureModel";
import IComponentProps from "../../../interfaces/IComponentProps";
import ContentfulReactRenderOptions from '../../_shared/ContentfulReactRenderOptions';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export function FeatureRender(props: (FeatureModel & IComponentProps)){
    const {richTextFeature1, richTextFeature2, htmlId, id, backgroundImage} = props;
    return (
        <div id={htmlId} className={`layout ${styles['feature']}`} data-contentid={id} style={{backgroundImage: backgroundImage ? `url(${backgroundImage?.imageURL})`: ""}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={`${styles['feature-container']}`}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-5">
                                    <div className={styles["feature-content"]}>
                                    {richTextFeature1 && documentToReactComponents(richTextFeature1, ContentfulReactRenderOptions)}
                                    </div>
                                </div>
                                <div className="col-1 d-flex flex-row justify-content-center">
                                    <hr />
                                </div>
                                {/* <div className={`d-none d-lg-block ${styles.div}`} /> */}
                                <div className="col-12 col-lg-5 d-flex flex-row align-items-center">
                                    <div className={styles["feature-content"]}>
                                        {richTextFeature2 && documentToReactComponents(richTextFeature2, ContentfulReactRenderOptions)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withErrorBoundary(FeatureRender);


