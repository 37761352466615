enum CategoryCardTypeEnum{
    // @ts-ignore
    Default = "Small",
    // @ts-ignore
    Large = "Large",
    // @ts-ignore
    Small = "Small"
}

export default CategoryCardTypeEnum;
