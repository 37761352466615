    
import IComponentProps from "../../../interfaces/IComponentProps";
import BiographyPageModel from '../../../models/BiographyPageModel';
import { BioCardList } from '../../components/BioCardList/BioCardList';
import CategoryCardList from '../../components/CategoryCardList/CategoryCardList';
import DynamicForm from "../../components/Form/DynamicForm";
import Promotional from '../../components/Promotional/Promotional';
import stylesBase from '../../components/Promotional/_HeroBase.module.scss';

export default function BiographyPage(props: BiographyPageModel & IComponentProps){
    
    let { htmlId="", className, hero, relatedTools, bioCards, form } = props;
    let { background } = relatedTools ?? {};

    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero} className={`${stylesBase["c-hero-gradient"]}`}/>
            </section>
            <section>
                <BioCardList bioCards={bioCards} />
            </section>
            {form && <section>
                <DynamicForm {...form} />
            </section>}
            <section style={{backgroundImage: `url(${background?.imageURL})`}}>
                <CategoryCardList {...relatedTools} />
            </section>

        </div>
    );
}
