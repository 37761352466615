import wait from "./wait/wait";
export interface IScriptConfigModel{
    scriptText?: string,
    htmlID: string,
    src?: string,
    destination?: HTMLElement | HTMLScriptElement | Element | null,
    type?:string,
    checkIsScriptRun?: Function
}

export default function createScript(config: IScriptConfigModel){

    config = config ?? {};
    let {htmlID, checkIsScriptRun} = config;
    if(!!htmlID){
        let oldScript = document.getElementById(htmlID)
        if(!!oldScript){
            oldScript.remove();
        }
    }
    let {scriptText, type, src, destination = document.head} = config;
    let scriptBlock = document.createElement('script');
    scriptBlock.type = type || 'text/javascript';
    if(htmlID){
        scriptBlock.id = htmlID;
    }
    if(src){
        scriptBlock.src = src;
    }
    scriptBlock.async = false;//ensures script order is correct
    if(scriptText) {
        try {
            scriptBlock.appendChild(document.createTextNode(scriptText));
        } catch (e) {
            scriptBlock.innerHTML = scriptText;
        }
    }
    destination?.appendChild(scriptBlock);

    if(checkIsScriptRun === undefined){
        return Promise.resolve();
    }
    else{
        return wait.forIsValid(checkIsScriptRun);
    }

}