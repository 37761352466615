
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import ContentBlockModel from "../../../models/ContentBlockModel";

export function ContentBlock({id, htmlId, richText}: ContentBlockModel){
    return <div id={htmlId} data-contentid={id}>
        { richText && documentToReactComponents(richText, ContentfulReactRenderOptions)}
    </div>
}

export default withErrorBoundary(ContentBlock);