import ApplyModalBody from "./ApplyBody";
import ApplyCCBody from "./ApplyCCBody";
import BioModalBody from "./BioModalBody";
import CookiesBody from "./CookiesBody";
import LocationBody from "./LocationBody";
import _envDataConfig from "./../../../../EnvDataConfig/EnvDataConfig";
import envDataConfig from "./../../../../EnvDataConfig/EnvDataConfig";

interface IBioModalBody {
    modalType?: string | undefined;
    body?: any;
    header?: string;
    subHeader?: any;
    altText?: string;
    image?: any;
    link?: any;
}

export function ModalBody(props: IBioModalBody){

    const { modalType, body, header, altText, image, subHeader, link } = props;
    const bioModalBody = { body, header, subHeader, altText, image, link };

    switch (modalType){
        case "Location":
            return <LocationBody />
        case "Bio":
            return <BioModalBody {...bioModalBody} />
        case "Cookies":
            return <CookiesBody />
        case "ApplyCC":
            return <ApplyCCBody applyLink={envDataConfig.ApplyLink} />
        default:
            return <ApplyModalBody />
    }
}
export default ModalBody;