import SitemapItemModel from "./SitemapItemModel";

export default class SitemapModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id?: string | undefined;
    public readonly contentType?: string | undefined;
    public readonly contentTitle?: string | undefined;
    public readonly links: SitemapItemModel[][] | undefined;

    constructor(props: Readonly<SitemapModel> | undefined | null){
        const {id, links, contentType, contentTitle } = props || {};
        this.id = id;
        this.contentTitle = contentTitle;
        this.contentType = contentType;
        this.links = links?.map(link => link?.map(x=> new SitemapItemModel(x)));
    }
}
