
import { useEffect, useRef } from "react";
import stylesBase from "./Locator.module.scss";


const hasHoursOfOperation = (monday: string, tuesday: string, wednesday: string, thursday: string, friday: string, saturday: string, sunday: string) => {
    return !!(monday ?? tuesday ?? wednesday ?? thursday ?? friday ?? saturday ?? sunday);
}

function LocationInfo(props: any) {

    const { activeIndex, monday, tuesday, wednesday, thursday, friday, saturday, sunday, name, driveThruHours, atmDescription, contactDetail } = props;
    const hasHours = hasHoursOfOperation(monday, tuesday, wednesday, thursday, friday, saturday, sunday);
    const hasLocationInfo = activeIndex !== -1 && hasHours;

    const additionalDetailsRef = useRef<HTMLDivElement>(null);
    const contactDetailRef = useRef<HTMLDivElement>(null);

    
    useEffect(()=>{
        if(additionalDetailsRef.current && driveThruHours){
            additionalDetailsRef.current.innerHTML = driveThruHours;
        }
        if(contactDetailRef.current && contactDetail){
            contactDetailRef.current.innerHTML = contactDetail;
        }
    },[additionalDetailsRef, driveThruHours, contactDetailRef, contactDetail])

    return (hasLocationInfo ? <div className={`${stylesBase["location-info"]} active`}>
        <div className="row">
            <div className="col-md-12"></div>
        </div>
        <div className="row">
            <div className="col-sm-4">
                {hasHours && <div className={`${stylesBase["location-data"]}`}>
                    <div className={`${stylesBase["loc"]}`}>
                        <span>{name}</span>
                    </div>
                    <div className={`${stylesBase["location-data-item"]}`}>
                        <h2 className="mt-3">Hours of Operation</h2>
                        <div>Monday: {monday}</div>
                        <div>Tuesday: {tuesday}</div>
                        <div>Wednesday: {wednesday}</div>
                        <div>Thursday: {thursday}</div>
                        <div>Friday: {friday}</div>
                        <div>Saturday: {saturday}</div>
                    </div>
                </div>}
            </div>
            <div className="col-sm-8">
                <div className={`${stylesBase["location-data"]}`}>
                    {!!driveThruHours && <div className={`${stylesBase["location-data-item"]} addcol`}>
                        <br/>
                        <h2 className="mt-3">Additional Details</h2>
                        <b>Drive-Thru Hours</b><br />
                        <div ref={additionalDetailsRef} /><br />
                        {atmDescription && <div>{atmDescription}</div>}
                    </div>}
                </div>
                {!!contactDetail && <div className={`${stylesBase["location-data"]}`}>
                    {<div className={`${stylesBase["location-data-item"]}`}><br />
                        <b>Contact Numbers</b><br />
                        <div ref={contactDetailRef} /><br/>
                    </div>}
                </div>}
            </div>
        </div>
    </div> : <></>);
}
export default LocationInfo