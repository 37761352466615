import {useEffect, useState} from "react";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import styles from "./Anchors.module.scss";
import {connect} from "react-redux";
import AnchorsModel from "../../../models/AnchorsModel";
import IComponentProps from "../../../interfaces/IComponentProps";
import SmartLink from "../_shared/SmartLink/SmartLink";
import NavMenuModel from "../../../models/NavMenuModel";
import NavToolbarModel from "../../../models/NavToolbarModel";
import IThemedDisplay from "../../../interfaces/IThemedDisplay";
import HorizontalScroll from "../_shared/HorizontalScroll/HorizontalScroll";
import SmartLinkModel from "../../../models/SmartLinkModel";
import { RootState } from "../../../redux/store";
import { globalContentDuck_IReducerStateModel } from "../../../redux/slices/globalSlice";

interface IMapStateProps extends NavMenuModel, NavToolbarModel, IThemedDisplay{
    lastScrollPosition: number,
    isSticky: boolean,
    anchorsSpacerHeight: number,
    isCentered: boolean,
    headerSpacerHeight: number
    isScrolledPastAnchors: boolean,
    isDesktopView: boolean
}

export const mapStateToProps = (state: RootState): IMapStateProps => {
    const global: globalContentDuck_IReducerStateModel = state.global || {};
    const {scroll, header, desktopMenu} = global;
    let {headerSpacerHeight} = header;
    const {lastScrollPosition} = scroll;
    const anchorsContentWidth = document?.querySelector('.anchors .link-list ul')?.clientWidth || 0;
    const anchorsContainerWidth = document?.querySelector('.anchors .scrollable-horizontal ')?.scrollWidth || 0;
    const isCentered = anchorsContentWidth >= anchorsContainerWidth;
    const anchorsSpacer: HTMLElement | null = document?.querySelector('.anchors-spacer');
    const anchors: HTMLElement | null = document.querySelector(".anchors");
    // anchorsOffsetTop: Calculate offset, makes the first tab scroll correctly
    let anchorsOffsetTop = (anchorsSpacer?.offsetTop  || 0) + (desktopMenu?.menuHeight || 64) + 48;
    const anchorsHeight = anchors?.clientHeight || 0;
    let isSticky = false;
    let anchorsSpacerHeight = 0;
    let isDesktopView = window.innerWidth > 991;
    let isScrolledPastAnchors = lastScrollPosition >= anchorsOffsetTop;
    headerSpacerHeight = isScrolledPastAnchors ? headerSpacerHeight : 0;

    if(isScrolledPastAnchors && isDesktopView) {
        isSticky = true;
        anchorsSpacerHeight = anchorsHeight;
    }

    return {
        isSticky,
        anchorsSpacerHeight,
        lastScrollPosition,
        isCentered,
        headerSpacerHeight,
        isDesktopView
    } as IMapStateProps;
};
enum KeyCode {
    Right = 39,
    Left = 37
}

const keyDownHandler = (e: any, startingFocus: number, numberOfTabs: number, setTabFocus: Function) => {
    let tabFocus = startingFocus;
    switch(e.keyCode){
        case KeyCode.Right:
            tabFocus += 1;
            // If we're past the end, move to the start
            if (tabFocus >= numberOfTabs) {
                tabFocus = 0;
            }
            break;
        case KeyCode.Left:
            tabFocus -= 1;
            // // If we're past the start, move to the end
            if (tabFocus < 0) {
                tabFocus = numberOfTabs - 1;
            }
            break;
        default:
            return;
    }
    const tabNeedingFocus = document.getElementById(`tab-${tabFocus}`);
    const tabLink = tabNeedingFocus?.lastChild as HTMLLinkElement
    tabLink?.focus();
    setTabFocus(tabFocus);
}
export function AnchorsRender(props: AnchorsModel & IComponentProps & IMapStateProps)  {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [tabFocusIndex, setTabFocusIndex] = useState(0);
    let { htmlId, className, anchorLinks:links, isSticky, isCentered, anchorsSpacerHeight, id, headerSpacerHeight, lastScrollPosition, isDesktopView}=props;

    useEffect(()=>{
        Array.from(document.getElementsByClassName("smartlink_anchorlink")).forEach((el:any, index: number)=>{
            const sectionId = el.href.split("#")[1];
            const section = document.getElementById(sectionId)?.getBoundingClientRect();
            const currentView =  (window.innerHeight || document.documentElement.clientHeight) - 450;
            if(section){
                if(section.top < currentView && currentView < section.bottom){
                    setSelectedIndex(index);
                    const tabNeedingFocus = document.getElementById(`tab-${index}`);
                    if(isDesktopView) {
                        const tabLink = tabNeedingFocus?.lastChild as HTMLLinkElement
                        tabLink?.focus();
                    }
                    
                    setTabFocusIndex(index);
                }
            }
        });
    },[lastScrollPosition, setSelectedIndex, setTabFocusIndex, isDesktopView]);

    // menuHeight is used to align anchor bar with navigation menu

    return (links && links.length > 0 ? <div onKeyDown={(e)=>keyDownHandler(e, tabFocusIndex, links?.length ?? 0, setTabFocusIndex)}>
        <div 
            style={{height:`${anchorsSpacerHeight}px`}} 
            className={`anchors-spacer ${isSticky ? 'active' : ''}`}>
        </div>
        <div 
            id={htmlId} 
            className={`layout ${styles.anchors} ${className} ${isSticky ? "isSticky" : ""} anchors`} 
            data-contentid={id} 
            style={{top:headerSpacerHeight}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="visually-hidden">This page contains the following content:</div>
                        <HorizontalScroll>
                            <div className={`link-list ${className || ""} ${isCentered ? "alignCenter" : "alignLeft"}`}>
                                <ul>
                                    {links.map((link: SmartLinkModel, index: number) => {
                                        return (<li key={index} id={`tab-${index}`}>
                                                    <SmartLink 
                                                        {...new SmartLinkModel(link)} 
                                                        onClick={(e)=>{
                                                            setSelectedIndex(index);
                                                            setTabFocusIndex(index);
                                                        }}
                                                        className={`${selectedIndex === index ? 'active' : ''}`}/>
                                                </li>)
                                        })}
                                </ul>
                            </div>
                        </HorizontalScroll>
                    </div>
                </div>
            </div>
        </div>
    </div>
    : <></>);
}

export default withErrorBoundary(connect(mapStateToProps,)(AnchorsRender));