import store from "../../../../redux/store";
import { errorBoundaryCatch } from "../../../../redux/slices/systemSlice";
import { Component } from "react";

export default class ErrorBoundary extends Component {
    constructor(props) {
        try {
            super(props);
            this.state = {hasError: false};
        } catch (ex) {
            console.log("Error Boundary Error", ex)
        }
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        try {
            store.dispatch(errorBoundaryCatch(error.message))
        } catch (ex) {
            console.log("Error Boundary Error", ex)
        }
    }

    render() {
        if (this.state.hasError === false) {
            return this.props.children;
        } else {
            return <h1>Something went wrong</h1>;
        }
    }
}