export const MapEnrichmentNames = (code: string): string => {
    switch(code) {
        case "1_1":
            return "[Enrichment - 1_1] Credit Cards: Signature Cash Back"
        case "1_2":
            return "[Enrichment - 1_2] Credit Cards: Signature Rewards"
        case "1_3":
            return "[Enrichment - 1_3] Credit Cards: Savings Secured"
        case "1_4":
            return "[Enrichment - 1_4] Credit Cards: Platinum"
        case "2_1":
            return "[Enrichment - 2_1] Mortgages: Mortgage Purchase"
        case "2_2":
            return "[Enrichment - 2_2] Mortgages: Mortgage Refinance"
        case "2_3":
            return "[Enrichment - 2_3] Mortgages: HELOC"
        case "3_1":
            return "[Enrichment - 3_1] Deposits & Member Acquisition: CDs"
        case "3_2":
            return "[Enrichment - 3_2] Deposits & Member Acquisition: Checking"
        case "3_3":
            return "[Enrichment - 3_3] Deposits & Member Acquisition: Checking"
        case "4_1":
            return "[Enrichment - 4_1] Business Deposit: Business Checking"
        case "4_2":
            return "[Enrichment - 4_2] Business Deposit: Business Savings" 
        case "qatest":
            return "[Signal] qaTest"
        case "qatest2":
            return "[Signal] qaTest2"
        case "qatest3":
            return "[Signal] qaTest3"
        case "qatest4":
            return "[Signal] qaTest4"
        default:
            return code
    }
}