import "./SearchPanel.module.scss"
import {TextField} from "@mui/material";
import Search from "../../../_assets/magnifying-glass-blue.svg";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import { useDispatch } from "react-redux";
import { setSearch } from "../../../../redux/slices/globalSlice";
import { useNavigate } from "react-router-dom";
export interface IOwnProp {
    isSearchVisible: boolean
}

function SearchPanel(props: IOwnProp ) {

    const navigate = useNavigate();

    const { isSearchVisible } = props;
    const openClass = isSearchVisible ? "search-panel-open" : "";

    const dispatch = useDispatch();
    
    return (
        <div id="search-panel" className={`search-panel ${openClass}`} aria-labelledby="searchbar" role="group">
            <form 
                onSubmit={(e)=>{
                    e.preventDefault();
                    navigate("/search-results");
                }}>
                <TextField 
                    variant="outlined" 
                    label={"Search"}  
                    type="text"
                    id="txtSearch" 
                    name="search"
                    inputRef={input => input && input.focus()}
                    onChange={(e)=>{dispatch(setSearch(e.target.value))}}
                    classes={{
                        root: "field"
                    }} 
                    size={"small"} />
                <div className="field">
                    <button type="button" className="btn-search" onClick={()=>{ navigate("/search-results"); }}>
                        <span className="visually-hidden">Search Site</span>
                        <img src={Search} alt="search" />
                    </button>
                </div>
            </form>
        </div>
    );
}
export default withErrorBoundary(SearchPanel);