import ContentTypesEnum from "../enums/ContentTypesEnum";
import IClientServerModel from "../interfaces/IClientServerModel";
import PromotionModel from "./PromotionModel";

export default class PersonalizedPromoModel implements IClientServerModel {
    public readonly contentType: ContentTypesEnum | undefined;
    public readonly id: string | undefined = "";
    public readonly name?: string | undefined = "";
    public readonly variatians: PromotionModel[];
    constructor(props:Readonly<PersonalizedPromoModel> | null | undefined) {
        let {contentType, id = "", name = "", variatians = []} = props || {};
        this.contentType = contentType;
        this.id = id;
        this.name = name;
        this.variatians = variatians?.map(vars => new PromotionModel(vars)) || []; 
    }
}