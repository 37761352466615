import IComponentProps from "../../../interfaces/IComponentProps";
import ThemeTypeEnum from "../../../enums/ThemeTypeEnum";
import Header from "../Header/Header";
import Footer from "../_shared/Footer/Footer";
import stylesBase from "../../components/Promotional/_HeroBase.module.scss";
import styling from "./Search.module.scss";
import {
    Pagination,
    Configure,
    ScrollTo,
    SearchBox,
    InstantSearch,
} from "react-instantsearch-dom";
import SearchHits from "./SearchHits";
import SearchMenu from "./SearchMenu";
import HelmetHead from "../../components/HelmetHead/HelmetHead";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import { connect, useDispatch } from "react-redux";
import searchClient from "./SearchClient";
import envDataConfig from "../../../EnvDataConfig/EnvDataConfig";
import { useEffect } from "react";
import { updateNavigationTheme } from "../../../redux/slices/pageContentSlice";

export interface IUIState{
    query: string;
    menu: any;
}
interface IMapStateToProps {
    search: string;
}
export const mapStateToProps = (state: any, _: any): any => {
    const { search } = state.global;
    return { search };
};

const helmetHeadData = {
    title: "Search Page",
    description: "Search Page",
    keywords:  ["Search Page"],
    canonical: "https://vystarcu.org/search-results"
};

function SearchPage(props: IComponentProps & IMapStateToProps) {

    const { search } = props;
    const algoliaUserToken = sessionStorage.getItem("algoliaUserToken");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateNavigationTheme(ThemeTypeEnum.Light!))
    }, [dispatch])

    return (
        <>
        <HelmetHead {...helmetHeadData} />
            <Header theme={ThemeTypeEnum.Light} />
            <main className="main">
                {/* @ts-ignore */}
                <InstantSearch
                    indexName={envDataConfig.AlgoliaIndex}
                    searchClient={searchClient}
                >
                    <Configure 
                        hitsPerPage={10}   
                        clickAnalytics={true}
                        userToken={algoliaUserToken}
                    />
                    <ScrollTo>
                        <section>
                            <div
                                className={`${stylesBase["c-hero"]} ${stylesBase["c-hero-gradient"]} dark ${stylesBase["c-hero-search"]}`}
                                style={{background: 'linear-gradient(to bottom, #285ca5, #002d6c)'}}
                            >
                                <div className="container">
                                    <div className="row align-items-start">
                                        <div className="col-12 col-lg-6">
                                            <div className="hero-content">
                                                <h1>Search Results</h1>
                                            </div>
                                            <div className={styling["site-search"]}>
                                                <SearchBox
                                                    defaultRefinement={search}
                                                    translations={{
                                                        placeholder: "Enter search term"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div
                                className={"layout site-search-results"}
                            >
                                <div className="container">
                                    <div className="row" data-insights-index={envDataConfig.AlgoliaIndex}>
                                        <div className="col-12 col-lg-4">
                                            <div id="brand-list">
                                                <div className="ais-RefinementList ais-RefinementList--noRefinement">
                                                    <SearchMenu
                                                        attribute="category"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-7 offset-lg-1">
                                            <SearchHits />
                                            <Pagination
                                              showLast
                                              translations={{
                                                previous: 'Previous page',
                                                next: 'Next page',
                                                first: 'First page',
                                                last: 'Last page',
                                                ariaPrevious: 'Previous page',
                                                ariaNext: 'Next page',
                                                ariaFirst: 'First page',
                                                ariaLast: 'Last page',
                                              }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ScrollTo>
                </InstantSearch>
            </main>
            <Footer />
        </>
    );
}
export default connect( mapStateToProps, undefined) (withErrorBoundary(SearchPage));