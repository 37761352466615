import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import IEnrichment from "../interfaces/IEnrichment";

export default class ContentLoadingPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null = PageTemplateEnum.ContentLoading;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly title?: string;
    constructor(_:Readonly<ContentLoadingPageModel> | null | undefined) {
        this.templateType = PageTemplateEnum.ContentLoading;
        this.navigationTheme = ThemeTypeEnum.Light;
        this.enrichments = [];
        this.keywords = [];
        this.description = "";
        this.title = "VyStar Credit Union";
    }
}

