
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import LinkList from "../../_shared/LinkList/LinkList";
import ITabComponentProps from "../ITallTabComponetProps";

export function TabSplitImagePanel(props: ITabComponentProps){
    let {image, sidePanelBlock, relatedLinkGroup, isSelected, index, tabDescription} = props;
    let {links:relatedLinks, label} = relatedLinkGroup || {};
    let {richText, id} = sidePanelBlock || {};
    return <>
        <div id={`panel-${index}`} role="tabpanel" aria-labelledby={`tab-${index}`} aria-hidden={!isSelected} data-contentid={id}>
            <div className="accordion-desc">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <p>{tabDescription}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container tab-panel-content">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                        <div className="tab-panel-content-inner">
                            <img src={image?.imageURL} alt=""/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-1 d-flex flex-row">
                        <hr />
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="tab-panel-content-inner right-side">
                            { richText && documentToReactComponents(richText, ContentfulReactRenderOptions)}
                        </div>
                    </div>
                </div>
                {(label || relatedLinks?.length) ? <div className="row justify-content-center mt-5">
                    <div className="col-12 col-lg-11">
                        <div className="horizontal-list-links">
                            <h3>{label}</h3>
                            <LinkList links={relatedLinks}/>
                        </div>
                    </div>
                </div> : <></>}
            </div>
        </div>
    </>;
}

TabSplitImagePanel.displayName = "TabSplitImagePanel";
export default withErrorBoundary(TabSplitImagePanel);