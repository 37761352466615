import ContentTypesEnum from "../enums/ContentTypesEnum";
import AnyDynamicContentModel from "../redux/_shared/helpers/AnyDynamicContentModel.type";
import LinkGroup from "./LinkGroup";

export default class ComponentContentBlockModel {
    public readonly contentType: ContentTypesEnum | undefined;
    public readonly id: string | undefined;
    public readonly htmlId?: string | undefined;
    public readonly sidebar?: LinkGroup;
    public readonly content?: AnyDynamicContentModel[];
    constructor(props:Readonly<ComponentContentBlockModel> | null | undefined) {
        const {contentType, sidebar, content = []} = props || {};
        const {id, htmlId} = props || {};
        this.id = id;
        this.htmlId = htmlId;
        this.sidebar = sidebar ? new LinkGroup(sidebar): undefined;
        this.contentType = contentType;
        this.content =  content || [];
    }
}