import { useDispatch } from "react-redux";
import LinkTypeEnum from "../../../enums/LinkTypeEnum";
import SmartLinkModel from "../../../models/SmartLinkModel";
import { closeModal } from "../../../redux/slices/modalSlice";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import { submitCookieForm } from "../_shared/Dialogs/CookiesBody";
import SmartLink from "../_shared/SmartLink/SmartLink";
import styling from "./CookieBanner.module.scss";

const acceptButtonModel = new SmartLinkModel({
    type: LinkTypeEnum.PrimaryButton,
    defaultLinkText: "Accept All Cookies",
 
});
const managePreferencesModel = new SmartLinkModel({
    type: LinkTypeEnum.SecondaryButton, 
    defaultLinkText: "Manage Preferences",
    url: "modal:Cookies",
});
const privacyPolicyModel = new SmartLinkModel({
    type: LinkTypeEnum.Default,
    url: "/privacy-statement", 
    defaultLinkText: "Privacy Policy",
});

interface ICookieBanner{
    setShowCookieBanner: Function
}

function CookieBanner({setShowCookieBanner}: ICookieBanner){

    const dispatch = useDispatch();

    return (<div className={`${styling["cookie-banner"]}`} id="cookie-banner">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-9 text-center text-lg-start">
                <h6>Your Privacy Matters</h6>
                <p>VyStar, and its partners, use first and third-party cookies and similar analytics technologies, such as persistent trackers, pixels (e.g., MetaPixel), and heat mapping, and live chat (collectively, “technologies”) to help us operate this website, conduct analytics, provide you with a more personalized user experience, and provide advertisements. The information we collect from you through these technologies may be shared with third parties, including advertising, social media, and analytics partners. By accepting cookies, you agree to our use of technologies and acknowledge our 
                    {" "}
                    <SmartLink {...privacyPolicyModel} className="text-white" />.
                </p>
            </div>
            <div className="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-end">
                <div className="d-grid d-block gap-3 text-center">
                    <SmartLink 
                        {...acceptButtonModel}
                        onClick={(e: any)=>{
                            e.preventDefault();
                            submitCookieForm(true, true, dispatch);
                            // window.location.reload();
                            dispatch(closeModal(null));
                            dispatch(setShowCookieBanner(false));
                        }}
                    />
                    <SmartLink 
                        {...managePreferencesModel} 
                        className={styling["manage-preferences"]}
                    />
                </div>
            </div>
        </div>
    </div>
</div>);
}
export default withErrorBoundary(CookieBanner);