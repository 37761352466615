import HelmetHead from "../../components/HelmetHead/HelmetHead";
import AccessRestrictedPageModel from "../../../models/AccessRestrictedPageModel";
import IContainerComponentProps from "../../../interfaces/IContainerComponentProps";
import Logo from "../Header/Logo/Logo";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { useAccessRestrictedRequestQuery } from "../../../redux/_shared/helpers/vystarApi";
import ContentBlockModel from "../../../models/ContentBlockModel";
import ContentTypesEnum from "../../../enums/ContentTypesEnum";

const contentBlockDefault = {
    contentType: ContentTypesEnum.None,
    richText: undefined,
    id: "contentBlockDefault",
    htmlId: "",
    name: "UNK"
}

export function AccessRestrictedPage(props: IContainerComponentProps) {

    let accessRestrictedModel: AccessRestrictedPageModel = {
        templateType: null,
        navigationTheme: null,
        keywords: null,
        description: null,
        pageTitle: "",
        logo: undefined,
        contentBlock: new ContentBlockModel(contentBlockDefault)
    }

    const accessRestrictedApi = useAccessRestrictedRequestQuery("accessRestricted");

    if(accessRestrictedApi.isSuccess && accessRestrictedApi.status === "fulfilled"){
        accessRestrictedModel = new AccessRestrictedPageModel(accessRestrictedApi.data[0])
    }
    
    let { htmlId, keywords, pageTitle, contentBlock = contentBlockDefault, logo } = accessRestrictedModel;
    const helmetHeadData = {
        title: pageTitle,
        description: "Access Restricted Page",
        keywords
    };

    return (
        <>
            <HelmetHead {...helmetHeadData} />
            <header>
                <Logo {...logo} />
            </header>
            <div 
              id={htmlId}
            >
                <div className={'container'}>
                    <ContentBlock {...contentBlock}/>
                </div>
            </div>
        </>
    );
}

export default AccessRestrictedPage;