import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import PrintIcon from "../../_assets/icon-print.png";
import styling from "./PrintButton.module.scss";

interface IPrintButton{
    handlePrint: any;
}

export function PrintButton({handlePrint}: (IPrintButton & IComponentProps)){
    
    return (<a className={`smartlink_default ${styling['print-button']}`} onClick={handlePrint} onKeyDown={(e)=>{
        if (e.key === "Enter") {
            handlePrint();
        }
    }} tabIndex={0}>
                <span className="px-1">Print</span>
            <img src={PrintIcon} width={30} height={30} alt="Print this page"/></a>
    );
}
export default withErrorBoundary(PrintButton);