import { useEffect, useState } from "react";
import IComponentProps from "../../../interfaces/IComponentProps";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import createScript from "../../../featureConfiguration/_shared/createScript";
import createStyleSheetLink from "../../../featureConfiguration/_shared/createStyleSheetLink";
import CalculatorModel from "../../../models/CalculatorModel";
import ContentBlock from "../ContentBlock/ContentBlock";
import "./Calculator.scss";
import wait from "../../../featureConfiguration/_shared/wait/wait";

declare global {
    interface Window {
        KJE:any;
    }
}

function loadCalculator(calculatorContainer: Element | null, calculatorCode: string | undefined | null) {
    let kjeScriptPromise = createScript({
        src: `https://cucalc.org/013941/KJE.js`,
        destination: calculatorContainer,
        htmlID: "KJEScript"
    }).then(() => {
        //create params script
        createScript({
            src: `https://cucalc.org/013941/KJESiteSpecific.js`,
            destination: calculatorContainer,
            htmlID: "KJESiteScript",
            checkIsScriptRun: () => {
                return window?.KJE;
            }
        });
    });

    kjeScriptPromise.then(() => {
        let lastInitialize = window?.KJE?.initialize;
        let scrollVerticalToIdAsync = (id:string, ms:number = 500)=>{
            console.log('scrollVerticalToIdAsync');
            setTimeout(()=>{
                let el = document.getElementById(id);
                el?.scrollIntoView({block:'center', behavior:'smooth', inline:'start'})
                console.log(el);
            },ms);
        };
        //create params script
        createScript({
            src: `https://cucalc.org/013941/${calculatorCode}Params.js`,
            destination: calculatorContainer,
            htmlID: "KJECalculatorParamsScript"
        }).then(() => {
            return createScript({
                src: `https://cucalc.org/013941/${calculatorCode}.js`,
                destination: calculatorContainer,
                htmlID: "KJECalculatorScript",
                checkIsScriptRun: () => {
                    return lastInitialize !== window?.KJE?.initialize;
                }
            });
        }).then(() => {
            window.KJE.init();
            // Note init requires all scripts to be loaded and and the KJEAllContent div to be created
            // if this runs into timing issues consider setTimeout or revising checkIsScriptRun
            return wait.forElementById('KJEViewReport').then(()=>{
                document.getElementById('KJECalculate')?.addEventListener("click", (e)=> {
                    scrollVerticalToIdAsync('KJECommandButtons',300);
                });
                document.getElementById('KJEViewReport')
                    ?.addEventListener("click", (e)=>{
                        let reportButton = e.currentTarget as HTMLInputElement ;
                        if(reportButton && reportButton.value === "Hide Report"){
                            reportButton.id = "KJEViewReport";
                            let calcButton = document.getElementById('KJEPrint');
                            if(calcButton){
                                calcButton.id = "KJECalculate";
                            }
                        }
                        else if(reportButton && reportButton.value === "View Report"){
                            reportButton.id = "KJEHideReport";
                            let calcButton = document.getElementById('KJECalculate');
                            if(calcButton){
                                calcButton.id = "KJEPrint";
                            }
                        }
                        scrollVerticalToIdAsync('KJECommandButtons',300);
                    });
            });
        });
    });
}

export function Calculator({contentBlock, header, topicCalculator, htmlId, id}:  CalculatorModel & IComponentProps) {
    let {calculatorCode} = topicCalculator;
    let calculatorContainer = document.querySelector(".calculatorContainer");
    let [isInitialized, setIsInitialized] = useState(false); // set to true on first render use effect

    useEffect(() => {
        if(!isInitialized){
            // create general base css link
            {
                let styleSheetHtmlId = "KJE-Base-Style";
                if (!document.getElementById(styleSheetHtmlId)) {
                    createStyleSheetLink({
                        href: "https://cucalc.org/013941/KJESiteSpecific.css",
                        destination: document.head,
                        htmlID: styleSheetHtmlId
                    });
                }
            }

            // create site specific css link
            {
                let styleSheetHtmlId = "KJE-Site-Style";
                if (!document.getElementById(styleSheetHtmlId)) {
                    createStyleSheetLink({
                        href: "https://cucalc.org/013941/KJE.css",
                        destination: document.head,
                        htmlID: styleSheetHtmlId
                    });
                }
            }
            // the first time this runs set to true... will trigger second render
            setIsInitialized(true);
        }
        else{
            loadCalculator(calculatorContainer, calculatorCode);
        }

    }, [isInitialized, calculatorCode, calculatorContainer]);
    
    return <>
            <div className="layout" data-contentid={id} id={htmlId}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <div className="intro text-center">
                                <h2>{header}</h2>
                                <ContentBlock {...contentBlock} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="calculator-filter-container">
                            <div className="calculatorContainer container" >
                                <div id="KJEAllContent"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
}

export default withErrorBoundary(Calculator);
