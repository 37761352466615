import {Component, Fragment} from "react";

export interface DomScriptProps{
    children?:any | undefined;
    onLoad?:EventListenerOrEventListenerObject | undefined;
}

class DOMScript extends Component<DomScriptProps>{

    componentDidMount() {
        // @ts-ignore
        this?.props?.onLoad();
    }

    render() {
        let {children} = this.props;
        return !!children ? children : [<Fragment/>];
    }
}

export default DOMScript;