
import {Dialog, DialogActions, DialogContent, IconButton} from "@mui/material";
import styles from "./ModalDialog.module.scss";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import LinkList from "../LinkList/LinkList";

interface IConfirmationDialogProps{
    header: JSX.Element | string | null | undefined,
    body: React.ReactNode | string | null | undefined,
    isOpen:boolean,
    onRequestConfirmation:Function,
    onRequestClose:Function,
    className?:String
}


export default function ExternalModal(props: IConfirmationDialogProps) {

    let {header, body, isOpen, onRequestClose, onRequestConfirmation, className} = props;

    const confirmButtonData = {
        linkText:"Confirm",
        onClick: (e:React.MouseEvent<HTMLAnchorElement>)=> {
            e.preventDefault();
            onRequestConfirmation()
        },
        type: LinkTypeEnum.PrimaryButton
    }
    const cancelButtonData = {
        linkText:"Cancel",
        onClick: (e:React.MouseEvent<HTMLAnchorElement>)=> {e.preventDefault();onRequestClose()},
        type: LinkTypeEnum.SecondaryButton
    }
    
    return (
        <Dialog 
            className={`${styles.dialog} ${className}`} 
            aria-labelledby="customized-dialog-title" 
            open={isOpen} 
            onClose={()=>onRequestClose()} 
            classes={{ paper: "mf-modal overflow-hidden confirmation-dialog"}}>
            <div className="modal-title">
                {header}
            </div>
            <DialogContent dividers className={'dialogBody'}>
                <p>
                    {body}
                </p>
                <DialogActions classes={{root: "d-block"}}>
                    <LinkList links={[confirmButtonData, cancelButtonData]}/>
                </DialogActions>
            </DialogContent>
            <IconButton 
                aria-label="close" 
                onClick={()=>onRequestClose()}
                classes={{root: `closeButton p-0 ${styles["mfp-close"]}`}}                  
            >
            </IconButton>
        </Dialog>       
    );
}