//TODO: jdh - Remove if duplicate
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import { useEffect, useState } from 'react';
import store from '../redux/store';
import {Provider} from 'react-redux';
import AppRoutes from "./AppRoutes";
import envDataConfig from '../EnvDataConfig/EnvDataConfig';
import { ManifestClient } from '@uniformdev/context/api';
import { UniformContext } from "@uniformdev/context-react";
import { Context, enableContextDevTools, enableDebugConsoleLogDrain } from '@uniformdev/context';
import { enableCustomGoogleGtagAnalytics } from './_shared/helpers/plugin';
import getCookie from './_shared/helpers/getCookie';
import { useDispatch } from 'react-redux';
import { togglePersonalize } from '../redux/slices/pageContentSlice';
import { retrieveGeo } from './_shared/helpers/geo';

async function fetchManifest() {
    const client = new ManifestClient({
        apiKey: envDataConfig.UniformApiKey,
        projectId: envDataConfig.UniformProjectId
    });
    return await client.get();
}

async function createContext() {
    const [manifest, geo] = await Promise.all([fetchManifest(), retrieveGeo(envDataConfig.UniformLocationAPI)]);
    const gaPlugin = enableCustomGoogleGtagAnalytics();
    // const isConsentGranted = getCookie("marketing_storage") === "granted" ? true : false;
    const context = new Context({
        manifest,
        plugins: [
            enableContextDevTools(),
            enableDebugConsoleLogDrain('info'),
            gaPlugin
        ]
    })
    await context.update({
        quirks: { ...geo }
    });
    return context;
} 

function App() {

    const [ context, setContext ] = useState<Context>();

    useEffect(()=>{
        const isMarketingConsentGranted = getCookie("marketing_storage") === "granted" ? true : false;
        createContext().then((context: Context)=>{
            setContext(context);
            store.dispatch(togglePersonalize(isMarketingConsentGranted));
        })
    },[]);

    // Boolean will decide whether to use context

    return (context ?
        <UniformContext context={context!}>
            <Provider store={store}>
                <AppRoutes/>
            </Provider>
        </UniformContext> : <></>
    );
}

export default App;