import baseStyles from "./CardBase.module.scss";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import CardModel from "../../../../models/CardModel";
import IComponentProps from "../../../../interfaces/IComponentProps";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SmartLink from "../SmartLink/SmartLink";
import SmartLinkModel from "../../../../models/SmartLinkModel";

export function CardSimpleRender(props: CardModel & IComponentProps & {styleOverrides:any | undefined}) {
    let {body, image, header:linkText, link, headerLink, htmlId, className, stepNumber, showStepNumber, styleOverrides = {}} = props ?? {};
    const smartLink = new SmartLinkModel({
        ...headerLink,
        linkText
    });
    let hasHeaderLink = !!headerLink?.url;
    return (
        <div id={htmlId} className={`${baseStyles['card']} ${styleOverrides['card']} card ${className}`}>
            {image && image.imageURL && <div className={`${baseStyles['card-image']} ${styleOverrides['card-image']} icon d-none d-lg-block`}>
                <img src={image.imageURL} alt="" aria-hidden="true"/>
            </div>}
            {showStepNumber && (stepNumber !== undefined) && <div className={`${baseStyles['step']} ${styleOverrides['step']}`}>
                { stepNumber }
            </div>}
            <div className={`${baseStyles['card-body']} ${styleOverrides['card-body']} card-body`}>
                <h3 className={`${baseStyles['card-title']} ${styleOverrides['card-title']} card-title`}>
                    { hasHeaderLink ? <SmartLink {...smartLink}/> : linkText }
                </h3>
                <div>
                    {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                    <SmartLink {...new SmartLinkModel(link)}/>
                </div>
            </div>
        </div>
    );
}

CardSimpleRender.displayName = "CardSimple";
export default withErrorBoundary(CardSimpleRender);
