import Markdown from "markdown-to-jsx";
import {Document} from "@contentful/rich-text-types";
import React, { useEffect, useRef, useState } from "react";
import LinkTypeEnum from "../../../enums/LinkTypeEnum";
import SmartLink from "../_shared/SmartLink/SmartLink";
import DynamicClamp from "../_shared/DynamicClamp/DynamicClamp";
import Clamp from 'react-multiline-clamp';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";

const isEllipsisActive = (e:any) => e.offsetHeight < e.scrollHeight;

interface IFAQItem { 
    question: string | null | undefined, 
    answer: Document | null | undefined, 
    onRequestDetails:Function, 
    windowWidth: number, 
    id?: string | undefined 
}

export default function FaqItem({answer, question, onRequestDetails, windowWidth, id = "0"}: IFAQItem) {

    const divRef = useRef<HTMLDivElement>(null);
    let onReadMoreClick=(e:any)=>{
        e.preventDefault();
        onRequestDetails();
    }
    const [shouldReadMore, setShouldReadMore] = useState(false);
    const isChrome = navigator.userAgent.indexOf("Chrome") > -1;
    const style = !isChrome ? {paddingBottom: 15} : undefined;

        useEffect(()=>{
            if(divRef?.current?.children?.length && isChrome){
                const spanDiv = divRef?.current?.children[0];
                setShouldReadMore(isEllipsisActive(spanDiv));
            }
        },[setShouldReadMore, divRef, windowWidth, isChrome]);

    return <div className="faq-item">
        <h3><Markdown>{question ?? ""}</Markdown></h3>
            <div className="faq-content" style={style} ref={divRef}>
                {isChrome ? 
                    <Clamp withTooltip lines={4} >
                    {answer && documentToReactComponents(answer, ContentfulReactRenderOptions)}
                    </Clamp>:
                    <DynamicClamp 
                        content={answer} 
                        windowWidth={windowWidth} 
                        parentShouldReadMore={setShouldReadMore} 
                    />
                }
            </div>
        {shouldReadMore && <SmartLink type={LinkTypeEnum.Default} linkText={'Read More'} onClick={onReadMoreClick}/>}
    </div>;
}
