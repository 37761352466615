import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import BrowserSupportFeatureEnum from '../../enums/BrowserSupportFeatureEnum';

const initialState = {
    errorLog: [],
    browserSupport:{
        [BrowserSupportFeatureEnum.webp]: false
    }
}

export const globalSlice = createSlice({
  name: 'system',
  initialState: initialState,
  reducers: {
        errorBoundaryCatch: (state:any, action: PayloadAction<Error>) => {
            const {errorLog = []} = state;
            return {...state, errorLog:[...errorLog, action.payload]};
        },
        browserSupportSettingUpdate: (state:any, action: PayloadAction<any>) => {
            state.browserSupport = {...state.browserSupport, ...action.payload};
        },
  },
})

// Action creators are generated for each case reducer function
export const { errorBoundaryCatch, browserSupportSettingUpdate } = globalSlice.actions

export default globalSlice.reducer