
import IComponentProps from "../../../interfaces/IComponentProps";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import "./Header.module.scss";
import {connect} from "react-redux";
import NavMenuModel from "../../../models/NavMenuModel";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";
import NavToolbarModel from "../../../models/NavToolbarModel";
import IThemedDisplay from "../../../interfaces/IThemedDisplay";
import ThemeTypeEnum from "../../../enums/ThemeTypeEnum";
import DesktopToolbar from "./DesktopToolbar/DesktopToolbar";
import BannerAlerts from "./BannerAlerts/BannerAlerts";
import DOMScript from "../DOMConfig";
import ConfigureHeaderDOM from "./ConfigureHeaderDom/ConfigureHeaderDom";
import ScriptedDomIdsEnum from "../../../enums/ScriptedDomIdsEnum";
import ScrollDirectionEnum from "../../../enums/ScrollDirectionEnum";
import { useMenuRequestQuery, useToolbarRequestQuery } from "../../../redux/_shared/helpers/vystarApi";
import { desktopMenuSetActiveIndex, desktopMenuSetHeight, mobileMenuSetActiveIndex, mobileMenuToggleAction } from "../../../redux/slices/globalSlice";
import { RootState } from "../../../redux/store";

interface IMapStateProps extends IThemedDisplay {
    navigationTheme: string
}

interface IMapDispatchProps{
    desktopMenuSetHeight: Function,
    toggleMobileMenu:  ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined,
    setMobileMenuActiveIndex: (index: number | null) => void,
    setDesktopMenuActiveIndex: (index: number | null) => void,
}

interface IMapStateProps extends NavToolbarModel, IThemedDisplay{
    navMenus: NavMenuModel[],
    isMobileSubmenuOpen: boolean,
    mobileMenuActiveIndex: number | null,
    desktopMenuActiveIndex: number | null,
    isSticky: boolean,
    scrollClassName: string,
    lastScrollPosition: number,
    headerSpacerHeight: number,
    stickyMinScrollHeight: number,
    isLoginModalVisible: boolean,
    activeTabIndex: number,
    activeNavMenuIndex: number,
    bannerAlerts: any
}

export const mapStateToProps = (state: RootState, ownProps?: any): IMapStateProps => {
    
    const {mobileMenu, desktopMenu, header, scroll, bannerAlerts} = state.global || {};
    const {navigationTheme} = state.pageContent || {};
    const {scrollDirection, lastScrollPosition} = scroll || {scrollDirection: ScrollDirectionEnum.Up, lastScrollPosition: 0};
    let scrollClassName = "";
    if(scrollDirection === ScrollDirectionEnum.Up){
        scrollClassName = "scroll-up"
    }
    if(scrollDirection === ScrollDirectionEnum.Down){
        scrollClassName = "scroll-down"
    }
    let { navMenus, activeTabIndex } = ownProps;
    
    const {isSticky, headerSpacerHeight, stickyMinScrollHeight, isLoginModalVisible} = header || {};
    const {theme = ThemeTypeEnum.Light} = ownProps || {};
    const {isMenuOpen: isMobileSubmenuOpen, activeIndex: mobileMenuActiveIndex} = mobileMenu || {isMenuOpen: false, activeIndex: 0};
    const {activeSubmenuIndex: desktopMenuActiveIndex} = desktopMenu || { activeSubmenuIndex: -1 };

    return {
        navMenus,
        isMobileSubmenuOpen,
        theme,
        navigationTheme,
        mobileMenuActiveIndex,
        desktopMenuActiveIndex,
        isSticky,
        scrollClassName,
        isLoginModalVisible,
        lastScrollPosition,
        headerSpacerHeight,
        stickyMinScrollHeight,
        activeTabIndex,
        activeNavMenuIndex:activeTabIndex,
        bannerAlerts
    } as IMapStateProps;
};

export const mapDispatchToProps = (dispatch: any): IMapDispatchProps => {
    return {
        desktopMenuSetHeight: (height:number) => {
            dispatch(desktopMenuSetHeight(height));
        },
        toggleMobileMenu: () => {
            dispatch(mobileMenuToggleAction(null));
        },
        setMobileMenuActiveIndex: (index: number | null) => {
            dispatch(mobileMenuSetActiveIndex(index));
        },
        setDesktopMenuActiveIndex: (index: number | null) => {
            dispatch(desktopMenuSetActiveIndex(index));
        }
    };
};


export function Header(props: IMapStateProps & IMapDispatchProps & IComponentProps & { theme: ThemeTypeEnum }) {
    let {callToAction, mobileCTA, navMenus, mobileMenuActiveIndex, desktopMenuActiveIndex, setMobileMenuActiveIndex,
        toggleMobileMenu, isMobileSubmenuOpen, routingNumber, tabs, theme, lastScrollPosition,navigationTheme,
        navigationItems, setDesktopMenuActiveIndex, isSticky, headerSpacerHeight, desktopMenuSetHeight,
        scrollClassName, isLoginModalVisible, activeTabIndex = 0, activeNavMenuIndex = 0, bannerAlerts
    } = props;

    let menuApi = useMenuRequestQuery(null);
    if(menuApi.isSuccess && menuApi.status === "fulfilled"){
        const payload: Array<NavMenuModel> | null | undefined = menuApi.data || [];
        navMenus = payload ? payload?.map(i =>new NavMenuModel(i)) : [];
    }
    const toolbarApi = useToolbarRequestQuery(null);
    if(toolbarApi.isSuccess && toolbarApi.status === "fulfilled"){
        const payload: Array<NavToolbarModel> | null | undefined = toolbarApi.data || [];
        const navMenuArray = payload ? payload?.map(i =>new NavToolbarModel(i)) : [];
        const navToolbar = new NavToolbarModel(navMenuArray[0]);
        tabs = navToolbar.tabs;
        routingNumber = navToolbar.routingNumber;
        navigationItems = navToolbar.navigationItems;
        callToAction = navToolbar.callToAction;
        mobileCTA = navToolbar.mobileCTA;
        activeTabIndex = tabs?.findIndex(tab => {
            let linkText = (tab.linkText || tab.defaultLinkText || "").toLowerCase();
            let locationPath = window.location.pathname.toLowerCase();
            return locationPath.indexOf(linkText) > -1;
        }) ?? -1;
        activeTabIndex = activeTabIndex === -1 ? 0 : activeTabIndex;
        activeNavMenuIndex = activeTabIndex;
    }
    const themeClass = navigationTheme === ThemeTypeEnum.Light ? "light-header" : "";

    return (<DOMScript onLoad={ConfigureHeaderDOM}>
        <header className={`${scrollClassName} dont-print`}>
                <BannerAlerts {...bannerAlerts}/>
                <DesktopToolbar {...{
                    tabs,
                    activeTabIndex,
                    links: navigationItems,
                    routingNumber,
                    callToAction,
                    isLoginModalVisible,
                    themeClass
                }}/>
                <div className={`header g-header ${themeClass}`}>
                    <DesktopMenu {...{
                        navMenus,
                        theme,
                        activeNavMenuIndex,
                        setActiveIndex: setDesktopMenuActiveIndex,
                        activeIndex: desktopMenuActiveIndex,
                        isSticky,
                        lastScrollPosition,
                        desktopMenuSetHeight
                    }}/>
                    <MobileMenu {...{
                        navMenus,
                        tabs,
                        activeTabIndex,
                        mobileCTA,
                        toggleMobileMenu,
                        isMobileSubmenuOpen,
                        routingNumber,
                        theme, navigationItems,
                        activeIndex: mobileMenuActiveIndex,
                        setActiveIndex: setMobileMenuActiveIndex,
                        isLoginModalVisible
                    }}/>
                    <div id={ScriptedDomIdsEnum.HeaderSpacer} className="header-spacer" style={{height: headerSpacerHeight}}></div>
                </div>
        </header>
    </DOMScript>);
}


Header.displayName = "Header";
export default connect(mapStateToProps, mapDispatchToProps) (withErrorBoundary(Header));
