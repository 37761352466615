
import IComponentProps from "../../../interfaces/IComponentProps";
import DynamicPageModel from "../../../models/DynamicPageModel";
import dynamicComponentConfig from "../../../_dynamicComponentConfig";
import ContentTypesEnum from "../../../enums/ContentTypesEnum";
import IClientServerModel from "../../../interfaces/IClientServerModel";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import AnyDynamicContentModel from "../../../redux/_shared/helpers/AnyDynamicContentModel.type";

function DynamicPageRender(props: IComponentProps & DynamicPageModel){
    let content: AnyDynamicContentModel[] | undefined = props.content;
    let {htmlId, className="",} = props;

    return (
        <div id={htmlId} {...{ className }}>
            {content?.map((contentItem:AnyDynamicContentModel & IClientServerModel, i: number) => {
                let config = dynamicComponentConfig.get((contentItem)?.contentType || ContentTypesEnum.None);
                let DynamicComponent = config?.component;
                let model = config?.model;
                let className = "";

                if(i === 0) {                    
                    switch(contentItem.contentType) {
                        case "componentPromo": {
                            className = "";
                            break;
                        }                        
                        case "personalized-Promo": {
                            className = "";
                            break;
                        }
                        case "componentSitemap": {
                            className = "";
                            break;
                        }                        
                        case "componentPromoAccordions": {
                            className = "";
                            break;
                        }
                        default: {
                            className = "top-section";
                            break;
                        }
                    }
                }

                // @ts-ignore
                let content = model ? new model(contentItem) : undefined;
                const hasData = !!DynamicComponent && !!model
                return  hasData ? <section key={i} className={className}><DynamicComponent {...content}/></section> : <></>;
            })}
        </div>
    );
}

export default withErrorBoundary(DynamicPageRender);
