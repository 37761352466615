
import IComponentProps from "../../../interfaces/IComponentProps";
import FormModel from "../../../models/FormModel";
import GenericPageModel from "../../../models/GenericPageModel";
import RichContentModel from "../../../models/RichContentModel";
import CategoryCardList from "../../components/CategoryCardList/CategoryCardList";
import Disclosures from "../../components/Disclosures/Disclosures";
import DynamicForm from "../../components/Form/DynamicForm";
import RichContent from "../../components/RichContent/RichContent";

export default function GenericPage(props: GenericPageModel & IComponentProps) {
    let { htmlId, className = "", body, category, sidebarLinkGroup, backgroundImage, contentTitle, disclosures, form} = props;
	let bodyModel = JSON.parse(String(body || "{}")) || null;
	let richContent = new RichContentModel({body: bodyModel, sidebarLinkGroup, htmlId, contentTitle}) ?? {};
    return (
        <div id={htmlId} {...{ className }} style={{background: "linear-gradient(0deg, white 0%, #f3f1f1 100%)"}}>
            <section>
                <RichContent {...richContent}/>
            </section>
            {form && <section>
                <DynamicForm {...new FormModel(form)} />
            </section>}
            <section style={{backgroundImage: `url(${backgroundImage?.imageURL})`}}>
                <CategoryCardList {...category} />
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
