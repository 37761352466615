export const REACT_APP_CONTENT_ACCESS_RESTRICTIONS_ENABLED: string = process.env.REACT_APP_CONTENT_ACCESS_RESTRICTIONS_ENABLED?.valueOf() ?? "";
export const REACT_APP_API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL?.valueOf() ?? "";
export const REACT_APP_API_BASE_URL_LOCAL: string = process.env.REACT_APP_API_BASE_URL_LOCAL?.valueOf() ?? "";
export const REACT_APP_OCP_APIM_SUBSCRIPTION_KEY: string = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY?.valueOf() ?? "";
export const REACT_APP_GTM_TAG = process.env.REACT_APP_GTM_TAG?.valueOf() ?? "";
export const REACT_APP_GA_TRACKER =  process.env.REACT_APP_GA_TRACKER?.valueOf() ?? "";
export const REACT_APP_VYCHAT_TYPE = process.env.REACT_APP_VYCHAT_TYPE?.valueOf() || "None";
export const REACT_APP_POSH_ID = process.env.REACT_APP_POSH_ID?.valueOf() ?? "";
export const REACT_APP_HIDE_SEARCH = process.env.REACT_APP_HIDE_SEARCH?.valueOf() ?? "";
export const REACT_APP_HIDE_LOGIN = process.env.REACT_APP_HIDE_LOGIN?.valueOf() ?? "";
export const REACT_APP_SITE_CACHE_GLOBAL_TIMEOUT: string = process.env.REACT_APP_SITE_CACHE_GLOBAL_TIMEOUT?.valueOf() ?? "";
export const REACT_APP_SITE_CACHE_PAGE_TIMEOUT: string = process.env.REACT_APP_SITE_CACHE_PAGE_TIMEOUT?.valueOf() ?? "";
export const REACT_APP_ALGOLIA_INDEX: string = process.env.REACT_APP_ALGOLIA_INDEX?.valueOf() ?? "";
export const REACT_APP_ALGOLIA_APP_ID: string = process.env.REACT_APP_ALGOLIA_APP_ID?.valueOf() ?? "";
export const REACT_APP_APPLY_LINK: string = process.env.REACT_APP_APPLY_LINK?.valueOf() ?? "";
export const REACT_APP_ALGOLIA_API_KEY: string = process.env.REACT_APP_ALGOLIA_API_KEY?.valueOf() ?? "";
export const REACT_APP_SITE_TAG = process.env.REACT_APP_SITE_TAG?.valueOf() ?? "websiteVCU";
export const REACT_APP_RECAPTCHA_API_KEY = process.env.REACT_APP_RECAPTCHA_API_KEY?.valueOf() ?? "";
export const REACT_APP_RECAPTCHA_API_SITE_KEY = process.env.REACT_APP_RECAPTCHA_API_SITE_KEY?.valueOf() ?? "";
export const REACT_APP_RECAPTCHA_API_SECRET_KEY = process.env.REACT_APP_RECAPTCHA_API_SECRET_KEY?.valueOf() ?? "";
export const REACT_APP_SENDGRID_CLIENT_KEY = process.env.REACT_APP_SENDGRID_CLIENT_KEY?.valueOf() ?? "";
export const REACT_APP_UNIFORM_API_KEY = process.env.REACT_APP_UNIFORM_API_KEY?.valueOf() ?? "";
export const REACT_APP_UNIFORM_PROJECT_ID = process.env.REACT_APP_UNIFORM_PROJECT_ID?.valueOf() ?? "";
export const REACT_APP_UNIFORM_LOCATION_API = process.env.REACT_APP_UNIFORM_LOCATION_API?.valueOf() ?? "";