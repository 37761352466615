import React, {useEffect, useState} from 'react';
import IComponentProps from "../../../../interfaces/IComponentProps";
import ContentLoadingPageModel from "../../../../models/ContentLoadingPageModel";
import ContentLoading from "../../../_assets/content-loading.svg";
import './ContentLoadingPage.module.scss';

export default function ContentLoadingPage(_: ContentLoadingPageModel & IComponentProps){

    const [isInitialized, setIsInitialized] = useState(false);
    const [className, setClassName] = useState("");

    useEffect(()=>{
        let timer: NodeJS.Timeout;
        if(!isInitialized){
            setIsInitialized(true);
            timer = setTimeout(()=>setClassName("fade-in"), 3000);
        }
        return () => clearTimeout(timer);
    },[isInitialized, setIsInitialized, setClassName]);

    return (
        <div id="loading" className={`loading ${className}`}>
            <div className="loading-message">
                <div>
                    <img src={ContentLoading} alt="Content Loading"/>
                </div>
                <div className="loading-message-text">
                    One moment please...
                </div>
            </div>
        </div>
    );
}
