import React, { useState } from "react";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import BioCardListModel from "../../../models/BioCardListModel";
import "./BioCardContainer.module.scss";
import BioCardModel from "../../../models/BioCardModel";
import { BioCard } from "./BioCard";
import SmartLink from "../_shared/SmartLink/SmartLink";
import SmartTab from "./SmartTab";
import setHidden from "../../_shared/helpers/setHidden";

interface IBioCardContainer {
    bioCards: Readonly<BioCardListModel[]> | null | undefined;
}

const keyDownHandler = (e: any, focusIndex: number, setFocus: Function, numberOfTabs: number) => {
    if (e.keyCode === 39 || e.keyCode === 37) {
        let tabFocus = focusIndex;
        if (e.keyCode === 39) {
            tabFocus = focusIndex + 1;
            // // If we're at the end, go to the start
            if (tabFocus >= numberOfTabs) {
                tabFocus = 0;
            }
            // Move left
        } else if (e.keyCode === 37) {
            tabFocus = focusIndex - 1;
            // // If we're at the start, move to the end
            if (tabFocus < 0) {
                tabFocus = numberOfTabs - 1;
            }
        }
        setFocus(tabFocus);
    }
}

export function BioCardList({ bioCards }: IBioCardContainer & IComponentProps) {

    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div
            className="bio-landing"
            onKeyDown={(e) => keyDownHandler(e, selectedIndex, setSelectedIndex, bioCards?.length ?? 0)}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={`tabs`}>
                            <div
                                role="tablist"
                                aria-orientation="horizontal"
                                aria-label="Sample Tabs"
                            >
                                {bioCards?.map((bioCardList, i) => {
                                    return (
                                        
                                            <SmartTab 
                                                label={bioCardList.label}
                                                tabIndex={i}
                                                role={"listitem"}
                                                key={i}
                                                activeIndex={selectedIndex}
                                                htmlId={`tab-${i + 1}`}
                                                id={bioCardList.id}
                                                onClick={()=>setSelectedIndex(i)}
                                            />
                                    );
                                })}
                            </div>
                            <div className="divider"></div>
                            {bioCards?.map((bioCardList, i) => {
                                const { ctaLink, content, htmlId, id, label } =
                                    bioCardList;
                                return (
                                    <div
                                        id={htmlId}
                                        role="tabpanel"
                                        aria-labelledby={`tab-${i + 1}`}
                                        key={i}
                                        className={setHidden(i, selectedIndex)}
                                        data-contentid={id}
                                    >
                                        <div className="row">
                                            {content?.map(
                                                (bioCard: BioCardModel, j) => (
                                                    <BioCard
                                                        {...bioCard}
                                                        label={label ?? ""}
                                                        key={j}
                                                    />
                                                )
                                            )}
                                        </div>
                                        <div className="row">
                                            {ctaLink && (
                                                <div className="col-12 text-center">
                                                    <SmartLink {...ctaLink} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withErrorBoundary(BioCardList);
