import React, { useCallback, useEffect, useState } from "react";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import LinkList from "../../../components/_shared/LinkList/LinkList";

interface ITabProps{
    tabs?:SmartLinkModel[],
    defaultTabIndex:number,
    onTabChange?:(index:number) => void,
    isSubMenuOpen?: boolean
}

export default function SubMenuTabs(props: ITabProps) {
    const {tabs, onTabChange, defaultTabIndex, isSubMenuOpen} = props || {};
    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex);
    const [isInitialized, setIsInitialized] = useState(isSubMenuOpen);

    const selectTab = useCallback((index: number) => {
        setSelectedTabIndex(index);
        onTabChange && onTabChange(index);
    },[onTabChange]);

    useEffect(() => {
        if (isSubMenuOpen && !isInitialized ) {
            setIsInitialized(true);
            selectTab(defaultTabIndex);
        }
        else if(isSubMenuOpen === false){
            setIsInitialized(false)
        }
    }, [isInitialized, isSubMenuOpen, setIsInitialized, defaultTabIndex, selectTab]);

    return <div className="container">
        <div className="row">
            <div className="col-12">
                <LinkList links={tabs} className={'mobile_menu_tabs'} linkRender={({linkText, defaultLinkText}, index)=>{
                    return (
                        <button 
                            className={`mobile_menu_tab ${index === selectedTabIndex ? "current_mm_tab" : ""}`} 
                            onClick={()=>{
                                selectTab(index);
                            }} 
                            data-mtab-index={index}>
                                {linkText ?? defaultLinkText}
                        </button>
                    )}}/>
            </div>
        </div>
    </div>;
};