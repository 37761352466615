import loadLivePersonAsync from "./loadLivePersonAsync/loadLivePersonAsync";
import loadPoshAsync from "./loadPoshAsync/loadPoshAsync";
import envDataConfig from "../../EnvDataConfig/EnvDataConfig";

export default async function loadVychatAsync() {
    if(envDataConfig.isGliaEnabled){
        var gliaScript = document.createElement("script");
        gliaScript.src = "https://api.glia.com/salemove_integration.js";
        document.head.appendChild(gliaScript);
        var poshScript = document.createElement("script");
        poshScript.src = "https://js.poshdevelopment.com/glia/render.js";
        document.head.appendChild(poshScript);
        return true;
    }

    if(envDataConfig.isLivePersonOnlyEnabled) {
        return loadLivePersonAsync().then(()=>{
            let lpmContainer = document.querySelector('.LPMcontainer') as HTMLElement;
            if(!!lpmContainer){
                lpmContainer.style.setProperty('visibility', 'visible', 'important');
            }
        });
    }
    else if(envDataConfig.isPoshLivePersonEnabled) {
        return Promise.allSettled([loadLivePersonAsync(), loadPoshAsync()]).then(() => {
            let livePersonButton = document.querySelector('.LPMcontainer') as HTMLElement;
            let poshWidgetContainer = document.querySelector('.posh-widget-container') as HTMLElement;
            let poshWidgetButton = document.querySelector('#posh-widget-button') as HTMLElement;
            let isLivePersonInitialized = !!livePersonButton;
            let isPoshInitialized = !!poshWidgetContainer && !!poshWidgetButton;
            if (isLivePersonInitialized && isPoshInitialized) {
                let chatButton = livePersonButton.cloneNode(true) as HTMLElement;
                chatButton.className = "newLivePersonButton";
                chatButton.id = "newLivePersonButton";               
                chatButton.style.setProperty('visibility', 'visible', 'important');
                chatButton.addEventListener("click", () => {
                    poshWidgetContainer.style.setProperty('display', 'block', 'important');
                    if(document?.querySelector('#posh-widget-button')?.className === 'show-posh-widget'){
                        poshWidgetButton.click();
                    }
                });

                livePersonButton.parentNode?.append(chatButton);
            } else {
                console.warn("Vychat/Posh Integration Failed");
            }
        });
    }
}