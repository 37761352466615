export default class ColumnDefinitionModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly label: string;
    public readonly fieldKey: string;
    constructor(props: Readonly<ColumnDefinitionModel> | undefined | null){
        let { label = "", fieldKey = "" } = props || {};
        this.label = label;
        this.fieldKey = fieldKey;
    }
}

