enum RichTextComponentTypesEnum{
    HyperLink = "hyperlink",
    EntryHyperlink = "entry-hyperlink",
    AssetHyperlink = "asset-hyperlink",
    EmbeddedEntryInline = "embedded-entry-inline",
    EmbeddedEntryBlock = "embedded-entry-block",
    EmbeddedAssetBlock = "embedded-asset-block",
    Table = "table"
}

export default RichTextComponentTypesEnum;