
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import ITabComponentProps from "./ITallTabComponetProps";

export function TallTab(props: ITabComponentProps){
    let {tabHeader, tabDescription, icon, selectedIcon, isSelected, onTabSelected, index, id} = props;
    let iconUrl = isSelected && selectedIcon?.imageURL ? selectedIcon?.imageURL : icon?.imageURL;
    let imageClassName = isSelected ? "icon-on" : "icon-off";// compensating for design styles
    return <button onClick={onTabSelected} className="tab" role="tab" aria-selected={isSelected} aria-controls={`panel-${index}`} id={`tab-${index}`} tabIndex={index} data-contentid={id}>
        {iconUrl && <div className={imageClassName}>
            <img className="icon-on" src={iconUrl} alt="" aria-hidden="true"/>
        </div>}
        <div className="tab-title">{tabHeader}</div>
        <div className="tab-desc">{tabDescription}</div>
    </button>;
}

TallTab.displayName = "TallTab";
export default withErrorBoundary(TallTab);