import TestimonialModel from "./TestimonialModel";
import ImageModel from "./ImageModel";
import IClientServerModel from "../interfaces/IClientServerModel";

export default class TestimonialCarouselModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string | undefined = "";
    public readonly htmlId?: string | undefined = "";
    public readonly header: string = "";
    public readonly showQuotes: boolean = false;
    public readonly backgroundImage: ImageModel | null = null;
    public readonly content: ReadonlyArray<Readonly<TestimonialModel>> = [];
    constructor(props: Readonly<TestimonialCarouselModel> | null | undefined) {
        const {id = "", htmlId = "", header = "", backgroundImage = null, showQuotes = false} = props || {};
        let {content = []} = props || {};
        this.id = id;
        this.htmlId = htmlId;
        this.header = header;
        this.showQuotes = showQuotes;
        this.backgroundImage = new ImageModel(backgroundImage);
        this.content = content?.map((contentfulTestimonial: any) => new TestimonialModel(contentfulTestimonial));
    }
}


