enum LinkDataTypeEnum{
    // @ts-ignore
    RelativeURL = "relativeURL",
    // @ts-ignore
    AnchorLink = "anchorLink",
    // @ts-ignore
    FullURL = "fullURL",
    // @ts-ignore
    ModalLink = "modalLink",
    // @ts-ignore
    Email = "email",
    // @ts-ignore
    Phone = "phone",
    // @ts-ignore
    Unspecified = "unspecified",
    // @ts-ignore
    Scripted = "scripted",
}

export default LinkDataTypeEnum;
