import React, { SVGProps } from 'react'

const CheckmarkNoBg: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='18'
    height='13'
    viewBox='0 0 18 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17 1L6 12L1 7'
      stroke={props.color ?? 'white'}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default CheckmarkNoBg
