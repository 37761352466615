
import stylesBase from "../_PromotionalBase.module.scss";
import styles from "./PromoShort.module.scss";
import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";
import PromotionModel from "../../../../models/PromotionModel"
import ImageModel from "../../../../models/ImageModel";
import LinkButtonList from "../../_shared/LinkButtonList/LinkButtonList";
import SideAlignmentEnum from "../../../../enums/SideAlignmentEnum";
import CalculateNavigationTheme from "../../../_shared/helpers/CalculateNavigationTheme";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateNavigationTheme } from "../../../../redux/slices/pageContentSlice";

export function PromoShortRender(props: PromotionModel & IComponentProps)  {
    const { id, htmlId, primary, className = "", textAlignment, theme, content = {}} = props;
    const { header = "", secondaryHeader = "", links = [],
        image = new ImageModel(null), body
    } = content;
    const layoutAlignmentClassName = textAlignment === SideAlignmentEnum.Left ? "" : styles["offset"];
    const colAlignmentClassName = textAlignment === SideAlignmentEnum.Left ? "" : "offset-lg-6";

    const {
        imageURL: backgroundImageURL,
        description: backgroundDescription
    } = image || new ImageModel(null);

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    const themeClassName = (theme === ThemeTypeEnum.Dark) ? styles.dark : styles.light;

    return (
    <div className={`layout ${className} ${layoutAlignmentClassName} ${stylesBase['c-promo']} ${styles['c-promo-short']} ${themeClassName}`} id={htmlId} data-contentid={id}>
        <div className={`layout ${styles['c-promo-short-inline-image']}`} aria-label={backgroundDescription} style={{backgroundImage:backgroundImageURL}}/>
        <div className={`layout ${styles['c-promo-short-gradient']}`}/>
        <div className={`${styles['container']} container`}>
            <div className="row align-items-center">
                <div className={`col-12 col-lg-6 ${colAlignmentClassName}`}>
                    <div className="eyebrow">{secondaryHeader}</div>
                    <h2>{header}</h2>
                    {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                    <LinkButtonList links={links} />
                </div>
            </div>
        </div>
    </div>
    );
}
PromoShortRender.displayName = "PromoShort";
export default withErrorBoundary(PromoShortRender);