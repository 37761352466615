import IClientServerModel from "../interfaces/IClientServerModel";
import KeyValueModel from "./KeyValueModel";
import SmartLinkModel from "./SmartLinkModel";

export default class NavToolbarModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined;
    public readonly routingNumber?: KeyValueModel;
    public readonly navigationItems?: SmartLinkModel[];
    public readonly tabs?: SmartLinkModel[];
    public readonly callToAction?: SmartLinkModel | null;
    public readonly mobileCTA?: SmartLinkModel | null;
    constructor(props: NavToolbarModel) {
        let {routingNumber, navigationItems, tabs, callToAction, mobileCTA} = props;
        this.routingNumber = routingNumber;
        this.callToAction = callToAction ? new SmartLinkModel(callToAction) : null;
        this.mobileCTA = mobileCTA ? new SmartLinkModel(mobileCTA) : null;
        this.navigationItems = navigationItems?.map(link=>{
            return  new SmartLinkModel(link);
        });
        this.tabs = tabs;
    }
}

export const _clientType = NavToolbarModel.constructor.name;

