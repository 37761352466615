/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import  { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import styles from './FormInput.module.scss'
import { phoneNumberAutoFormat } from '../../../helpers/func/phoneFormatter'
import { defaultRegex, getFormattedErrors } from './utils'
import HelperText from '../HelperText'
import { FieldType } from '../../../../components/Form/FormField'

export interface ErrorMessages {
  minMax?: string
  exactLength?: string
  requiredField?: string
}
export interface FormInputProps
  extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'prefix' | 'onSelect'> {
  name: string
  label: string
  regex?: RegExp
  disabled?: boolean
  className?: string
  placeholder?: string
  maxLength?: number
  minLength?: number
  helperText?: string
  isRequired?: boolean
  value?: string
  style?: CSSProperties
  onChange?: (value: string) => void
  type?: FieldType //  'text' | 'phone' | 'textArea' | 'email' | 'memberNumber'
  customErrorMessages?: ErrorMessages
  textTransform?: 'uppercase' | 'capitalize' | 'none',
  shouldReset?: boolean
}

const FormInput: FC<FormInputProps> = ({
  name,
  type,
  style,
  label,
  regex,
  onChange,
  disabled,
  className,
  maxLength,
  isRequired,
  helperText,
  placeholder,
  defaultValue,
  textTransform,
  minLength = 0,
  customErrorMessages,
  shouldReset
}) => {
  const [value, setValue] = useState(defaultValue?.toString() ?? '')
  const [isFocused, setIsFocused] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const [errorText, setErrorText] = useState<string | undefined>(undefined)

  const resizeTextarea = () => {
    const textarea = document.getElementById(name)
    if (textarea && type === FieldType.TextArea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  const textarea = document.getElementById(name)
  textarea?.addEventListener('input', resizeTextarea)
  const reg = regex ?? defaultRegex(type ?? FieldType.AlphanumericTextField)
  const errors = { ...getFormattedErrors(label, minLength, maxLength ?? 0), ...customErrorMessages }
  const CustomInput = type === FieldType.TextArea ? 'textarea' : 'input'

  const handleFocus = () => {
    setIsTouched(true)
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)

    if(type === FieldType.EmailAddress) {
      let formattedValue = value
      setValue(formattedValue)
      if (onChange) onChange(type === FieldType.EmailAddress ? value.replace(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, value) : value)
      return
    }

    setValue((oldValue: any) => oldValue.trim())
  }

  const handleChange = (value: string) => {
    if(type !== FieldType.EmailAddress) {
      if (!value.match(reg) || (maxLength && !(value.length <= maxLength))) return
    }

    if(type === FieldType.MemberNumber) {
      const formattedValue = value.replace(/^(?:[0-9 ]*)$/, '');
      setValue(formattedValue)
    }

    const formattedValue = type === FieldType.PhoneNumber ? phoneNumberAutoFormat(value) : value
    setValue(formattedValue)
    if (onChange) onChange(value)
  }

  const handleErrors = () => {
    if (isTouched && !isFocused) {
      if (isRequired && value === '') {
        setErrorText(errors.requiredField)
      } else {
        if (!maxLength) {
          setErrorText(undefined)
        }
      }
      return
    }
    if (isTouched && value !== '') {
      if (maxLength) {
        if (minLength === 0) {
          if (value.length === maxLength) {
            setErrorText(undefined)
          } else {
            setErrorText(errors.exactLength)
          }
        } else {
          if (value.length >= minLength && value.length <= maxLength) {
            setErrorText(undefined)
          } else {
            setErrorText(errors.minMax)
          }
        }
      }
      return
    }
    setErrorText(undefined)
  }

  useEffect(() => {
    handleErrors()
  }, [value, isTouched, isFocused])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultValue) setValue(defaultValue?.toString().trim())
  }, [defaultValue])

  useEffect(() => {
    if(shouldReset){
      setValue("");
      setIsTouched(false);
    }
  }, [value, setValue, shouldReset])

  return (
    <div className={styles.formInput}>
      {label && (
        <label htmlFor={name} className={isFocused || value !== '' ? styles.labelFocused : ''}>
          {label}
        </label>
      )}
      <CustomInput
        id={name}
        name={name}
        value={value}
        onBlur={handleBlur}
        disabled={disabled}
        onFocus={handleFocus}
        className={[className].join(' ')}
        style={{ ...style, textTransform }}
        onChange={(e:any ) => { handleChange(e.target.value) }}
        placeholder={isFocused ? '' : placeholder || label}
      />
      {helperText && !errorText && <HelperText text={helperText} style={style} />}
      {errorText && <HelperText text={errorText} className={styles["danger-text"]} />}
    </div>
  )
}

FormInput.defaultProps = {
  type: undefined,
  minLength: 0,
  label: undefined,
  style: undefined,
  regex: undefined,
  isRequired: false,
  onChange: undefined,
  maxLength: undefined,
  className: undefined,
  textTransform: 'none',
  helperText: undefined
}

export default FormInput