import PromotionModel from "./PromotionModel";
import CTABarProductModel from "./CTABarProductModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import DisclosureItemModel from "./DisclosureItemModel";
import IEnrichment from "../interfaces/IEnrichment";

export default class CampaignLandingPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly hero?: PromotionModel | null  | undefined;
    public readonly callToActionBar?: null | Readonly<CTABarProductModel>;
    public readonly secondaryPromos?: PromotionModel[] | null | undefined;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly title?: string;
    public readonly disclosures?: DisclosureItemModel[];

    constructor(props:Readonly<CampaignLandingPageModel> | null | undefined) {
        const { htmlId, hero, callToActionBar, templateType, secondaryPromos, title, navigationTheme, 
            description, enrichments = [], keywords = [], disclosures = []} = props || {};
        this.htmlId = htmlId;
        this.title = title || "VyStar Credit Union";
        this.hero = new PromotionModel(hero ?? null);
        this.callToActionBar = new CTABarProductModel(callToActionBar ?? null);
        this.templateType = templateType || null;
        this.secondaryPromos = secondaryPromos?.map( pm => new PromotionModel(pm)); 
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description;
        this.title = title;
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
    }
}

