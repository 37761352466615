import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import { Document } from "@contentful/rich-text-types";
import IEnrichment from "../interfaces/IEnrichment";

export default class SomethingWentWrongPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null = PageTemplateEnum.SomethingWentWrong;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Light;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly title?: string;
    public readonly content?: Document | null;

    constructor(props:Readonly<SomethingWentWrongPageModel> | null | undefined) {
        const {title = "VyStar Credit Union", templateType = PageTemplateEnum.SomethingWentWrong, description = "", enrichments = [], keywords = [], content} = props || {};
        this.templateType = templateType;
        this.navigationTheme = ThemeTypeEnum.Light;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description;
        this.title = title;
        this.content = content;
    }
}