import IClientServerModel from "../interfaces/IClientServerModel";
import NavigationItemsModel from "./NavigationItemsModel";
import SubFooterModel from "./SubFooterModel";
import FooterSupportModel from "./FooterSupportModel";

export default class FooterModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string | undefined = "";
    public readonly htmlId?: string | undefined = "";
    public readonly navigationItems: Array<NavigationItemsModel>;
    public readonly subFooter: SubFooterModel | null | undefined;
    public readonly footerSupportContent: FooterSupportModel | null | undefined;

    constructor(contentfulHeroModel: Readonly<FooterModel> | undefined | null){
        let {id, htmlId, navigationItems = [], subFooter, footerSupportContent } = contentfulHeroModel || {};
        this.id = id;
        this.htmlId = htmlId;
        this.navigationItems = navigationItems ? navigationItems.map(n=> new NavigationItemsModel(n)) : [];
        this.footerSupportContent = new FooterSupportModel(footerSupportContent);
        this.subFooter = new SubFooterModel(subFooter);
    }
}

