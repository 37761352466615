import { useEffect, useRef } from "react"


export function HtmlDiv(props: any){

    const divRef = useRef<HTMLDivElement>(null);

    const { innerHTML, className } = props;

    useEffect(()=>{

        if(divRef?.current && innerHTML){
            divRef.current.innerHTML = innerHTML;
        }

    },[innerHTML, divRef]);


    return <div ref={divRef} className={className} />
}