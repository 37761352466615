import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import ScrollDirectionEnum from '../../enums/ScrollDirectionEnum';
interface IHeaderContextChangedPayload{
    isToolbarInitialized: boolean,
    desktopHeaderOffsetHeight: number,
    alertsOffsetHeight: number,
    windowScrollY: number,
    windowWidth: number,
}
interface IBannerAlertTracking{
    overriddenIds: Readonly<Array<Readonly<string>>>;
}

export interface globalContentDuck_IReducerStateModel {
    bannerAlerts: IBannerAlertTracking,
    mobileMenu:{
        isMenuOpen: boolean,
        activeIndex: number | null,
    },
    desktopMenu:{
        activeSubmenuIndex: number | null,
        menuHeight: number | null | undefined    
    },
    header:{
        isSticky: Boolean,
        headerSpacerHeight: number,
        stickyMinScrollHeight: number,
        isLoginModalVisible: boolean
    },
    scroll:{
        scrollDirection: ScrollDirectionEnum,
        lastScrollPosition: number
    },
    windowWidth: number,
    search: string,
}

const initialState: globalContentDuck_IReducerStateModel = {

    bannerAlerts: {
        overriddenIds:[],
    },
    mobileMenu:{
        isMenuOpen:false,
        activeIndex: null,
    },
    desktopMenu:{
        activeSubmenuIndex:null,
        menuHeight: 0
    },
    header:{
        isSticky: false,
        headerSpacerHeight: 0,
        stickyMinScrollHeight: 0,
        isLoginModalVisible: false,
    },
    scroll:{
        scrollDirection: ScrollDirectionEnum.None,
        lastScrollPosition: 0
    },
    windowWidth: 0,
    search: ""
}

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
        desktopMenuSetActiveIndex: (state:any, action: PayloadAction<number | null>) => {
            const activeIndex = action?.payload;
            let mobileMenuReset = state.mobileMenu;
            const stateReset = activeIndex !== null ? {
                mobileMenu: mobileMenuReset,
                header: {
                    ...state.header,
                    isLoginModalVisible: false
                }
            } : {};
            return {
                ...state,
                desktopMenu: {
                    ...state.desktopMenu,
                    activeSubmenuIndex: activeIndex
                },
                ...stateReset
            };
        },
        pageResize: (previousState:any, action: PayloadAction<IHeaderContextChangedPayload>) => {
            let actionPayload = action.payload as IHeaderContextChangedPayload
            let {alertsOffsetHeight, desktopHeaderOffsetHeight, isToolbarInitialized, windowScrollY, windowWidth} = actionPayload;
            if (isToolbarInitialized) {//TODO: rename isToolbarInitialized to isDesktopToolbarVisible
                let {lastScrollPosition, scrollDirection: lastScrollDirection} = previousState?.scroll;
                let stickyMinScrollHeight = 40 + alertsOffsetHeight;//todo: replace 40 with read value... perhaps toolbar height
                let currentScrollPosition = windowScrollY;
                let isSticky = currentScrollPosition >= stickyMinScrollHeight;
                let isScrolledPastAlerts = windowScrollY > alertsOffsetHeight;
                let scrollDirection = ScrollDirectionEnum.Up;

                if (!isScrolledPastAlerts) {
                    scrollDirection = ScrollDirectionEnum.None;
                } else if (currentScrollPosition < lastScrollPosition) {//has scrolled up
                    scrollDirection = ScrollDirectionEnum.Up;
                } else if (currentScrollPosition > lastScrollPosition) {//has scrolled down
                    scrollDirection = ScrollDirectionEnum.Down;
                } else {
                    scrollDirection = lastScrollDirection;
                }

                return {
                    ...previousState,
                    desktopMenu: initialState.desktopMenu,
                    header: {
                        ...previousState.header,
                        isSticky,
                        headerSpacerHeight: isSticky ? desktopHeaderOffsetHeight : 0,
                        stickyMinScrollHeight
                    },
                    scroll:{
                        scrollDirection,
                        lastScrollPosition: currentScrollPosition,
                    },
                    windowWidth
                };
            }
            else{
                return {
                    ...previousState,
                    header: initialState.header
                };
            }
        },
        pageScroll: (state:any, action: PayloadAction<IHeaderContextChangedPayload>) => {
            let actionPayload = action.payload as IHeaderContextChangedPayload;
            let {alertsOffsetHeight, desktopHeaderOffsetHeight, isToolbarInitialized, windowScrollY} = actionPayload;
            if (isToolbarInitialized) {//TODO: rename isToolbarInitialized to isDesktopToolbarVisible
                let {lastScrollPosition, scrollDirection: lastScrollDirection} = state?.scroll;
                let stickyMinScrollHeight = 40 + alertsOffsetHeight;//todo: replace 40 with read value... perhaps toolbar height
                let currentScrollPosition = windowScrollY;
                let isSticky = currentScrollPosition >= stickyMinScrollHeight;
                let isScrolledPastAlerts = windowScrollY > alertsOffsetHeight;
                let scrollDirection = ScrollDirectionEnum.Up;

                if (!isScrolledPastAlerts) {
                    scrollDirection = ScrollDirectionEnum.None;
                } else if (currentScrollPosition < lastScrollPosition) {//has scrolled up
                    scrollDirection = ScrollDirectionEnum.Up;
                } else if (currentScrollPosition > lastScrollPosition) {//has scrolled down
                    scrollDirection = ScrollDirectionEnum.Down;
                } else {
                    scrollDirection = lastScrollDirection;
                }

                return {
                    ...state,
                    header: {
                        ...state.header,
                        isSticky,
                        headerSpacerHeight: isSticky ? desktopHeaderOffsetHeight : 0,
                        stickyMinScrollHeight
                    },
                    scroll:{
                        scrollDirection,
                        lastScrollPosition: currentScrollPosition
                    }
                };
            }
            else{
                return {...state};
            }
        },
        mobileMenuSetActiveIndex: (state:any, action: PayloadAction<number | null>) => {
            const activeIndex = action?.payload
            return {
                ...state,
                mobileMenu: {
                    ...state.mobileMenu,
                    activeIndex
                }
            };
        },
        mobileMenuToggleAction: (state:any, action: PayloadAction<null>) => {
            const {mobileMenu} = state;
            const {isMenuOpen, activeIndex} = mobileMenu;
            let headerReset = {
                ...state.header,
                isLoginModalVisible: false
            }
            let desktopMenuReset = state.desktopMenu;
            return {
                ...state,
                mobileMenu: {
                    ...state.mobileMenu,
                    isMenuOpen: !isMenuOpen,
                    activeIndex: isMenuOpen ? null : activeIndex
                },
                desktopMenu: desktopMenuReset,
                header: headerReset
            };
        },
        desktopMenuSetHeight: (state:any, action: PayloadAction<number>) => {
            const payload: number | null | undefined = action?.payload || 0;
            const menuHeight = payload;
            let {desktopMenu} = state;
            desktopMenu = {...desktopMenu, menuHeight};
            return {...state, desktopMenu};
        },
        hideAlertById: (state:any, action: PayloadAction<string>) => {
            const id: string | null | undefined = action?.payload;
            const {bannerAlerts} = state;
            const {overriddenIds = [], alerts = []} = bannerAlerts || {};
            let newOverrides = [...overriddenIds, id || ""];
            try {
                const myStorage = window.sessionStorage;
                myStorage.setItem("alertOverrides", JSON.stringify(newOverrides));
            }
            catch(e){
                console.log(e);
            }
            return { ...state, bannerAlerts:{overriddenIds: newOverrides ,  alerts}};
        },
        setSearch:  (state:any, action: PayloadAction<string>) => {
            const search = action?.payload;
            return { ...state, search};
        }
  },
})

// Action creators are generated for each case reducer function
export const { desktopMenuSetActiveIndex, pageScroll, mobileMenuToggleAction, mobileMenuSetActiveIndex, 
    desktopMenuSetHeight, hideAlertById, setSearch, pageResize } = globalSlice.actions

export default globalSlice.reducer