import HomePageModel from "../../../models/HomePageModel";
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import PromotionalSecondary from "../../components/Promotional/PromotionalSecondary";
import CategoryCardList from '../../components/CategoryCardList/CategoryCardList';
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import ImageList from "../../components/ImageList/ImageList";
import { Disclosures } from '../../components/Disclosures/Disclosures';
import { PersonalizedPromo } from "../../components/Personalization/PersonalizedPromo";
import { PersonalizedCategoryCards } from "../../components/Personalization/PersonalizedCategoryCards";

export default function HomePage(props: IComponentProps & HomePageModel) {

    let { htmlId, className="", mainPromo = {}, secondaryPromos = [], shouldPersonalize, carousel, 
    largeCarousel, imageList, backgroundImage, disclosures, personalizedCategory, personalizedPromo } = props;

    let promoVariations = personalizedPromo?.variatians;
    let categoryVariations = personalizedCategory?.variatians;

    return (
        <div id={htmlId} {...{ className }}>
            <section>
                { !!promoVariations?.length ? shouldPersonalize ? <PersonalizedPromo variatians={promoVariations} /> : <Promotional {...promoVariations[promoVariations.length-1]}/> : <></>}
            </section>
            <section style={{backgroundImage: `url(${backgroundImage?.imageURL})`}}>
                {!!categoryVariations?.length ? shouldPersonalize ? <PersonalizedCategoryCards variatians={categoryVariations} /> : <CategoryCardList {...categoryVariations[categoryVariations.length-1]} className={'overlap-top-50'}/>:<></>}
                <TestimonialCarousel className="padding-bottom-120 padding-top-120" {...carousel}/>
            </section>
            <section>
                <ProductCarousel {...largeCarousel}/>
            </section>
            <section>
                <PromotionalSecondary {...mainPromo}/>
            </section>
            <section>
                <ImageList {...imageList}/>
            </section>
            <section>
                {secondaryPromos?.map((promo, index) => <PromotionalSecondary key={index} {...promo}/>)}
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}

