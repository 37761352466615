import KeyValuePairType from "./KeyValuePairType";

export function getQueryStringParams(): Array<KeyValuePairType<string, string>> {
    let queryStringParams = window.location.search.replace("?", "").split("&").map(kv => {
        let [key, value] = kv.split("=");
        return { key, value };
    });
    return queryStringParams;
}

export function getQueryStringParameter(key: string): string {
    let queryStringAccessToken = getQueryStringParams().find(kv => kv.key.toLowerCase() === key)?.value;
    return queryStringAccessToken || "";
}