import IClientServerModel from "../interfaces/IClientServerModel";
import ImageModel from "./ImageModel";
import SmartLinkModel from "./SmartLinkModel";
import {Document} from "@contentful/rich-text-types";

export default class BioCardModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly label: string;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public readonly altText?: string | undefined | null;
    public readonly image?: ImageModel | undefined | null;
    public readonly header?: string | undefined | null;
    public readonly subHeader?: string | undefined | null;
    public readonly link?: SmartLinkModel | undefined | null;
    public readonly body?: Document | null | undefined;

    constructor(props: Readonly<BioCardModel> | undefined | null){
        const {htmlId = "", altText, image, header, subHeader, body, link, label = "", id} = props || {};
        this.htmlId = htmlId;
        this.id = id;
        this.image = new ImageModel(image);
        this.header = header;
        this.subHeader = subHeader;
        this.body = body;
        this.link = link;
        this.altText = altText;
        this.label = label;
    }
}
