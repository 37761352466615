import stylesBase from "../../Locator/Locator.module.scss";
import styleModal from "../../../components/_shared/Dialogs/ModalDialog.module.scss";
import SmartLink from "../SmartLink/SmartLink";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { addValues } from "../../../../redux/slices/pageContentSlice";
import { closeModal } from "../../../../redux/slices/modalSlice";
import { useState } from "react";
import { IDrivingInstruction, ILocation } from "../../Locator/LocationInterfaces";
import LocationItem from "./LocationItem";
export interface ILocationBodyReduxDispatch{
    valuesAdd: Function,
    modalClose: Function,
}
export interface ILocationBodyReduxProps{
    location: ILocation,
    getDirectionsStart: string,
    summary: string,
    startAddress: string,
    endAddress: string
    duration: string,
    distance: string
}
// MARK TO DO: COMPLETE DIRECTIONS FORM
// MARK TO DO: ADD DIRECTIONS FORM INPUT TO REDUX
export const mapDispatchToProps = (dispatch: any, _: any):ILocationBodyReduxDispatch => {
    return {
        valuesAdd: (payload: any)=>{
            dispatch(addValues(payload));
        },
        modalClose: (payload: any)=>{
            dispatch(closeModal(null));
        }
    };
};
function mapStateToProps(state: any): ILocationBodyReduxProps {
    const { location, getDirectionsStart, summary, startAddress, endAddress, duration, distance } = state.pageContent;
    return { location, getDirectionsStart, summary, startAddress, endAddress, duration, distance };
  }
function LocationBody({valuesAdd, location, getDirectionsStart, summary, startAddress, endAddress, duration, distance}: ILocationBodyReduxProps & ILocationBodyReduxDispatch) {

    const { latitude, longitude } = location;

    const [directions, setDirections] = useState<Array<IDrivingInstruction>>([]);

    var directionsService = new google.maps.DirectionsService();
    const shouldShowDirections = !!directions.length;

    return (
        <div>
            <div className={styleModal["kentico-form"]}>
                <div className={stylesBase["form-group"]}>
                <TextField 
                    variant="outlined" 
                    label={"Starting Address, City, State, Zip"}  
                    value={getDirectionsStart}
                    type="text"
                    id="get-directions-start" 
                    name="search"
                    onChange={(e:any)=>{
                        const payload = {
                            getDirectionsStart: e.target.value
                        }
                        valuesAdd(payload);
                    }}
                    classes={{
                        root: "field"
                    }} 
                    size={"small"} />
                </div>
                <div className="form-group d-none">
                    <div className="form-label">
                        <label htmlFor="get-directions-end">Destination Address</label>
                    </div>
                    <div className="form-input">
                        <input id="get-directions-end" className="form-control" type="text" data-maxzpsw="0" />
                    </div>
                </div>
                <div className={`buttons ${styleModal["SubmitGroup"]}`}>
                    <SmartLink className="getDirectionsSubmit" linkText={"Submit"} type={LinkTypeEnum.PrimaryButton} onClick={(e: any)=>{
                        e.preventDefault();
                        const request = {
                            origin: getDirectionsStart,
                            destination: `${latitude}, ${longitude}`,
                            travelMode: google.maps.TravelMode.DRIVING
                        }
                        directionsService.route(request, function (response, status) {
                            if (status === google.maps.DirectionsStatus.OK) {
                                var googleOverview = response?.routes[0];
                                var googleSteps = response?.routes[0]?.legs[0].steps || [];
                                setDirections(googleSteps as IDrivingInstruction[]);
                                if(googleOverview){
                                    const payload = {
                                        startAddress: googleOverview.legs[0].start_address,
                                        endAddress: googleOverview.legs[0].end_address,
                                        summary: googleOverview.summary,
                                        duration: googleOverview.legs[0].duration?.text,
                                        distance: googleOverview.legs[0].distance?.text,
                                    }
                                    valuesAdd(payload);
                                    // CANT ADD MESSY OBJECT, SO WILL NEED TO JUST SET PROPS INSTEAD
                                }
                            }
                            else if (status === google.maps.DirectionsStatus.NOT_FOUND || status === google.maps.DirectionsStatus.ZERO_RESULTS) {
                                console.log("FAIL!")
                            }
                        })                    
                    }}/>
                </div>
            </div>
            {/* MARK TO DO -  ADD DIRECTIONS HERE */}
            {shouldShowDirections && 
                <div className={`${styleModal["zag-popup-background"]} ${stylesBase["get-directions-html"]}`}>
                    <div className={`${stylesBase["content"]}`}>
                        <h4>Directions</h4>
                        <div className={`${stylesBase['google-overview']}`}>
                            <div className={`${stylesBase['google-address']}`}>
                                <div className='google-start'>
                                    {startAddress}
                                </div>
                                <div className='google-end'>
                                    {endAddress}
                                </div>
                                <div className={`${stylesBase['google-via']}`}>
                                        Via {summary}
                                </div>
                            </div>
                            <div className={`${stylesBase['google-distance']}`}>
                                <div className={`${stylesBase['google-duration']}`}>
                                    {duration}
                                </div>
                                <div className={`${stylesBase['google-miles']}`}>
                                    {distance}
                                </div>
                            </div>
                        </div>
                        <div className={`${stylesBase['google-overflow']}`}>
                            {
                                directions.map((googleStep: IDrivingInstruction, indexNumber: number)=>{
                                    return (
                                        <LocationItem
                                            drivingKey={indexNumber} 
                                            drivingInstruction={googleStep}
                                            key={indexNumber}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        <div className="zag-popup-shade"></div>
    </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationBody);