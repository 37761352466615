import IClientServerModel from "../interfaces/IClientServerModel";
import ContentRichTextDocumentModel from "./ContentRichTextDocumentModel";
import ContentTypesEnum from "../enums/ContentTypesEnum";

export default class ContentBlockModel implements IClientServerModel{
    public readonly contentType: ContentTypesEnum;
    public readonly id: string | undefined;
    public readonly htmlId: string | undefined = "";
    public readonly name: string;
    public readonly richText: ContentRichTextDocumentModel | null | undefined;
    constructor(props: Readonly<ContentBlockModel | undefined>) {
        let {contentType, id, htmlId, name, richText} = props || {};
        this.id = id;
        this.htmlId = htmlId;
        this.contentType = contentType || ContentTypesEnum.None;
        this.name = name || "";
        this.richText = richText;
    }
}

