import "./DesktopToolbar.module.scss";
import React, { useState } from "react";
import DesktopToolbarModel from "../../../../models/DesktopToolbarModel";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import LinkDataTypeEnum from "../../../../enums/LinkDataTypeEnum";
import { showLogin, showSearch } from "../../../environmentVariables";
import SearchPanel from "../SearchPanel/SearchPanel";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import { connect, useDispatch } from "react-redux";
import { setSearch } from "../../../../redux/slices/globalSlice";
import IThemedDisplay from "../../../../interfaces/IThemedDisplay";
import { RootState } from "../../../../redux/store";

export interface IOwnProp extends DesktopToolbarModel{
    isLoginModalVisible: boolean,
    clearsearch: string,
    activeTabIndex:number,
    themeClass: string,
}

interface IMapStateProps extends IThemedDisplay {
    navigationTheme: string
}

export const mapStateToProps = (state: RootState, ownProps?: any): IMapStateProps => {
    const {navigationTheme} = state.pageContent || {};
    return {
        navigationTheme,
    } as IMapStateProps;
}

export function DesktopToolbar(props: IOwnProp & IMapStateProps) {
    const {activeTabIndex, routingNumber, links, callToAction, tabs, themeClass, navigationTheme} = props;

    const [isSearchPanelOpen, setSearchPanelOpen] = useState(false);

    const noTabs = !!tabs ? "" : "no-tabs";

    const dispatch = useDispatch();

    return <div id="toolbar" className={`g-toolbar ${noTabs}`}>
        <div className="container">
            <div className="row no-gutters g-0 nav-height">
                <div className={`col-lg-4 no-bg ${themeClass}`}>
                    <ul className="site-select">
                        {tabs?.map((tab, index)=>{
                            return <li className={activeTabIndex === index ? "active" : "" } key={index}>
                                <SmartLink {...tab} dataType={LinkDataTypeEnum.RelativeURL} />
                            </li>
                        })}
                    </ul>
                </div>
                <div className="col-lg-8 text-right">
                    <div className="link-list">
                        <ul>
                            <li>{routingNumber?.value}</li>
                            {showSearch && <li className="search">
                                <SmartLink 
                                    onClick={(e:React.UIEvent)=>{ 
                                        e.preventDefault(); 
                                        setSearchPanelOpen(!isSearchPanelOpen);  
                                        dispatch(setSearch(""));  
                                    }} 
                                    dataType={LinkDataTypeEnum.Scripted} type={LinkTypeEnum.Default} 
                                    linkText={"Search"} className={`${isSearchPanelOpen ? 'searchbar-open' : undefined}`}/>
                                <SearchPanel isSearchVisible={isSearchPanelOpen}/>
                            </li>}
                            {links?.map((linkData, index)=>{
                                return <li key={index}>
                                    <SmartLink {...linkData}/>
                                </li>
                            })}
                            {showLogin && <li className="login">
                                <SmartLink {...{...callToAction, type:LinkTypeEnum.Default}}/>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
export default connect(mapStateToProps) (withErrorBoundary(DesktopToolbar));