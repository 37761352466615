import IClientServerModel from "../interfaces/IClientServerModel";
import RateLineModel from "./RateLineModel";
import SmartLinkModel from "./SmartLinkModel";
import RateAccountModel from "./RateAccountModel";

export default class RateGroupModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly name?: string | undefined = "";
    public readonly htmlId?: string | undefined = "";
    public readonly accountGroupName?: string | undefined = "";
    public readonly iframeURL?: string | undefined;
    public readonly headerLink?: SmartLinkModel | undefined;
    public readonly filterLabel?: string | undefined = "";
    public readonly allFilterSelection?: string | undefined = "";
    public readonly advertisedRate?: RateLineModel | undefined;
    public readonly advertisedRateType?: string | undefined = "";
    public readonly accounts?: RateAccountModel[] | undefined;

    constructor(props:RateGroupModel | null){
        const {
            htmlId = "",
            name = "",
            accountGroupName = "",
            iframeURL,
            filterLabel = "",
            allFilterSelection = "",
            advertisedRateType = "",
            advertisedRate,
            accounts,
            headerLink
        } = props || {};

        this.htmlId = htmlId;
        this.name = name;
        this.headerLink = new SmartLinkModel(headerLink)
        this.filterLabel = filterLabel;
        this.allFilterSelection = allFilterSelection;
        this.advertisedRateType = advertisedRateType;
        this.advertisedRate = advertisedRate;
        this.accountGroupName = accountGroupName;
        this.iframeURL = iframeURL;
        this.filterLabel = filterLabel;
        this.accounts = accounts?.map(disclosure => {
            return new RateAccountModel(disclosure);
        }) || [];

    }
}


