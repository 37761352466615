import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";

export interface ICTABarProductContentModel {
    readonly label?: string | undefined;
    readonly name?: string | undefined;
    readonly primaryLinks?: SmartLinkModel[] | null | undefined;
    readonly secondaryLinks?: SmartLinkModel[] | null | undefined;
}

export default class CTABarProductModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public readonly content?: ICTABarProductContentModel | undefined;
    constructor(props:Readonly<CTABarProductModel> | null | undefined) {
        const {htmlId, content, id} = props || {};
        this.htmlId = htmlId;
        this.id = id;
        this.content = {
            label: content?.label,
            name: content?.name,
            primaryLinks: content?.primaryLinks?.map(link=>{
                return new SmartLinkModel(link);
            }),
            secondaryLinks: content?.secondaryLinks?.map(link=>{
                return new SmartLinkModel(link);
            })
        }
    }
}