import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import { Document } from "@contentful/rich-text-types";
import IEnrichment from "../interfaces/IEnrichment";

export interface IPageNotFoundPageJSON {
    readonly htmlId?: string | undefined;
    readonly templateType: PageTemplateEnum | null;
    readonly navigationTheme: ThemeTypeEnum | null;
    readonly keywords: IKeyword[] | null;
    readonly description: string | null;
    readonly title: string | null;
    readonly leftContent?: any;
    readonly rightContent?: any;
}

export default class PageNotFoundPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly templateType: PageTemplateEnum | null = PageTemplateEnum.PageNotFound;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly title?: string;
    public readonly leftContent?: Document | null | undefined;
    public readonly rightContent?: Document | null | undefined;
    
    constructor(props:Readonly<IPageNotFoundPageJSON> | null | undefined) {
        const { leftContent, rightContent, htmlId} = props ?? {};
        this.htmlId = htmlId;
        this.templateType = PageTemplateEnum.PageNotFound;
        this.navigationTheme = ThemeTypeEnum.Light;
        this.enrichments = [];
        this.keywords = [];
        this.description = "Page Not Found";
        this.title = "Page Not Found";
        this.leftContent = leftContent?.richText;
        this.rightContent = rightContent?.richText;
    }
}