
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import CategoryPageModel from "../../../models/CategoryPageModel";
import CategoryCardList from "../../components/CategoryCardList/CategoryCardList";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import CTABarProductDetail from "../../components/CTABarProductDetail/CTABarProductDetail";
import Disclosures from '../../components/Disclosures/Disclosures';

export default function CategoryPage(props: CategoryPageModel & IComponentProps){
    let {htmlId, className, hero, category, carousel, callToActionBar, categoryRelated, backgroundImage, disclosures} = props;
    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero}/>
            </section>
            <section className="bg-gray">
                 <CategoryCardList {...category}/>
            </section>
            <section style={{backgroundImage: `url(${backgroundImage?.imageURL})`}}>
                <TestimonialCarousel {...carousel}/>
                <CTABarProductDetail {...callToActionBar}/>
                <CategoryCardList {...categoryRelated}/>
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
