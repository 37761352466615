
import { Select as MuiSelect } from "@mui/material";
import "./Select.module.scss";

export interface ISelectProps{
    onChange?: {(event: any): void},
    children?: React.ReactNode,
    className?: string | undefined,
    name?: string | undefined,
    id?: string | undefined,
    ariaLabel?: string | undefined,
    value?: string,
    defaultValue?: string
}

export default function Select(props: ISelectProps) {
    let {onChange, className, children, name, id, value, defaultValue, ariaLabel} = props;
    return <MuiSelect
        variant="standard"
        displayEmpty
        disableUnderline
        value={value}
        defaultValue={defaultValue}
        className={`${className} select`}
        inputProps={{ 'aria-label': `${ariaLabel ? ariaLabel : 'Select Rate Type'}` }}
        onChange={onChange}
        MenuProps={{
            className: 'Select_Menu',
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin:{
                vertical: "top",
                horizontal: "left"
            }
        }}
        name={name}
        id={id}
    >
            {children}
    </MuiSelect>
}