import IClientServerModel from "../interfaces/IClientServerModel";
import ImageModel from "./ImageModel";
import SmartLinkModel from "./SmartLinkModel";

export default class CardModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly body: any | undefined | null = "";
    public readonly image: ImageModel | undefined;
    public readonly header: string | undefined = "";
    public readonly headerLink: SmartLinkModel | undefined;
    public readonly link: SmartLinkModel | undefined;
    public readonly stepNumber?: number | undefined = undefined;
    public readonly showStepNumber?: boolean | undefined = false;
    constructor(props: Readonly<CardModel> | undefined | null){
        let {htmlId = "", body, image, header, link, headerLink, stepNumber, showStepNumber} = props || {};
        this.image = image ? new ImageModel(image) : undefined;
        this.htmlId = htmlId;
        this.body = body;
        this.header = header;
        this.headerLink = new SmartLinkModel(headerLink);
        this.stepNumber = stepNumber;
        this.showStepNumber = showStepNumber;
        this.link = new SmartLinkModel(link);
    }
}

