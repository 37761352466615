enum EventListenerTypeEnum{
    // @ts-ignore
    Load = "load",
    // @ts-ignore
    Resize = "resize",
    // @ts-ignore
    Scroll = "scroll"
}

export default EventListenerTypeEnum;
