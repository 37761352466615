import envDataConfig from "../../../EnvDataConfig/EnvDataConfig";
import Encrypt from "./Encrypt";

const ValidateHuman = async (secretKey: string, token: string) => {
    const options = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        { 
          ApiSecretKey: Encrypt(secretKey), 
          Token: Encrypt(token)
        }
      )
    }

    let url = `${envDataConfig.baseUrl}Page/VerifyCaptcha`;

    return await fetch(url, options)
      .then(res => res.text())
      .then(data => {
        let returnData = JSON.parse(data);
        return returnData;
      })
      .catch(error => console.error(error))

}

export default ValidateHuman