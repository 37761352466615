import React, { useState, Fragment } from "react";
import IComponentProps from "../../../../interfaces/IComponentProps";
import styling from "../CompareTable.module.scss";
import SmartLink from "../../_shared/SmartLink/SmartLink";
import ColumnDefinitionModel from "../../../../models/ColumnDefinitionModel";
import { ITableItem } from "../../../../models/CompareTableDetailModel";
import DynamicValue from "../../_shared/ContentValue/ContentValue";
import SmartLinkModel from "../../../../models/SmartLinkModel";

interface IMobileTable {
    readonly columnDefinition: ColumnDefinitionModel[];
    readonly row: ITableItem;
    readonly numberOfRows: number;
}

export default function ComparisonCard(props: IMobileTable & IComponentProps) {
    let {columnDefinition, row, numberOfRows} = props;
    const data = row.heading;
    const ctaColKey = columnDefinition?.find(def => def?.label?.toLowerCase() === 'cta')?.fieldKey;
    const linkData = ctaColKey ? row[ctaColKey].value as SmartLinkModel : undefined;
    const [isExpanded, setIsExpanded] = useState(false);

    return ( <table className={`${styling["compare-card"]}`}>
                <thead>
                    <tr>
                        <th scope="col" colSpan={2}>{typeof data === "object" ? <SmartLink {...new SmartLinkModel(data)} className="blue"/> : data }</th>
                    </tr>
                </thead>
                <tbody>
                    { columnDefinition?.slice(0,isExpanded ? columnDefinition.length : numberOfRows)
                        .filter(colDef => colDef?.label?.toLowerCase() !== 'cta')
                        .map((column: ColumnDefinitionModel, index: number) => {
                            const columnValue = row[column.fieldKey]?.value;
                            let isFirstRow = index === 0;
                            return <Fragment key={index}>
                                <tr className={`${isFirstRow ? "desc" : ""}`}>
                                    <th scope={"col"} >
                                        {column.label}
                                    </th>
                                    <td>
                                        <DynamicValue value={columnValue} defaultString={"-"}/>
                                    </td>
                                </tr>
                            </Fragment>;
                        })
                    }
                    {(columnDefinition.length - (linkData ? 1 : 0) ) > numberOfRows &&
                    <tr className={`${styling["expand-rows-mobile"]} ${isExpanded ? styling["expanded"] : 'view-more'}`}>
                        <td colSpan={2}>
                            <a onClick={() => setIsExpanded(!isExpanded)} className={`${styling["expand-rows"]}`} aria-label="view more table rows" style={{ cursor: "pointer" }}>
                                {isExpanded ? "View less" : "View more"}
                            </a>
                        </td>
                    </tr>}
                </tbody>
                    <tfoot>
                        <tr>
                            {linkData &&
                            <td colSpan={2}>
                                <SmartLink {...{
                                    ...linkData,
                                }} />
                            </td>
                            }
                        </tr>
                    </tfoot>
            </table>);
}