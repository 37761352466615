import { v4 as uuidv4 } from 'uuid';
import aa from 'search-insights';
import envDataConfig from '../../../EnvDataConfig/EnvDataConfig';

declare global {
    interface Window { dataLayer: any[]; }
}

const InsightsClient = () => {
    const token = uuidv4();
    sessionStorage.setItem("algoliaUserToken", token);
    aa('init', {
        appId: envDataConfig.AlgoliaAppId,
        apiKey: envDataConfig.AlgoliaApiKey,
    });
    window.dataLayer?.push({
        algoliaUserToken: token,
    });
}
export default InsightsClient;