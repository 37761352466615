import { Helmet } from 'react-helmet';
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import HelmetHeadModel from '../../../models/HelmetHeadModel';
import { IKeyword } from '../../../interfaces/IPageRequestData';
import { useFaviconRequestQuery } from '../../../redux/_shared/helpers/vystarApi';

function HelmetHead(props: (HelmetHeadModel & IComponentProps)){

    let { 
        title, 
        description, 
        keywords, 
        favicon16x16 = "" ,
        favicon32x32 = "",
        canonical
    } = props;

    let keywordString = "";
    keywords?.forEach((k: IKeyword)=> keywordString += k.keyword + " ");
    
    const faviconApi = useFaviconRequestQuery(null);
    if(faviconApi.isSuccess && faviconApi.status === "fulfilled"){
        const apiData = faviconApi.data[0];
        let { favicon16x16: url16, favicon32x32:url32 } = apiData;
        favicon16x16 = url16?.imageURL || "";
        favicon32x32 = url32?.imageURL || "";
    }

    return (<Helmet>
        <title>{title}</title>
        <meta name="description" content={description ?? ""} />
        <meta name="keywords" content={keywordString.trim()} />
        <link rel="canonical" href={canonical} />
        <link rel="shortcut icon" type="image/png" sizes="32x32" href={favicon32x32} />
        <link rel="shortcut icon" type="image/png" sizes="16x16" href={favicon16x16} />
    </Helmet>);
}
export default withErrorBoundary(HelmetHead);