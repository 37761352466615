import React, {Component} from 'react';
import styles from './Carousel.module.scss';
import 'matchmedia-polyfill';
import Slider, {LazyLoadTypes, ResponsiveObject, Settings} from "react-slick";
import PrevArrowIcon from './carousel-arrow-prev.svg'
import NextArrowIcon from './carousel-arrow-next.svg'
import IContainerComponentProps from "../../../../interfaces/IContainerComponentProps";

export interface ICarouselConfig{
    tabLabels?: any,
    prevIcon?: string,
    nextIcon?: string,
    sliderRef?: any,
    beforeChange?: (currentSlide: number, nextSlide: number) => void,
    afterChange?: (currentSlide: number) => void,
    accessibility?: boolean;
    adaptiveHeight?: boolean;
    arrows?: boolean;
    asNavFor?: Slider;
    autoplaySpeed?: number;
    autoplay?: boolean;
    centerMode?: boolean;
    centerPadding?: string;
    className?: string;
    cssEase?: string;
    dotsClass?: string;
    dots?: boolean;
    draggable?: boolean;
    easing?: string;
    edgeFriction?: number;
    fade?: boolean;
    focusOnSelect?: boolean;
    infinite?: boolean;
    initialSlide?: number;
    lazyLoad?: LazyLoadTypes;
    pauseOnDotsHover?: boolean;
    pauseOnFocus?: boolean;
    pauseOnHover?: boolean;
    responsive?: ResponsiveObject[];
    rows?: number;
    rtl?: boolean;
    slide?: string;
    slidesPerRow?: number;
    slidesToScroll?: number;
    slidesToShow?: number;
    speed?: number;
    swipeToSlide?: boolean;
    swipe?: boolean;
    touchMove?: boolean;
    touchThreshold?: number;
    useCSS?: boolean;
    useTransform?: boolean;
    variableWidth?: boolean;
    vertical?: boolean;
    verticalSwiping?: boolean;
    waitForAnimate?: boolean;
}

interface IArrowConfig{
    className?: string,
    isDisabled?: (currentSlide?: number, slideCount?:number) => boolean,
    label?: string
}

interface ISlickArrow{
    tabLabels?: any,
    currentSlide?: number,
    slideCount?:number,
    icon?: string
}

export const SlickArrow = (props:ISlickArrow & IArrowConfig) => {
    const { currentSlide, slideCount, className, label, icon, isDisabled = (() => false), ...buttonProps} = props;

    return (<button
        {...buttonProps}
        className={`${className} slick-arrow ${isDisabled() ? 'slick-disabled' : ''}`}
        aria-label={label}
        aria-disabled={isDisabled()}
        type='button'
    >
        <img src={icon} alt={label}/>
    </button>);
};

export const PrevArrow = (props: ISlickArrow)=> {

    const {tabLabels = [], ...newObj} = props;

    const ariaLabel = tabLabels[(props.currentSlide || 1)-1] ? tabLabels[(props.currentSlide || 1)-1] : "arrow back";

    const label = props.currentSlide === 0 ? "disabled": ariaLabel;

    return <SlickArrow className={'slick-prev'} icon={PrevArrowIcon}
        isDisabled={(currentSlide)=> currentSlide === 0}
        label={label}
        currentSlide={props.currentSlide}
        slideCount={props.slideCount}
        {...newObj}
    />
}

export const NextArrow = (props: ISlickArrow)=> {

    const {tabLabels = [], ...newObj} = props;

    const ariaLabel = tabLabels[(props.currentSlide || 0)+1] ? tabLabels[(props.currentSlide || 0)+1] : "arrow forward";

    const label = props.currentSlide === tabLabels.length - 1 ? "disabled": ariaLabel;

    return <SlickArrow className={'slick-next'} icon={NextArrowIcon}
        isDisabled={(currentSlide, slideCount)=>currentSlide === (slideCount || 1) - 1}
        label={label}
        currentSlide={props.currentSlide}
        slideCount={props.slideCount}
        {...newObj}
    />
}

export default class Carousel extends Component<IContainerComponentProps & ICarouselConfig> {

    render() {
        let {
            children,
            className,
            prevIcon,
            nextIcon,
            sliderRef,
            tabLabels,
            ...sliderProps
        } = this.props;
        const Test = ()=><div className={'test'}/>;


        const settings: Settings = {
            infinite: false,
            accessibility: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            customPaging: (index:number) => <Test key={index}/>,
            prevArrow: <PrevArrow icon={prevIcon} tabLabels={tabLabels}/>,
            nextArrow: <NextArrow icon={nextIcon} tabLabels={tabLabels}/>,
            ...sliderProps as Settings
        }
        // @ts-ignore
        return (<Slider ref={sliderRef} {...{className: `${className} ${styles.root}`}} {...settings}>
                {children}
            </Slider>
        );
    }
};
