import ImageModel from "./ImageModel";
import IClientServerModel from "../interfaces/IClientServerModel";

export default class TestimonialModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public quote?: string = "";
    public byLine?: string = "";
    public image?: null | Readonly<ImageModel> = null;
    constructor(contentfulTestimonialModel: Readonly<TestimonialModel> | null | undefined) {
        const {htmlId = "", quote = "", byLine = "", image: contentfulImageModel = null} = contentfulTestimonialModel || {};
        this.htmlId = htmlId;
        this.quote = quote;
        this.byLine = byLine;
        this.image = !!contentfulImageModel ? new ImageModel(contentfulImageModel) : null;
    }
}


