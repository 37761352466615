import IClientServerModel from "../interfaces/IClientServerModel";
import {Document} from "@contentful/rich-text-types";

export default class QuestionAnswerModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly question: string | null | undefined;//markdown
    public readonly answer: Document | null | undefined;

    constructor(contentfulQuestionAnswerModel: Readonly<QuestionAnswerModel> | undefined | null){
        const {htmlId = "", question, answer} = contentfulQuestionAnswerModel || {}
        this.htmlId = htmlId;
        this.question = question; 
        this.answer = answer; 
    }
}

