import LinkList from "../../_shared/LinkList/LinkList";
import "./ProductPanel.module.scss"
import styles from "./ProductPanel.module.scss"
import ProductDetailModel from "../../../../models/ProductDetailModel";
import IComponentProps from "../../../../interfaces/IComponentProps";

interface ICanFocus{
    canFocus?: boolean
}

export default function ProductPanel(props:ProductDetailModel & IComponentProps & ICanFocus){

    const {className = "", id = "", header = "", subHeader = "",
        firstValuePropText = "" , firstValuePropImage = null, isActive,
        secondValuePropText = "" , secondValuePropImage = null, canFocus
    } = props;
    // Accessibility fix, make tabindex -1 so that screen readers don't tab to unavailable links
    let { links = [] } = props;
    if(links && !isActive){
        links = links.map(link=> ({...link, tabIndex: -1}));
    }


    return (
            <div id={id} className={`${styles['product-panel-container']} ${className}`} tabIndex={0} ref={ref=>{
                if(isActive && canFocus){
                    ref?.focus();
                }
            }}>
                <div className={`${styles['product-panel-content']}`}>
                    {subHeader && <div className="eyebrow">{subHeader}</div>}
                    {header && <h3>{header}</h3>}
                    <div className="col-wrap">
                        <div className="col">
                            {firstValuePropImage && <div className="icon d-none d-md-block">
                                <img src={firstValuePropImage.imageURL} alt={firstValuePropImage.description} aria-hidden="true"/>
                            </div>}
                            <p>{firstValuePropText}</p>
                        </div>
                        <div className="col">
                            {secondValuePropImage && <div className="icon d-none d-md-block">
                                <img src={secondValuePropImage.imageURL} alt={secondValuePropImage.description} aria-hidden="true"/>
                            </div>}
                            <p>{secondValuePropText}</p>
                        </div>
                    </div>
                    {links && <LinkList links={links} />}
                </div>
            </div>
    );
};
