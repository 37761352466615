import React, { cloneElement, ReactElement, ReactNode, useEffect, useMemo } from 'react';

// Boolean for troubleshooting

const DEBUG = false;

const isDebugOn = window.location.hostname === "localhost" && DEBUG;

const countTextCharacters = (el: ReactElement) : number => el.props.children.reduce((total:number, el: any)=>{
        if(el.props){
            if(el.props.children){
                // bold characters
                el.props.children.forEach((textItem:any) => {
                    if(typeof textItem === "string" && textItem !== "\r"){
                        total += textItem.length;
                    }
                });
            }else{
                // characters from links
                total += el.props.defaultLinkText?.length || el.props.linkText?.length || 0;
            }
        }else{
            // plain old text
            el.forEach((textItem:any) => {
                if(typeof textItem === "string" && textItem !== "\r"){
                    total += textItem.length;
                }
            });
        }
        return total;
    }, 0);

const mutateTextArray = (pDiv: ReactElement, characterGoal: number, dampingRatio: number): any[] => {
    let newChildren: any[] = [];
    let characterCache = 0;
    if(pDiv.props.children.length){
        let isArrayFull = false;
        pDiv.props.children.forEach((el: ReactElement)=>{
            let newTextArray: string[] = [];
            if(el.props){
                if(el.props.children){
                    const boldCharacters = el.props.children.reduce((total:number, el: any)=>{
                        if(typeof el === "string" && el !== "\r"){
                            total += el.length;
                        }
                        return total;
                    }, 0);
                    characterCache += boldCharacters;
                    if(characterCache < characterGoal){
                        newChildren.push(el);
                    }
                }else{
                    // links
                    characterCache += el.props.defaultLinkText?.length || el.props.linkText?.length || 0;
                    if(characterCache < characterGoal){
                        newChildren.push(el);
                    }
                }
            }else if (Array.isArray(el)){    
                for (var i = 0; i < el.length; i++) {
                    let textItem = el[i];

                    if(typeof textItem === "string" && textItem !== "\r"){
                        characterCache += textItem.length;
                        if(!isArrayFull){
                            if(characterCache > characterGoal){
                                const imOverBy = characterCache - characterGoal;
                                // dampingRatio of 1 means its already the right line length, so just add dots
                                // if its a single text array, you want to chop it on the characterGoal
                                // if its the end of multiple textArrays, you want to chop it on the overBy
                                const whereToChop = dampingRatio === 1 ? textItem.length : (newChildren.length > 1) ? imOverBy : characterGoal;
                                newTextArray.push((textItem.substring(0,whereToChop) + "..."));
                                newChildren.push(newTextArray);
                                isArrayFull = true;
                                break;
                            }
                            else{
                                newTextArray.push(textItem);
                                newChildren.push(newTextArray);
                            }
                        }
                    }
                }            
            }
        });
    }
    return newChildren;
}
// pass children through text chopper, configured with the following params:
// blockIndex: which block to cut
// subBlockIndex: which list item to cut
// dampingRatio: percentage of text to include before adding "..."

export function TruncateText({children, blockIndex, subBlockIndex, dampingRatio}: any){

    useEffect(()=>{},[dampingRatio]);

    const getStyles = useMemo(() => {
        return {
            background: 'aliceblue'
        };
    }, []);

    const getChildrenElement = (): ReactElement => {
        if (children.type) return children;

        return <div>{children}</div>;
    };
    
    
    const getClonedChildren = () => {
        let childrenElement = getChildrenElement();
        // remove <p>, <div>, etc past cutoff point
        let mutantChildren: Array<ReactElement> = [...childrenElement.props.children].filter((_,i:number)=>i<=blockIndex);
        // get the last block, which you intend to chop up
        let child = mutantChildren.pop();
        if(child?.type === "ul"){
            // remove extraneous "li"s
            let listItemContainer = [...child.props.children].filter((_,j:number)=>j<=subBlockIndex);
            // at this point you should only need the last list item
            if(listItemContainer.length > 0){
                let listItem: ReactElement = listItemContainer[listItemContainer.length -1];
                let pDiv = listItem.props.children?.length > 0 ? listItem.props.children[0] : undefined;
                // 1.  add characters in all text arrays
                const characterTotal = countTextCharacters(pDiv);

                const attenuationFactor = dampingRatio ? dampingRatio : 1;
                let characterTarget = (characterTotal * attenuationFactor) - 5;
                if(characterTarget > 1000){
                    characterTarget = 1000;
                }
                // 2. truncate text arrays once characterCache is busted past target
                let newChildren: Array<ReactNode> = mutateTextArray(pDiv, characterTarget, dampingRatio);
                pDiv = cloneElement(pDiv,undefined, newChildren);
                listItem = cloneElement(listItem,undefined, [pDiv]);
                listItemContainer.pop();
                listItemContainer.push(listItem);
            }
            child = cloneElement(child, undefined, listItemContainer);
        }else{
            // // 1.  add characters in all text arrays
            if(child){
                const characterTotal = countTextCharacters(child);
                const characterTarget = (characterTotal * dampingRatio) - 5;
                // 2. truncate text arrays once characterCache is busted past target
                let newChildren: Array<ReactNode> = mutateTextArray(child, characterTarget, dampingRatio);
                child = cloneElement(child,undefined, newChildren);
            }
        }
        if(child)
            mutantChildren.push(child);
        const debugStyle = isDebugOn ? {
            style: { ...childrenElement.props.style, ...getStyles }
        } : undefined;
        const clonedElement = cloneElement(childrenElement, debugStyle, mutantChildren);
        return clonedElement;
    };
    return getClonedChildren();
}
export default TruncateText;