import { CookieListItem } from "../../components/_shared/Dialogs/CookiesBody";

const cookies: CookieListItem[] = [
    {
      "site": "websiteVCU",
      "name": "AnalyticsSyncHistory",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "li_gc",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_pinterest_ct_ua",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "AWSALBCORS",
      "type": "Performance"
    },
    {
      "site": "websiteVCU",
      "name": "__cf_bm",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "__hssrc",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "__hssc",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "nmstat",
      "type": "Performance"
    },
    {
      "site": "websiteVCU",
      "name": "_ga_6N1FLHQRH0",
      "type": "Performance"
    },
    {
      "site": "websiteVCU",
      "name": "_gid",
      "type": "Performance"
    },
    {
      "site": "websiteVCU",
      "name": "__hstc",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_ga",
      "type": "Performance"
    },
    {
      "site": "websiteVCU",
      "name": "_gat_UA-65402835-1",
      "type": "Performance"
    },
    {
      "site": "websiteVCU",
      "name": "bcookie",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "lidc",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "bscookie",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_gcl_au",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "MUID",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_fbp",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "UserMatchHistory",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_uetsid",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_uetvid",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_pin_unauth",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "IDE",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "hubspotutk",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_tt_enable_cookie",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "_ttp",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "ln_or",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "li_sugr",
      "type": "Marketing"
    },
    {
      "site": "websiteVCU",
      "name": "barometric[cuid]",
      "type": "Marketing"
    },
    {
      "site": "websiteVTA",
      "name": "_ga",
      "type": "Performance"
    },
    {
      "site": "websiteVTA",
      "name": "_gat_UA-65402835-1",
      "type": "Performance"
    },
    {
      "site": "websiteVTA",
      "name": "_gid",
      "type": "Performance"
    },
    {
      "site": "websiteVTA",
      "name": "_gat_UA-72118450-1",
      "type": "Performance"
    },
    {
      "site": "websiteVTA",
      "name": "_ga_81Z3MKJ41C",
      "type": "Performance"
    },
    {
      "site": "websiteVTA",
      "name": "_ga_5VEFXBTYN1",
      "type": "Performance"
    },
    {
      "site": "websiteVCF",
      "name": "_ga_X24LV9Q7RV",
      "type": "Performance"
    },
    {
      "site": "websiteVCF",
      "name": "_ga",
      "type": "Performance"
    },
    {
      "site": "websiteVMA",
      "name": "_gat_UA-65402835-1",
      "type": "Performance"
    },
    {
      "site": "websiteVMA",
      "name": "_ga",
      "type": "Performance"
    },
    {
      "site": "websiteVMA",
      "name": "_gid",
      "type": "Performance"
    },
    {
      "site": "websiteVMA",
      "name": "_ga_CX3EZLJHD3",
      "type": "Performance"
    },
    {
      "site": "websiteVMA",
      "name": "YSC",
      "type": "Marketing"
    },
    {
      "site": "websiteVMA",
      "name": "VISITOR_INFO1_LIVE",
      "type": "Marketing"
    }
];

export function getCookieAuditList() {
  return cookies;
}