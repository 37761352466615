
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import CategoryCardList from '../../components/CategoryCardList/CategoryCardList';
import ProductDetailComplexPageModel from "../../../models/ProductDetailComplexPageModel";
import ProductStepwiseDetails from "../../components/ProductStepwiseDetails/ProductStepwiseDetails";
import CTABarProductDetail from "../../components/CTABarProductDetail/CTABarProductDetail";
import Disclosures from '../../components/Disclosures/Disclosures';

export default function ProductDetailComplexPage(props: ProductDetailComplexPageModel & IComponentProps){
    let {htmlId, className, category, hero, stepwise, callToActionBar, disclosures, categoryRelated} = props;

    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero}/>
            </section>
            <section className={"bg-blue"}>
                <CategoryCardList {...category}/>
            </section>
            <section>
                <ProductStepwiseDetails {...stepwise}/>
            </section>
            <section>
                <CategoryCardList {...categoryRelated}/>
            </section>
            <section>
                <CTABarProductDetail {...callToActionBar}/>
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
