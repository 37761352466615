import {useEffect, useState} from "react";
import ThemeTypeEnum from "../../enums/ThemeTypeEnum";
import Header from "./Header/Header";
import _templatePageConfig from "../../_templatePageConfig";
import Footer from "./_shared/Footer/Footer";
import PageTemplateEnum from "../../enums/PageTemplateEnum";
import HelmetHead from "../components/HelmetHead/HelmetHead";
import DynamicModal from "../components/_shared/Dialogs/DynamicModal";
import { usePageRequestQuery } from "../../redux/_shared/helpers/vystarApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import AccessRestrictedPage from "./AccessRestrictedPage/AccessRestrictedPage";
import { useLocation } from "react-router-dom";
import CookieBanner from "../components/CookieBanner/CookieBanner";
import getCookie from "../_shared/helpers/getCookie";
import { connect, useDispatch } from "react-redux";
import { closeModal, openModal } from "../../redux/slices/modalSlice";
import { toggleCookieBanner, updateNavigationTheme } from "../../redux/slices/pageContentSlice";
import envDataConfig from "../../EnvDataConfig/EnvDataConfig";
import { Track } from "@uniformdev/context-react";

function mapStateToProps(state: any): any {
    const { showCookieBanner, shouldPersonalize, navigationTheme } = state.pageContent;
    return { showCookieBanner, shouldPersonalize, navigationTheme };
  }

interface IContentPage {
    showCookieBanner?: boolean,
    shouldPersonalize?: boolean, 
    navigationTheme?: string, 
}

const websitesWithCookieBanner = [ "websiteVCF" , "websiteVCU" , "websiteVTA" ];

export function ContentPage({ showCookieBanner, shouldPersonalize, navigationTheme }: IContentPage) {
    const { pathname } = useLocation();

    const locationPath = pathname.startsWith('/') ? pathname.replace('/','') : pathname;

    let id: string = "unknown-id";
    let templateType: PageTemplateEnum | null = null;
	let unfrmOptEnrichmentTag: any = [];
    let title: string = "";
    let canonical: string = "";
    let description: string = "";
    let keywords: string[] = [];
    //let navigationTheme: ThemeTypeEnum | null;
    const pageApi = usePageRequestQuery(encodeURIComponent(locationPath.toLowerCase() || ""));
    let pageProps: any;
    let accessRestricted: boolean = false;

    if(pageApi.isSuccess && pageApi.status === "fulfilled"){
        pageProps = pageApi.data;
        templateType = pageProps.templateType;
        navigationTheme = pageProps.navigationTheme;
        title = pageProps.title;
        description = pageProps.description;
        keywords = pageProps.keywords;
        canonical = pageProps.canonical;
		unfrmOptEnrichmentTag = pageProps.enrichments;
        const slug = pageProps.content?.slug;
        if(templateType === PageTemplateEnum.BlogNewsEvents && !!slug){
            canonical += "/" + slug;
        }
    }
    const helmetHeadData = {
        title, 
        description, 
        keywords,
        canonical
    }
    const pageData = {
        ...pageProps,
        shouldPersonalize
    }
    if(pageApi.isError ){
        navigationTheme = ThemeTypeEnum.Light;
        const { status } = pageApi.error as FetchBaseQueryError;
        switch(status){
            case 403:
                accessRestricted = true;
                break;
            case 404:
                templateType = PageTemplateEnum.PageNotFound;
                break;
            default:
                templateType = PageTemplateEnum.SomethingWentWrong;
        }
    }
    let PageTemplate: any = templateType ? _templatePageConfig.get(templateType)?.component : undefined;
    let model: any = templateType ? _templatePageConfig.get(templateType)?.model : undefined;
    let body = PageTemplate ? <PageTemplate {...new model(pageData)}/> : undefined;
    const isHeaderFooterValid = templateType !== PageTemplateEnum.SomethingWentWrong;

    const [ isInitialLoad, setIsInitialLoad] = useState(true);
    const dispatch = useDispatch();

    if(templateType === "templateNewsEventsPage" && pageProps.content){
        navigationTheme = ThemeTypeEnum.Dark;
        dispatch(updateNavigationTheme(navigationTheme!))
    }

    if(templateType === "templateDynamicPage" && pageProps.navigationTheme) {
        navigationTheme = pageProps.navigationTheme;
        dispatch(updateNavigationTheme(navigationTheme!))
    }

    if(templateType === "templateRatesDetailPage" && pageProps.navigationTheme) {
        navigationTheme = pageProps.navigationTheme;
        dispatch(updateNavigationTheme(navigationTheme!))
    }

    if(templateType === "templateGeneric" && pageProps.navigationTheme) {
        navigationTheme = pageProps.navigationTheme;
        dispatch(updateNavigationTheme(navigationTheme!))
    }

    if(isInitialLoad){
        const isPerformanceCookieNotSet = getCookie("performance_storage") === null;
        const isMarketingCookieNotSet = getCookie("marketing_storage") === null;
        const isWebsiteWithCookieBanner = websitesWithCookieBanner.includes(envDataConfig.SiteTag);
        const shouldCookieBannerOpen = (isPerformanceCookieNotSet || isMarketingCookieNotSet) && isWebsiteWithCookieBanner
        if(shouldCookieBannerOpen){
            dispatch(toggleCookieBanner(true));
            dispatch(openModal({modalType: "Backdrop"}));
        }
        setIsInitialLoad(false);
    }

    useEffect(() => {
        window.scrollTo(0,0);
    },[ locationPath ]);

    return !accessRestricted ? <>
        <HelmetHead {...helmetHeadData} />
        {isHeaderFooterValid && <Header theme={navigationTheme}/>}
        <Track behavior={unfrmOptEnrichmentTag!} disableVisibilityTrigger={true}>
	        <main 
	            role="main" 
	            className={isHeaderFooterValid ? "main" : undefined} 
	
	            >
	            <div onClick={()=>{
	                if(showCookieBanner){
	                    dispatch(closeModal(null));
	                }
	            }} >
	                {body}
	            </div>
	            {showCookieBanner && <CookieBanner setShowCookieBanner={toggleCookieBanner} />}
	        </main>
 		</Track>
        {isHeaderFooterValid && <Footer />}
        <DynamicModal 
            className={"smartLinkModal"} 
        />  
    </>: <AccessRestrictedPage />;
}

export default connect(mapStateToProps, undefined)(ContentPage);