import { useDispatch } from "react-redux";
import { clickEvent } from "../../../models/SmartLinkModel";
import { openModal } from "../../../redux/slices/modalSlice";
import styling from "./AuthorList.module.scss";
import AuthorGroupModel from "../../../models/AuthorGroupModel";
import AuthorCardModel from "../../../models/AuthorCardModel";
import processBiography from "../../_shared/helpers/processBiography";

export default function AuthorList(props: AuthorGroupModel) {
    let { writtenBy = [], reviewedBy = [], editedBy = [] } = props;
    const hasAuthors = !!writtenBy?.length || reviewedBy?.length || editedBy?.length;
    const dispatch = useDispatch();
    let returnString = "";

    return hasAuthors ? ( <span className={`${styling.authorList}`}>
            {
                <div>
                    {
                        writtenBy.map((author: AuthorCardModel, i) => (
                            <a key={author.id} onClick={(e:clickEvent)=> {
                                e.preventDefault();
                                dispatch(openModal({
                                    modalType: "Bio", 
                                    dialogTitle: 'About the Writer',
                                    link: '',
                                    body: author?.biography ? processBiography(author?.biography!) : null,
                                    altText: author?.name,
                                    header: author?.name,
                                    subHeader: 'Writer', 
                                    image: { imageURL: author?.image?.imageURL}}));
                                }}>

                                {i === 0 ? returnString = "Written by: " : ""}
                                {i > 0 ? ", " : ""}
                                <span key={i} className={styling.authorLink}>
                                    {author?.name}
                                </span>
                            </a>                           
                        ))
                    }

                    {
                        reviewedBy.map((author: AuthorCardModel, i: number) => (
                            <a key={author.id} onClick={(e:clickEvent)=> {
                                e.preventDefault();
                                dispatch(openModal({
                                    modalType: "Bio", 
                                    dialogTitle: 'About the Reviewer',
                                    link: '',
                                    body: author?.biography ? processBiography(author?.biography!) : null,
                                    altText: author?.name,
                                    header: author?.name,
                                    subHeader: 'Reviewer', 
                                    image: { imageURL: author?.image?.imageURL}}));
                                }}>
                                    {i === 0 ? returnString = " Reviewed by: " : ""}
                                    {i > 0 ? ", " : ""}
                                    <span key={author?.id} className={styling.authorLink}>
                                        {author?.name}
                                    </span>
                            </a>
                        ))
                    }

                    {
                        editedBy.map((author: AuthorCardModel, i: number) => (
                            <a key={author.id} onClick={(e:clickEvent)=> {
                                e.preventDefault();
                                dispatch(openModal({
                                    modalType: "Bio", 
                                    dialogTitle: 'About the Editor',
                                    link: '',
                                    body: author?.biography ? processBiography(author?.biography!) : null,
                                    altText: author?.name,
                                    header: author?.name,
                                    subHeader: 'Editor', 
                                    image: { imageURL: author?.image?.imageURL}}));
                                }}>
                                    {i === 0 ? returnString = " Edited by: " : ""}
                                    {i > 0 ? ", " : ""}
                                    <span className={styling.authorLink}>
                                        {author?.name}
                                    </span>
                            </a>
                        ))
                    }
                </div>
            }
        </span> ) : <></>
}