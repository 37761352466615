import IClientServerModel from "../interfaces/IClientServerModel";
import {Document} from "@contentful/rich-text-types";
import ImageModel from "./ImageModel";

export default class FeatureModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly backgroundImage?: ImageModel | null = undefined
    public readonly id: string | undefined;
    public readonly label: string | undefined;
    public readonly richTextFeature1: Document | null | undefined;
    public readonly richTextFeature2: Document | null | undefined;
    constructor(contentfulFeatureModel: Readonly<FeatureModel> | undefined | null){
        const {htmlId = "", id, label, richTextFeature1, richTextFeature2, backgroundImage} = contentfulFeatureModel || {}
        this.htmlId = htmlId;
        this.backgroundImage = backgroundImage ? new ImageModel(backgroundImage) : null;
        this.id = id;
        this.label = label; 
        this.richTextFeature1 = richTextFeature1; 
        this.richTextFeature2 = richTextFeature2; 
    }
}

