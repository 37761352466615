import {
    connectMenu,
} from "react-instantsearch-dom";

interface IItem{
    count: number;
    isRefined: boolean;
    label: string;
    value: string
}

interface ISearchMenu {
    items: Array<IItem>;
    refine: Function;
    attribute?: string;
}
// Moves refined (selected) value to top and then alphabetizes menu list
const sortItems = (items: IItem[]) => [...items].sort((a:any, b:any) => (a.isRefined || a.value < b.value) ? -1 : 1);

const SearchMenu = ({ items, refine, attribute }: ISearchMenu) => {

    return (
        <div className="ais-Menu">
            <ul className="ais-Menu-list">
                {sortItems(items).map((item:any) => {
                    return (
                        <li 
                            key={item.value}
                            data-insights-filter={`${attribute}: ${item.value || item.label}`}
                        >
                            <a
                                href="#"
                                style={{ fontWeight: item.isRefined ? 'bold' : undefined }}
                                onClick={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}
                            >
                                {item.label} ({item.count})
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
};
export default connectMenu(SearchMenu);