import FooterModel from "../../../../models/FooterModel";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import "./Footer.module.scss";
import FooterSupport from "./FooterSupport";
import NavigationItems from "./NavigationItems";
import SubFooter from "./SubFooter";
import { useFooterRequestQuery } from "../../../../redux/_shared/helpers/vystarApi";

export function Footer() {
    const footerAPI = useFooterRequestQuery(null);
    const hasData = footerAPI.isSuccess && footerAPI.status === "fulfilled";
    if(hasData){
        const {id, navigationItems, footerSupportContent, subFooter} = new FooterModel(footerAPI.data[0]);
        return (<footer className="g-footer" role="contentinfo" data-contentid={id}>
                <div id="upper-footer">
                    <div className="container">
                        <NavigationItems navigationItems={navigationItems} />
                        <FooterSupport {...footerSupportContent} />
                    </div>
                </div>
                <SubFooter {...subFooter} />
            </footer>);
    }
    return <></>;
}
Footer.displayName = "Footer";
export default withErrorBoundary(Footer);