import IClientServerModel from "../interfaces/IClientServerModel";

// Video hosting companies use a special url for embedded videos, this function replaces
// share and play urls with their embedded counterparts, plus a privacy param

const replaceShareURL = (url: string) => {
        // VIMEO CASE - PLAY URL
    if(url.includes('vimeo')){
        url = url.replace(/vimeo\.com/gi, 'player.vimeo.com/video');
        url += '?dnt=1';
    }else{
        // DEFAULT IS YOUTUBE, SHARE AND PLAY URLS
        url = url.replace('youtu.be', 'www.youtube-nocookie.com/embed/');
        url = url.replace('youtube.com/watch?v=', 'youtube-nocookie.com/embed/');
        url += '?rel=0';
    }
    return url;
};


export default class VideoPlayerModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly caption?: string = "";
    public readonly videoLink?: string = "";
    public readonly $id?: string;
    constructor(props:Readonly<VideoPlayerModel> | undefined | null = undefined) {
        const {htmlId = "", caption = "", videoLink = "", $id} = props ?? {};
        this.htmlId = htmlId;
        this.caption = caption;
        this.videoLink = replaceShareURL(videoLink);
        this.$id = $id;
    }
}