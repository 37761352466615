
function isJSON(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

let ParseContentID = (id: string | undefined) => {
    if(id !== undefined) {
        if(isJSON(id)) {
            let parsedID = JSON.parse(id);
            return parsedID.original;
        }
        return id;
    }
    
    return id;
}

export default ParseContentID;