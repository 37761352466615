enum PageTemplateEnum{
    // @ts-ignore
    Home = "templateHome",
    // @ts-ignore
    ProductDetailSimple = "templateProductDetailSimple",
    // @ts-ignore
    ProductDetailComplex = "templateProductDetailComplex",
    // @ts-ignore
    CampaignLanding = "templateCampaignLanding",
    // @ts-ignore
    Category = "templateCategory",
    // @ts-ignore
    Compare = "templateCompare",
    // @ts-ignore
    Landing = "templateLanding",
    // @ts-ignore
    Blog = "templateBlog",
    // @ts-ignore
    Biography = "templateBioLanding",
    // @ts-ignore
    RatesDetail = "templateRatesDetailPage",
    // @ts-ignore
    Generic = "templateGeneric",
    // @ts-ignore
    PageNotFound = "templatePageNotFound",
    // @ts-ignore
    ContentLoading = "templateContentLoading",
    // @ts-ignore
    SomethingWentWrong = "SomethingWentWrong",
    // @ts-ignore
    Locations = "templateLocationsPage",
    // @ts-ignore
    SearchResults = "templateSearchResults",
    // @ts-ignore
    BlogNewsEvents = "templateNewsEventsPage"
    // @ts-ignore
    ,Dynamic = "templateDynamicPage"
    // @ts-ignore
    ,Contact = "templateContact"
    // @ts-ignore
    ,AccessRestricted = "accessRestrictedPage"
}

export default PageTemplateEnum;