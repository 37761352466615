import { Document } from "@contentful/rich-text-types";
import ImageModel from "../../../../models/ImageModel";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import SmartLink from "../SmartLink/SmartLink";

interface IModalBody {
    body: Document | null | undefined;
    header: JSX.Element | string | null | undefined;
    altText: string | undefined | null;
    image?: ImageModel | undefined | null;
    subHeader?: string | undefined | null;
    link?: SmartLinkModel | undefined | null;
}

export default function BioModalBody({
    body,
    image,
    altText,
    header,
    subHeader,
    link
}: IModalBody) {

    return (
        <div className="bio-modal-content">
            <div className="bio-photo">
                <img
                    src={!!image?.imageURL ? image.imageURL : undefined}
                    alt={altText ?? ""}
                />
            </div>
            <div className="bio-full">
                <div className="bio-name">{header}</div>
                <div className="bio-title">{subHeader}</div>
                {body &&
                    documentToReactComponents(
                        body,
                        ContentfulReactRenderOptions
                    )}
                    <p>
                        <SmartLink {...link}/>
                    </p>
            </div>
        </div>
    );
}
