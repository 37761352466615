
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import CTABarProductDetail from "../../components/CTABarProductDetail/CTABarProductDetail";
import CampaignLandingPageModel from "../../../models/CampaignLandingPageModel";
import Disclosures from '../../components/Disclosures/Disclosures';

export default function CampaignLandingPage(props: CampaignLandingPageModel & IComponentProps){
    let {htmlId, className, hero, callToActionBar, secondaryPromos, disclosures} = props;
    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero}/>
            </section>
            {secondaryPromos?.map((sp,i)=>{
                return <section key={i}><Promotional {...sp} /></section>
            })}
            <section>
                <CTABarProductDetail {...callToActionBar}/>
            </section>
            <section>
              <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
