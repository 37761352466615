import React, {Fragment} from "react";
import IComponentProps from "../../../interfaces/IComponentProps";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import PromotionModel from "../../../models/PromotionModel";
import PromotionTypesEnum from "../../../enums/PromotionTypesEnum";
import PromoPrimary from "./PromoPrimary/PromoPrimary";
import PromoHero from "./PromoHero/PromoHero";
import PromoHeroSmall from "./PromoHeroSmall/PromoHeroSmall";
import PromoSecondary from "./PromoSecondary/PromoSecondary";
import PromoTwoThirds from "./PromoTwoThirds/PromoTwoThirds";
import PromoShort from "./PromoShort/PromoShort";
import PromoDesktopMenu from "./PromoDesktopMenu/PromoDesktopMenu";
import PromoMobileMenu from "./PromoMobileMenu/PromoMobileMenu";

export function PromotionRender(props: PromotionModel & IComponentProps)  {
    let {type} = props;
    let PromoComponent = null;
    switch(type){
        case PromotionTypesEnum.Default:
            PromoComponent = <PromoPrimary {...props}/>;
            break;
        case PromotionTypesEnum.Hero:
            PromoComponent = <PromoHero {...props}/>;
            break;
        case PromotionTypesEnum.HeroSmall:
            PromoComponent = <PromoHeroSmall {...props}/>;
            break;
        case PromotionTypesEnum.PromoSecondary:
            PromoComponent = <PromoSecondary {...props}/>
            break;
        case PromotionTypesEnum.PromoTwoThirds:
            PromoComponent = <PromoTwoThirds {...props}/>;
            break;
        case PromotionTypesEnum.PromoShort:
            PromoComponent = <PromoShort {...props}/>;
            break;
        case PromotionTypesEnum.PromoDesktopMenu:
            PromoComponent = <PromoDesktopMenu {...props}/>;
            break;
        case PromotionTypesEnum.PromoMobileMenu:
            PromoComponent = <PromoMobileMenu {...props}/>;
            break;
        default:
            PromoComponent = <PromoPrimary {...props}/>;
            break;
    }
    return <Fragment>
        {PromoComponent}
    </Fragment>;
}

PromotionRender.displayName = "Promotion";
export default withErrorBoundary(PromotionRender);