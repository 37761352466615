import { Personalize } from "@uniformdev/context-react";
import CategoryCardListPersonalized from "../Personalization/CategoryCardListPersonalized";
import { MapPersonalizationArray } from "../../_shared/helpers/MapPersonalizationArray";

//@ts-ignore
export const PersonalizedCategoryCards = ({variatians}) => {
  // This code block handles the data for the Custom Plugin for 
  // Google Analytics for the Category Cards
  const variations = (variatians as any[]).map(entry => {
    if(entry.pz) {
      entry.criteria = MapPersonalizationArray(entry.pz.crit);
    }

    entry.id = JSON.stringify({
      original: entry.id,
      entry: {
        contentType: entry.htmlId,
        entryId: entry.id,
        name: entry.header,
      },
      criteria: entry.criteria,
    });

    return entry;
  })

  // This code block handles the personalization for Category Cards
  return (
    <Personalize
      variations={variations}
      name="Personalized Category Cards"
      component={CategoryCardListPersonalized}
    />
  );
}