export function waitForIsValid(isValid:Function, interval: number = 100, maxAttempts= 30){
    return new Promise((complete:Function, fail:Function)=>{
        let attempts = 0;
        let intervalId = setInterval(()=>{
            attempts++;
            if(isValid()){
                clearInterval(intervalId);
                complete();
            }

            if(attempts >= maxAttempts){
                fail();
            }
        }, interval)
    })

}

export function waitForTimeoutAsync(timeout: number){
    return new Promise(function(resolve) {
        setTimeout(()=>{
            resolve(undefined);
        }, timeout);
    });
}

export async function waitForElementByClassNameAsync(className: string, timeout = 100, maxCount = 20) {
    let count = 0;
    let element: HTMLElement | never[] = await new Promise(function(resolve, reject) {
        (function waitForInternal() {
            count++;
            let elements = window.document.getElementsByClassName(className) || [];
            if (elements.length > 0) {
                let element = elements[0] as HTMLElement | never[];
                return resolve(element);
            }
            else if (count >= maxCount){
                reject();
            }
            else{
                setTimeout(waitForInternal, timeout);
            }
        })();
    });
    return element as HTMLElement | never[];
}

export async function waitForDomChangeAsync(isComplete: (()=>boolean), targetQuerySelector: string = 'body', options: MutationObserverInit = {childList:true}){
    return new Promise((resolve)=> {
        const target = document.querySelector(targetQuerySelector);
        const observer = new MutationObserver(function(mutations) {
            if (isComplete && isComplete()) {
                observer.disconnect()
                resolve(mutations)
            }
        });
        if(target){
            observer.observe(target, options);
        }
        else{
            throw new Error("target does not exists")
        }
    });
}

export async function waitForElementByIdAsync(id: string, timeout = 100, maxCount = 20) {
    let count = 0;
    let element: HTMLElement | never[] = await new Promise(function(resolve, reject) {
        (function waitForInternal() {
            count++;
            let element:HTMLElement | never[] = window.document.getElementById(id) || [];
            if (!!element) {
                return resolve(element);
            }
            else if (count >= maxCount){
                reject();
            }
            else{
                setTimeout(waitForInternal, timeout);
            }
        })();
    });
    return element as HTMLElement | never[];
}

const wait = {
    forElementByClassName: waitForElementByClassNameAsync,
    forElementById: waitForElementByIdAsync,
    forDomChange: waitForDomChangeAsync,
    forTimeout: waitForTimeoutAsync,
    forIsValid: waitForIsValid
};
export default wait;