import { useState } from "react";
import setCookie from "../../../_shared/helpers/setCookie";
import styles from "./ModalDialog.module.scss";
import { REACT_APP_SITE_TAG } from "../../../../EnvDataConfig/_envSettings";
import getCookie from "../../../_shared/helpers/getCookie";
import eraseCookie from "../../../_shared/helpers/eraseCookie";
import { getCookieAuditList } from "../../../_shared/helpers/CookieAuditList";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../redux/slices/modalSlice";
import { toggleCookieBanner, togglePersonalize } from "../../../../redux/slices/pageContentSlice";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";

const siteTag = REACT_APP_SITE_TAG;

export interface CookieListItem {
    site: string,
    name: string,
    type: string
}

export const submitCookieForm = ( performanceStorage: boolean, marketingStorage: boolean, dispatch: Dispatch<UnknownAction> ) => {
    const CookieAuditList = getCookieAuditList();
    const performanceStorageValue = performanceStorage ? "granted" : "denied";
    setCookie("performance_storage", performanceStorageValue, 2000);
    const marketingStorageValue = marketingStorage ? "granted" : "denied";
    setCookie("marketing_storage", marketingStorageValue, 2000);
    dispatch(togglePersonalize(marketingStorage));

    if(!performanceStorage && !marketingStorage) {
        CookieAuditList.forEach((cookie: CookieListItem) => {
            if(cookie.site === siteTag) {
                eraseCookie(cookie.name);
            }
        });
    }

    else if(!performanceStorage) {
        CookieAuditList.forEach((cookie: CookieListItem) => {
            if(cookie.type === "Performance" && cookie.site === siteTag) {
                eraseCookie(cookie.name);
            }
        });
    }

    else if(!marketingStorage) {
        CookieAuditList.forEach((cookie: CookieListItem) => {
            if(cookie.type === "Marketing" && cookie.site === siteTag) {
                eraseCookie(cookie.name);
            }
        });
    }

}

export default function CookiesBody() {

    const [ performanceStorage, setPerformanceStorage] = useState(false);
    const [ marketingStorage, setMarketingStorage] = useState(false);
    const [ isInitialLoad, setIsInitialLoad] = useState(true);
    

    const dispatch = useDispatch();
    // const uniformContext = useUniformContext();

    if(isInitialLoad){
        const initialPerformanceValue = getCookie("performance_storage") === "granted" ? true : false;
        const initialMarketingValue = getCookie("marketing_storage") === "granted" ? true : false;
        setPerformanceStorage(initialPerformanceValue);
        setMarketingStorage(initialMarketingValue);
        setIsInitialLoad(false);
    }

    return (
        <div className={styles["modal-content"]}>
            <p>Your privacy is important to us.</p>
            <p>Select which cookies you'll allow this site to use. These include Metapixel and heatmapping as outlined in our Privacy Policy.</p>
            <p>Data collected may be shared with third parties (social media, analytics, and advertising).</p>
            <h3>Strictly Necessary Cookies</h3>
            <p>These cookies are necessary to ensure the site works reliably and to use basic features like chat.</p>
            <form action="">
                <div className="form-group">
                    <div className="form-group-title">Performance Cookies</div>
                    <div className="form-group-desc">
                        <p>These cookies collect information about your site use to improve website functions and browsing experience.</p>
                    </div>
                    <div className="check-switch">
                        <label className="switch" htmlFor="performance">
                            <input type="checkbox" id="performance" checked={performanceStorage} onChange={(e:any)=>{
                                setPerformanceStorage(e.target.checked);
                            }}/>
                            <div className="slider round"></div>
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group-title">Marketing Cookies</div>
                    <div className="form-group-desc">
                        <p>These are typically third-party cookies that observe browsing activities to deliver more personalized content and targeted ads.</p>
                    </div>
                    <div className="check-switch">
                        <label className="switch" htmlFor="marketing">
                            <input type="checkbox" id="marketing" checked={marketingStorage} onChange={(e:any)=>{
                                setMarketingStorage(e.target.checked);
                            }}/>    
                            <div className="slider round"></div>
                        </label>
                    </div>
                </div>
                <div className="form-group-btn">
                    <button className="smartlink smartlink_button smartlink_buttonprimary" onClick={(e:any)=>{
                        e.preventDefault();
                        submitCookieForm(performanceStorage,marketingStorage, dispatch);
                        dispatch(closeModal(null));
                        dispatch(toggleCookieBanner(false));
                    }
                    }>Save Preferences</button>
                </div>
            </form>
        </div>
    );
}
