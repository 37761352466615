import { waitForDomChangeAsync } from "../../_shared/wait/wait";

export default async function loadPoshAsync(){
    require('./poshInit');
    return waitForDomChangeAsync(()=> {
        let poshWidgetContainer = document.querySelector('.posh-widget-container') as HTMLElement;
        let poshWidgetButton = document.querySelector('#posh-widget-button') as HTMLElement;
        let isComplete = !!poshWidgetContainer && !!poshWidgetButton;
        return isComplete;
    },'body', {childList: true})
}