import React, { Fragment } from "react";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import IComponentProps from "../../../../interfaces/IComponentProps";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import SmartLink from "../SmartLink/SmartLink";

interface ILinkButtonListViewModel extends IComponentProps{
    links: SmartLinkModel[] | undefined | null,
    buttonClassName?: string | undefined | null
}

function LinkButtonList(props: ILinkButtonListViewModel)  {
    let {links, buttonClassName} = props;
    return <Fragment>
        {links?.map((link, index) => {
            let buttonType = (index === 0) ? LinkTypeEnum.PrimaryButton : LinkTypeEnum.SecondaryButton;
            return <SmartLink {...{...link, type:buttonType}}
              key={index} className={`${buttonClassName}`}
            />
        })}
    </Fragment>
}
export default LinkButtonList;