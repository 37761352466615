export default class BlogNewsEventModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly contents?: string[];    
    public readonly bneItems?: any;
    public readonly blogNewsEventsContainer?: any;
    public readonly defaultTab?: string;

    constructor(props: Readonly<BlogNewsEventModel> | undefined | null){
        const {contents = [], bneItems = [], defaultTab} = props || {};
        this.contents = contents;
        this.bneItems = bneItems;
        this.defaultTab = defaultTab;
    }
}