import IClientServerModel from "../interfaces/IClientServerModel";
import DisclosureItemModel from "./DisclosureItemModel";
import RateGroupModel from "./RateGroupModel";
import { Document } from "@contentful/rich-text-types";
import SmartLinkModel from "./SmartLinkModel";

export default class RateFilterModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public name?: string;
    public readonly description?: Document | null | undefined;
    public ratesGroup: RateGroupModel[];
    public readonly mainCallToAction?: SmartLinkModel | undefined;
    public readonly bottomCallsToAction?: SmartLinkModel[] | undefined;
    public readonly disclosures?: DisclosureItemModel[] | undefined;
    constructor(props:RateFilterModel | null){
        const {
            htmlId = "",
            id,
            name = "",
            description,
            ratesGroup = [],
            mainCallToAction,
            bottomCallsToAction = [],
            disclosures = []
        } = props || {};

        this.htmlId = htmlId;
        this.id = id;
        this.name = name;
        this.description = description;
        this.ratesGroup = ratesGroup?.map(ratesDetailItemModel => {
            return new RateGroupModel(ratesDetailItemModel);
        }) || [];
        this.mainCallToAction = mainCallToAction;
        this.bottomCallsToAction = bottomCallsToAction;
        this.disclosures = disclosures?.map((disclosure: DisclosureItemModel)=>new DisclosureItemModel(disclosure));
    }   
}


