import ImageModel from "./ImageModel";
import SmartLinkModel from "./SmartLinkModel";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import {Document} from "@contentful/rich-text-types";
import IThemedDisplay from "../interfaces/IThemedDisplay";
import ISideAlignedDisplay from "../interfaces/ISideAlignedDisplay";
import SideAlignmentEnum from "../enums/SideAlignmentEnum";
import IClientServerModel from "../interfaces/IClientServerModel";
import PromotionTypesEnum from "../enums/PromotionTypesEnum";

export interface IPromotionContent {
    readonly secondaryHeader?: string | undefined;
    readonly header?: string | undefined;
    readonly links?: SmartLinkModel[] | null | undefined;
    readonly body?: Document | null | undefined;
    readonly image?: ImageModel | null | undefined;
    readonly mobileImage?: ImageModel | null | undefined;
}

enum PrimaryTypeEnum{
    // @ts-ignore
    True = "True",
    // @ts-ignore
    False = "False"
}


export default class PromotionModel implements IClientServerModel, IThemedDisplay, ISideAlignedDisplay{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string | undefined = "";
    public readonly htmlId?: string | undefined = "";
    public readonly pz?: string | undefined = "";
    public readonly theme: ThemeTypeEnum | undefined = ThemeTypeEnum.Default;
    public readonly textAlignment: SideAlignmentEnum | undefined;
    public readonly content: IPromotionContent = {};
    public readonly type?: PromotionTypesEnum = PromotionTypesEnum.Default;
    public readonly primary?: PrimaryTypeEnum = PrimaryTypeEnum.True;

    constructor(props:PromotionModel | null) {
        this.id = props?.id;
        this.htmlId = props?.htmlId;
        this.pz = props?.pz;
        this.textAlignment = props?.textAlignment;
        this.theme = props?.theme;
        this.type = props?.type;
        this.primary = props?.primary;
        this.content = {
            image: props?.content?.image ? new ImageModel(props.content.image) : undefined,
            mobileImage: props?.content?.mobileImage ? new ImageModel(props.content.mobileImage) : undefined,
            header: props?.content?.header,
            secondaryHeader: props?.content?.secondaryHeader,
            links: props?.content?.links,
            body: props?.content?.body,
        }
    }
}
