import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import SitemapModel from "../../../models/SitemapModel";
import SmartLink from "../_shared/SmartLink/SmartLink";
import SmartLinkModel from "../../../models/SmartLinkModel";
import styling from './Sitemap.module.scss';
import SitemapItemModel from "../../../models/SitemapItemModel";

export function Sitemap({ id, links, contentTitle }: SitemapModel & IComponentProps) {

    const hasData = !!links?.length

    return hasData ? (
        <div className={`container ${styling["sitemap-container"]}`} data-contentid={id}>
            <div className="row">
                <div className="col-12">
                    <h1>{contentTitle}</h1>
                </div>
            </div>
            <div className={`${styling["sitemap"]}`}>
                {links?.map((sitemapItems:SitemapItemModel[], i:number) => {

                const needsHeader = sitemapItems[0].sitemapCategory !== sitemapItems[0].sitemapName && sitemapItems[0].sitemapCategory !== "Uncategorized";

                return (<div key={i}>
                            { needsHeader && <h6>{sitemapItems[0].sitemapCategory}</h6> }
                            <div>{sitemapItems.map((link:SitemapItemModel, key: number)=>{
                                const linkData = new SmartLinkModel({
                                    url: link.url,
                                    defaultLinkText: link.sitemapName
                                });
                                const className = link.sitemapName === link.sitemapCategory ? "fw-bold" : "";
                                return link.sitemapName ?(
                                    <div className="row mx-auto" key={key}>
                                        <div className="col-12">
                                            <div className="m-1">
                                                <SmartLink {...linkData} className={className} />
                                            </div>
                                        </div>
                                    </div>
                                ):<></>;
                            })}</div>
                        </div>);
                })}
            </div>
        </div>
    ):<></>;
}
export default withErrorBoundary(Sitemap);
