import styles from "./Quote.module.scss";

import IComponentProps from "../../../../interfaces/IComponentProps";

export default function Quote(props: { byLine: string; quote: string; showQuotes: Boolean } & IComponentProps){
    const {byLine="", quote="", className="", showQuotes=false} = props || {};
    return (<div className={`${styles.root} ${className}`}>
        <p className={`${showQuotes ? styles.quoted : ''}`}>{quote}</p>
        <cite>{byLine}</cite>
    </div>);
};
