import envDataConfig from "../../../EnvDataConfig/EnvDataConfig";
import CryptoJS from 'crypto-js';

function Encrypt(value: any) {
    var key = CryptoJS.enc.Utf8.parse(envDataConfig.SendGridClientKey);
    var iv = CryptoJS.enc.Utf8.parse("7061737323313233");
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return encrypted.toString();
  }

  export default Encrypt