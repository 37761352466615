enum TallTabTypeEnum{
    // @ts-ignore
    SplitImage = "Split image",
    // @ts-ignore
    SplitLink = "Split link",
    // @ts-ignore
    Invalid = "Invalid",
}

export default TallTabTypeEnum;
