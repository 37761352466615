export interface DinkyTownCalculator {
    CalculatorName: string,
    CalculatorCode: string
}

const DinkyTownCalculators: DinkyTownCalculator[] = [
    {
        "CalculatorName": "Home Equity Loan vs. Auto loan Calculator",
        "CalculatorCode": "AutoEquityLoan"
    },
    {
        "CalculatorName": "Car Loan Calculator",
        "CalculatorCode": "AutoLoan"
    },
    {
        "CalculatorName": "Low Interest Financing Savings Calculator",
        "CalculatorCode": "AutoLowInterest"
    },
    {
        "CalculatorName": "Credit Card Balance Transfer Comparison",
        "CalculatorCode": "BalanceTransferCompare"
    },
    {
        "CalculatorName": "Biweekly Mortgage Calculator",
        "CalculatorCode": "Biweekly"
    },
    {
        "CalculatorName": "Equipment Buy vs. Lease Calculator",
        "CalculatorCode": "BizBuyvsLease"
    },
    {
        "CalculatorName": "Business Debt Consolidation Calculator",
        "CalculatorCode": "BizConsolidate"
    },
    {
        "CalculatorName": "Breakeven Analysis Calculator",
        "CalculatorCode": "BreakEven"
    },
    {
        "CalculatorName": "Business Valuation - Discounted Cash Flow Calculator",
        "CalculatorCode": "BusinessValuation"
    },
    {
        "CalculatorName": "Working Capital Needs Calculator",
        "CalculatorCode": "Capital"
    },
    {
        "CalculatorName": "Cash Flow Calculator",
        "CalculatorCode": "CashFlow"
    },
    {
        "CalculatorName": "Alternative Payment Frequencies",
        "CalculatorCode": "ComplexLoan"
    },
    {
        "CalculatorName": "Compound Savings Calculator",
        "CalculatorCode": "CompoundSavings"
    },
    {
        "CalculatorName": "Cool Million Calculator",
        "CalculatorCode": "Millionaire"
    },
    {
        "CalculatorName": "Dealer Financing vs. Credit Union Financing Calculator",
        "CalculatorCode": "CUAutoRebate"
    },
    {
        "CalculatorName": "Credit Union Certificate Calculator",
        "CalculatorCode": "CUDeposit"
    },
    {
        "CalculatorName": "Debt Consolidation Calculator",
        "CalculatorCode": "Consolidate"
    },
    {
        "CalculatorName": "Emergency Savings Calculator",
        "CalculatorCode": "Emergency"
    },
    {
        "CalculatorName": "Inventory Analysis Calculator",
        "CalculatorCode": "Inventory"
    },
    {
        "CalculatorName": "Lease vs. Buy Calculator",
        "CalculatorCode": "BuyvsLease"
    },
    {
        "CalculatorName": "Amortizing Loan Calculator",
        "CalculatorCode": "SimpleLoan"
    },
    {
        "CalculatorName": "Mortgage Calculator",
        "CalculatorCode": "MortgageLoan"
    },
    {
        "CalculatorName": "Mortgage Payoff Calculator",
        "CalculatorCode": "MortgagePayoff"
    },
    {
        "CalculatorName": "Mortgage Points Calculator",
        "CalculatorCode": "MortgagePoints"
    },
    {
        "CalculatorName": "Mortgage Qualifier Calculator",
        "CalculatorCode": "MortgageQualifier"
    },
    {
        "CalculatorName": "Mortgage Refinance Calculator",
        "CalculatorCode": "MortgageRefinance"
    },
    {
        "CalculatorName": "Credit Card Pay Off Calculator",
        "CalculatorCode": "PayoffCC"
    },
    {
        "CalculatorName": "Profit Margin Calculator",
        "CalculatorCode": "ProfitMargin"
    },
    {
        "CalculatorName": "Financial Ratios Calculator",
        "CalculatorCode": "Ratios"
    },
    {
        "CalculatorName": "Required Minimum Distribution (RMD)",
        "CalculatorCode": "RetireDistrib"
    },
    {
        "CalculatorName": "How long will my retirement savings last?",
        "CalculatorCode": "RetirementDistribution"
    },
    {
        "CalculatorName": "Retirement Nest Egg Calculator",
        "CalculatorCode": "RetirementNestegg"
    },
    {
        "CalculatorName": "Retirement Planner Calculator",
        "CalculatorCode": "RetirementPlan"
    },
    {
        "CalculatorName": "Roth IRA vs. Traditional IRA Calculator",
        "CalculatorCode": "RothvsRegular"
    },
    {
        "CalculatorName": "Savings Goal Calculator",
        "CalculatorCode": "Savings"
    },
    {
        "CalculatorName": "Auto Refinance Interest Savings Calculator",
        "CalculatorCode": "ShouldIRefiAuto"
    },
    {
        "CalculatorName": "Benefit of Spending Less Calculator",
        "CalculatorCode": "SpendLess"
    },
    {
        "CalculatorName": "Motorcycle, RV and Boat Loan Calculator",
        "CalculatorCode": "VehicleLoan"
    }
]

export function getDinkyTownCalculatorList() {
    return DinkyTownCalculators;
}

export function isDinkyTownCalc(CalcCode: string | undefined) 
{
    if(CalcCode === undefined || CalcCode === '') return false;
    return DinkyTownCalculators.some((x) => x.CalculatorCode === CalcCode); 
}