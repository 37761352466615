
import ImageModel from "../../../../models/ImageModel";
import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import LinkButtonList from "../../_shared/LinkButtonList/LinkButtonList";
import PromotionModel from "../../../../models/PromotionModel"
import CalculateNavigationTheme from "../../../_shared/helpers/CalculateNavigationTheme";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateNavigationTheme } from "../../../../redux/slices/pageContentSlice";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";

export function PromoDesktopMenu(props: PromotionModel & IComponentProps)  {
    const { id, theme, primary, content = {}} = props;
    const { header = "", secondaryHeader = "", links = [],
        image = new ImageModel(null)/*, body*/
    } = content || {};

    const {
        imageURL: backgroundImageURL,
        description: backgroundDescription
    } = image || new ImageModel(null);

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }
    
    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    return (<div aria-label={backgroundDescription} className="menu_column_promo" style={{backgroundImage:`url(${backgroundImageURL})`}} data-contentid={id}>
        <div>
            <div className="menu-promo-content">
                {secondaryHeader && <div className="eyebrow">{secondaryHeader}</div>}
                {header && <p>{header}</p>}
                <LinkButtonList links={links} buttonClassName={"btn-menu"}/>
            </div>
        </div>
    </div>);
}
PromoDesktopMenu.displayName = "PromoDesktopMenu";
export default withErrorBoundary(PromoDesktopMenu);