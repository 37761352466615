import SmartLinkModel from "./SmartLinkModel";

export default class NavigationItemsModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string | undefined = "";
    public readonly label: string | undefined = "";
    public readonly links: Array<SmartLinkModel>;
    constructor(contentfulQuestionAnswerModel: Readonly<NavigationItemsModel> | undefined | null){
        const {id = "", label = "", links = []} = contentfulQuestionAnswerModel || {}
        this.id = id;
        this.label = label;
        this.links = links?.map((contentfulLink: any) => new SmartLinkModel(contentfulLink));
    }
}

