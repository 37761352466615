import React, { useEffect } from "react";
import ImageModel from "../../../../models/ImageModel";
import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import PromotionModel from "../../../../models/PromotionModel"
import LinkButtonList from "../../_shared/LinkButtonList/LinkButtonList";
import CalculateNavigationTheme from "../../../_shared/helpers/CalculateNavigationTheme";
import { useDispatch } from "react-redux";
import { updateNavigationTheme } from "../../../../redux/slices/pageContentSlice";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";

export function PromoMobileMenu(props: PromotionModel & IComponentProps)  {
    const { id, theme, primary, content = {}} = props;
    const { header = "", secondaryHeader = "", links = [],
        image = new ImageModel(null)/*, body*/
    } = content || {};

    const {
        imageURL: backgroundImageURL
    } = image || new ImageModel(null);

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    return (<div className="promo" style={{backgroundImage:`url(${backgroundImageURL})`}} data-contentid={id}>
        {secondaryHeader && <div className="eyebrow">{secondaryHeader}</div>}
        {header && <p>{header}</p>}
        <LinkButtonList links={links} buttonClassName={"btn-menu"}/>
    </div>);
}

PromoMobileMenu.displayName = "PromoMobileMenu";
export default withErrorBoundary(PromoMobileMenu);