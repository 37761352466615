import IClientServerModel from "../interfaces/IClientServerModel";
import BrowserSupport from "../BrowserSupport/BrowserSupport";
import BrowserSupportFeatureEnum from "../enums/BrowserSupportFeatureEnum";

export default class ImageModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public readonly title?: string = "";
    public readonly description?: string = "";
    public readonly imageURL?: string | undefined;
    constructor(contentfulImageModel:Readonly<ImageModel> | undefined | null = undefined) {
        const {htmlId = "", title = "", id, description = "", imageURL} = (contentfulImageModel || {});
        this.htmlId = htmlId;
        this.id = id;
        this.title = title;
        this.description = description;
        let isSVG = imageURL?.includes('.svg');
        if(!isSVG && BrowserSupport.isSupported(BrowserSupportFeatureEnum.webp)){
            this.imageURL = imageURL + "?fm=webp&q=80";
        }else {
            this.imageURL = imageURL;
        }
    }
}



