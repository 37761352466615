import { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import SmartLinkModel from "./SmartLinkModel";
import ContentBlockModel from "./ContentBlockModel";

export default class AccessRestrictedPageModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly templateType: PageTemplateEnum | null;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly pageTitle: string;
    public readonly logo: SmartLinkModel | null | undefined;
    public readonly contentBlock?: ContentBlockModel;

    constructor(props:Readonly<AccessRestrictedPageModel> | null | undefined) {
        const {htmlId, templateType, contentBlock, navigationTheme, logo, pageTitle, description, keywords = []} = props || {};
        this.htmlId = htmlId;
        this.templateType = templateType || null;
        this.navigationTheme = navigationTheme || null;
        this.keywords = keywords;
        this.description = description || null;
        this.pageTitle = pageTitle || "";
        this.logo = logo;
        this.contentBlock = new ContentBlockModel(contentBlock)
    }
}

