import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import LinkDataTypeEnum from "../../../../enums/LinkDataTypeEnum";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import { useMenuRequestQuery } from "../../../../redux/_shared/helpers/vystarApi";

interface ILogoStatic{
    useStaticImage?: boolean;
    logo?: any;
}


function Logo (props: SmartLinkModel & ILogoStatic) {
    let { icon, useStaticImage, url } = props;

    let imageURL = icon && icon.imageURL ? icon.imageURL : "";

    let logo: SmartLinkModel | undefined;
    
    const navigationAPI = useMenuRequestQuery(null);
    if(navigationAPI.isSuccess && navigationAPI.status === "fulfilled"){
        logo = new SmartLinkModel(navigationAPI.data[0].logo);
        url = logo.url;
    }

    return (
        <div className="col-4 col-lg-3 col-xl-3">
            <div className="header-logo">
                {imageURL ? <SmartLink url={url} dataType={LinkDataTypeEnum.RelativeURL}>
                    <img src={imageURL} alt="VyStar Credit Union"/>
                </SmartLink>: useStaticImage ? <SmartLink url={url} dataType={LinkDataTypeEnum.RelativeURL}>
                    <img src={logo?.icon?.imageURL} alt="VyStar Credit Union"/>
                </SmartLink>:<></>}
            </div>
        </div>
    )
}
export default Logo;
