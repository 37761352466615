
import IComponentProps from "../../../../interfaces/IComponentProps";
import RateDetailPageModel from '../../../../models/RateDetailPageModel';
import CTABarProductDetail from "../../../components/CTABarProductDetail/CTABarProductDetail";
import RatesFilter from "../../../components/RatesFilter/RatesFilter";
import { Calculator } from "../../../components/Calculator/Calculator";
import { isDinkyTownCalc } from "../../../_shared/helpers/DinkyTownCalculators";
import { ChimneyCalculator } from "../../../components/ChimneyCalculator/Calculator";

export default function RateDetailPage(props: RateDetailPageModel & IComponentProps){
    let { htmlId="", className, callToActionBar, ratesFilter, calculator, disclosures } = props;
    let topicCalculator = calculator?.topicCalculator || {};
    let {calculatorCode} = topicCalculator || {};
    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <RatesFilter {...{...ratesFilter, disclosures}}/>
            </section>
            <section>
                {calculatorCode && isDinkyTownCalc(calculatorCode) ? 
                    <Calculator {...{...calculator, topicCalculator}}/> : 
                    <ChimneyCalculator {...{...calculator, topicCalculator}}/>}
            </section>
            <section id="getting-started">
                <CTABarProductDetail {...callToActionBar}/>
            </section>
        </div>
    );
}