import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import "./HorizontalScroll.module.scss"
import IComponentProps from "../../../../interfaces/IComponentProps";
import IContainerComponentProps from "../../../../interfaces/IContainerComponentProps";

export function HorizontalScroll(props: IComponentProps & IContainerComponentProps & {styleOverrides:any | undefined}) {
    
    const { className } = props;

    let {children} = props ?? null;

    return !!children ? <div className={`scrollable-horizontal scroller ${className}`}>
            {children}
        </div> : <></>;
}
export default withErrorBoundary(HorizontalScroll);
