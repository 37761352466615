import React, {useEffect, useState} from "react";
import NavMenuItemModel from "../../../../models/NavMenuItemModel";
import LinkList from "../../../components/_shared/LinkList/LinkList";
import Promotional from "../../../components/Promotional/Promotional";
import PromotionTypesEnum from "../../../../enums/PromotionTypesEnum";
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import SmartLinkModel from "../../../../models/SmartLinkModel";

interface IMenuItem extends NavMenuItemModel {
    index: Readonly<number>,
    activeIndex: Readonly<number> | Readonly<null>,
    setActiveIndex?: { (index: number | null): void }
    onMouseOver: React.MouseEventHandler
}

export default function SubMenu (props : IMenuItem){
    const {onMouseOver, label, links, promoContent, titleLink, callToAction, index, activeIndex} = props;
    const isVisible = activeIndex === index;
    const itemId = `desktopSubMenu${index}`;
    const linkList1 = links?.slice(0,4) || [];
    const linkList2 = links?.slice(4,8) || [];

    const [isAdjusted, setIsAdjusted] = useState(false);
    useEffect(() => {
        if(!isAdjusted && isVisible){
            //adjust if necessary to ensure menu can be seen
            const selector = `#${itemId}.submenu`;
            const menuElement = document.querySelector(selector);
            const boundingRectangle = menuElement?.getBoundingClientRect();
            const {x} = boundingRectangle || {};

            if(x && x < 50){
                // @ts-ignore
                menuElement.style.left = (-x + 50) + 'px';
            }
        }
    }, [isVisible, isAdjusted, setIsAdjusted, itemId]);
    const className = `title ${titleLink?.url ? '' : 'disabled'}`;
    const smartLinkModel = {...titleLink, defaultLinkText: label};
    const smartLink = new SmartLinkModel(smartLinkModel);

    return (!(links && links.length && isVisible) ? <React.Fragment/> :
        <div {...{onMouseOver, }} className="submenu" id={itemId} aria-labelledby="resources"
             role="group">
            <div className="menu_column">
                <SmartLink {...smartLink} className={className} /> 
                <div className="menu_inner_column">
                    {!!linkList1 && !!linkList1.length &&
                    <LinkList links={linkList1} className={'submenu_item'} linkClassName={'submenu_link'}/>}
                    {!!linkList2 && !!linkList2.length &&
                    <LinkList links={linkList2} className={'submenu_item'} linkClassName={'submenu_link'}/>}
                </div>
                {callToAction && <div className="cta">
                    <SmartLink {...callToAction}/>
                </div>}
            </div>
            {promoContent && <Promotional content={promoContent} type={PromotionTypesEnum.PromoDesktopMenu} className={"menu_column_promo"}/>}
        </div>
    );
}