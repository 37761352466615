import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";
import {IPromotionContent} from "./PromotionModel";
import ImageModel from "./ImageModel";

export default class NavMenuItemModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    readonly htmlId?: string | undefined;
    public readonly label?: string;
    public readonly promoContent?: IPromotionContent | null;
    public readonly titleLink?: SmartLinkModel | null;
    public readonly links?: Array<SmartLinkModel>;
    public readonly callToAction?: SmartLinkModel | null;
    constructor(props: NavMenuItemModel) {
        let {label, titleLink, links, promoContent, callToAction} = props;
        this.label = label;
        this.titleLink = titleLink;
        this.links = links?.map(link => new SmartLinkModel(link)) || [];
        this.callToAction = callToAction ? new SmartLinkModel(callToAction) : null;
        this.promoContent = promoContent ? {
            body: promoContent?.body,
            header: promoContent?.header,
            image: new ImageModel(promoContent?.image),
            links: promoContent?.links?.map(link => new SmartLinkModel(link)) || [],
            secondaryHeader: promoContent?.secondaryHeader
        }: null;
    }
}

export const _clientType = NavMenuItemModel.constructor.name;

