
import stylesBase from '../_HeroBase.module.scss';
import IComponentProps from "../../../../interfaces/IComponentProps";
import PromoHero from "../PromoHero/PromoHero";
import PromotionModel from "../../../../models/PromotionModel";
import CalculateNavigationTheme from '../../../_shared/helpers/CalculateNavigationTheme';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateNavigationTheme } from '../../../../redux/slices/pageContentSlice';
import ThemeTypeEnum from '../../../../enums/ThemeTypeEnum';

export default function PromoHeroSmall(props: PromotionModel & IComponentProps)  {
    let {className = "", theme, primary, ...otherProps} = props;
    props = {...otherProps, theme, className: `${className} ${stylesBase['c-hero-small']}`};

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    return <PromoHero {...props}></PromoHero>;
}