enum SideAlignmentEnum{
    // @ts-ignore
    Default = "Left",
    // @ts-ignore
    Left = "Left",
    // @ts-ignore
    Right = "Right",
    // @ts-ignore
    Center = "Center",
}

export default SideAlignmentEnum;
