
import { connectHighlight } from "react-instantsearch-dom";
import styling from "./Search.module.scss";

interface ISearchHighlight{
    highlight: any, 
    attribute: string,
    hit: any, 
    isURL: any;
}

const SearchHighlight = ({ highlight, attribute, hit, isURL }: ISearchHighlight) => {
    const parsedHit = highlight({
        highlightProperty: "_highlightResult",
        attribute,
        hit
    });

    return !isURL ? (
        <span>
            {parsedHit.map((part: any, index: number) =>
                part.isHighlighted ? (
                    <mark className="ais-Highlight-highlighted p-0" key={index}>
                        {part.value}
                    </mark>
                ) : (
                    part.value
                )
            )}
        </span>
    ) : (
        <a href={hit.url}>
            {parsedHit.map((part: any, index: number) =>
                part.isHighlighted ? (
                    <mark className={`${styling["ais-Highlight-highlighted"]} p-0`} key={index}>
                        {part.value}
                    </mark>
                ) : (
                    part.value
                )
            )}
        </a>
    );
};
export default connectHighlight(SearchHighlight);
