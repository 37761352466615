import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";

export default class LinkGroup implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly label?: string | undefined = "";
    public readonly links?: SmartLinkModel[] | null | undefined;

    constructor(contentfulFAQModel: Readonly<LinkGroup> | undefined | null){
        const {htmlId = "", label, links} = contentfulFAQModel || {};
        this.htmlId = htmlId;
        this.label = label;
        this.links = links;
    }
}

