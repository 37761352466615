export interface IStyleSheetConfigModel{
    htmlID?: string,
    href?: string,
    destination?: HTMLElement | HTMLScriptElement | Element | null,
    type?:string,
    rel?: string
}

export default function createStyleSheetLink(config: IStyleSheetConfigModel = {}){
    let {htmlID, type, href, destination = document.head} = config;
    let scriptBlock = document.createElement('link');
    scriptBlock.type = type || "text/css";
    scriptBlock.setAttribute('rel', 'StyleSheet');

    if(htmlID){
        scriptBlock.id = htmlID;
    }
    if(href){
        scriptBlock.href = href;
    }

    destination?.appendChild(scriptBlock);
}