import { ContextPlugin, PersonalizationEvent } from "@uniformdev/context";

declare global {
  interface Window { gtag: any; }
}

export function updateVariationForCustomPlugin(variation: any, entry: any) {
  //
  // Since we want to write additional data to GA,
  // we need to encode those additional values in 
  // one of the values that is included in the 
  // personalization event. The variation ID is
  // this value. NOTE that changing the variation
  // ID in this way will affect all context plugins!
  variation.id = JSON.stringify({
    original: entry.id,
    entry: {
      contentType: entry.htmlId,
      entryId: entry.id,
      name: entry._clientType === "PromotionModel" ? entry.content.header : entry.header,
    },
    criteria: entry.criteria,
  });
}
export function enableCustomGoogleGtagAnalytics(): ContextPlugin {
  /**
   * Sends a separate event to GA for each personalized variation.
   * @param e 
   */
  function onPersonalization(e: PersonalizationEvent) {
    
    const { changed, control, name, variantIds } = e;
    if (changed) {
      const ids = variantIds.map(variantId => JSON.parse(variantId.id));
      
      ids.forEach(id => {
        const { original, entry, criteria } = id;
        const data = {
          event_category: 'Uniform Personalization',
          event_label: original,
          is_control_group: control ? 1 : 0,
          component_name: entry.contentType,
          criteria: criteria
        }
        if (window?.gtag) {
          window.gtag('event', name, data);
        }
      })
    }
  }
  return {
    init: (context => {
      context.events.on("personalizationResult", onPersonalization);
      return () => {
        context.events.off("personalizationResult", onPersonalization);
      }
    })
  }
}