import styling from "./SideBar.module.scss";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import SideBarModel from '../../../models/SideBarModel';
import LinkList from "../_shared/LinkList/LinkList";

export function SideBarRender(props: (SideBarModel & IComponentProps)) {
    const { links, label } = props;
    return (
        <div className={`${styling["sidebar"]}`}>
            <h2>{label}</h2>
            <LinkList links={links} className="ps-0"/>
        </div>
    )
}
export default withErrorBoundary(SideBarRender);