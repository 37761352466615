import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";
import QuestionAnswerModel from "./QuestionAnswerModel";

export default class FAQModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly id: string | undefined;
    public readonly header: string | undefined;
    public readonly subHeader: string | undefined;
    public readonly link: SmartLinkModel | undefined;
    public readonly content: Array<QuestionAnswerModel> | undefined;

    constructor(contentfulFAQModel: Readonly<FAQModel> | undefined | null){
        const {htmlId = "", header, link, subHeader, content, id} = contentfulFAQModel || {};
        this.htmlId = htmlId;
        this.id = id;
        this.header = header;
        this.link = link;
        this.subHeader = subHeader; 
        this.content = content?.map( faq => new QuestionAnswerModel(faq)); 
    }
}

