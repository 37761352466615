enum ThemeTypeEnum{
    // @ts-ignore
    Default = "Light",
    // @ts-ignore
    Dark = "Dark",
    // @ts-ignore
    Light = "Light"
}

export default ThemeTypeEnum;
