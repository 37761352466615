
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import CategoryCardList from "../../components/CategoryCardList/CategoryCardList";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import Disclosures from '../../components/Disclosures/Disclosures';
import ComparePageModel from "../../../models/ComparePageModel";
import CompareTable from '../../components/CompareTable/CompareTable';
import { Calculator } from "../../components/Calculator/Calculator";
import { isDinkyTownCalc } from "../../_shared/helpers/DinkyTownCalculators";
import { ChimneyCalculator } from "../../components/ChimneyCalculator/Calculator";

export default function ComparePage({htmlId, className, hero, compareTable, testimonials, relatedTools, disclosures, calculator}:
    ComparePageModel & IComponentProps){
    let topicCalculator = calculator?.topicCalculator || {};
    let {calculatorCode} = topicCalculator || {};

    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero}/>
            </section>
            <section>
                <CompareTable {...compareTable}/>
            </section>
            <section className="bg-gray">
                {calculatorCode && isDinkyTownCalc(calculatorCode) ? 
                    <Calculator {...{...calculator, topicCalculator}}/> : 
                    <ChimneyCalculator {...{...calculator, topicCalculator}}/>
                }
            </section>
            <section style={{backgroundImage: `url(${testimonials?.backgroundImage?.imageURL})`}}>
                <TestimonialCarousel {...testimonials}/>
            </section>
            <section style={{backgroundImage: `url(${relatedTools?.background?.imageURL})`}}>
                <CategoryCardList {...relatedTools}/>
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
