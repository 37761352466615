
import IComponentProps from "../../../../interfaces/IComponentProps";
import FooterSupportModel, {IContentBlock, ImageListItemModelContentArray} from "../../../../models/FooterSupportModel";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import "./Footer.module.scss";
import ImageListItem from "../../../components/ImageList/ImageListItem/ImageListItem";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import ImageListItemModel from "../../../../models/ImageListItemModel";

export const getDocumentByName = (docs: IContentBlock[] | undefined, name: string | undefined) => docs?.filter(c=>c.name === name).pop()

export function FooterSupport(props: (FooterSupportModel & IComponentProps)){
    let { logos, imageLists, contentBlocks, id} = props;
    let imageList = imageLists?.reduce((accumulator: ImageListItemModel[], arrayContainer: ImageListItemModelContentArray) => accumulator.concat(arrayContainer?.content || []), []).map((item: ImageListItemModel | null) => new ImageListItemModel(item)) || [];
    return (<div className="row align-items-end" data-contentid={id}>
                <div className="col-12 col-lg-2 col-xl-3">
                    {logos?.map( (image, key) => {
                        return <ImageListItem {...{key}} htmlId={""} subText={""} image={image} />;
                    })}
                </div>
                <div className="col-12 col-lg-8 col-xl-7">
                    <div className="footer-info">
                        {
                            contentBlocks?.map((contentBlock, i) => {
                                const className = "info-col-" + (i+1).toString();
                                return (<div className={className} key={i}>
                                            {documentToReactComponents(contentBlock.richText, ContentfulReactRenderOptions)}
                                        </div>)
                            })
                        }
                    </div>
                </div>
                <div className="col-12 col-lg-2 col-xl-2">
                    <div className={`link-list extra`}>
                        {imageList && <ul>
                            {
                                imageList.map((item: ImageListItemModel, index: number) => {
                                    return (<li key={index}>
                                        <ImageListItem {...item}/>
                                    </li>)
                                })
                            }
                        </ul>}
                    </div>
                </div>
            </div>);
}
export default withErrorBoundary(FooterSupport);