
import IComponentProps from "../../../interfaces/IComponentProps";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import CTABarProductModel from "../../../models/CTABarProductModel";
import styles from "./CTABarProductDetail.module.scss";
import LinkList from "../_shared/LinkList/LinkList";

export function CTABarProductDetailRender(props: CTABarProductModel & IComponentProps)  {
    let {className = '', htmlId, content, id} = props || {};
    let {secondaryLinks, primaryLinks, label/*, name*/} = content || {};
    return (<div id={htmlId} className={`${styles.ctabar} layout ctabar ${className}`} data-contentid={id}>
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-12 col-xl-12">
                    <div className="ctabar-content">
                        <div>
                            <h4 className="d-none d-lg-block">{label}</h4>
                        </div>
                        <div>
                            <LinkList links={primaryLinks} dataCTA="primary-links"/>
                        </div>
                        <LinkList links={secondaryLinks} dataCTA="secondary-links"/>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

CTABarProductDetailRender.displayName = "CTABarProductDetail";
export default withErrorBoundary(CTABarProductDetailRender);