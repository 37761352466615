import styles from './Input.module.scss'

export interface HelperTextProps {
  text?: string
  style?: any
  className?: string
}

const HelperText = ({ text, style, className }: HelperTextProps) => {
  return (
    <span className={`${className}`} style={style}>
      {text}
    </span>
  )
}

export default HelperText