import SomethingWentWrongPageModel from "../../../models/SomethingWentWrongPageModel";
import stylesBase from "../Error.module.scss";
import logoWhite from "../../_assets/logo-vystar-white.svg"
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import { useErrorRequestQuery } from "../../../redux/_shared/helpers/vystarApi";
import { Document } from "@contentful/rich-text-types";


export function SomethingWentWrongPage() {

    const errorAPI = useErrorRequestQuery(null);
    let content: Document | null = null;
    if(errorAPI.isSuccess && errorAPI.status === "fulfilled"){
        const errorModel = new SomethingWentWrongPageModel(errorAPI.data[0]);
        content = errorModel.content || null;
    }

    return (
        <section className={stylesBase.section}>
            <header className={stylesBase.header}>
                <img
                    src={logoWhite}
                    alt="VyStar Credit Union"
                />
            </header>
            <div className={stylesBase.page}>
                {content ? <div className={stylesBase.container}>
                    <span className={stylesBase['on-desktop']}>
                    {content && documentToReactComponents(content, ContentfulReactRenderOptions)}
                    </span>
                    <span className={stylesBase['on-mobile']}>
                    {content && documentToReactComponents(content, ContentfulReactRenderOptions)}
                    </span>
                </div>:
                <div className={stylesBase.container}>
                    <h1>Something went wrong</h1>
                    <span className={stylesBase['on-desktop']}>
                        <p>Please try back shortly or not {" "}
                            <a href="https://online.vystarcu.org/VYCU/login.aspx">Log in to Online Banking</a>
                        </p>
                    </span>
                    <span className={stylesBase['on-mobile']}>
                        <p className="mx-1">Please try back shortly or
                            <a href="https://mobile.vystarcu.org" className="mx-1">Log in to Mobile Banking</a>
                        </p>
                    </span>
                </div>}
            </div>
        </section>
    );
}
export default withErrorBoundary(SomethingWentWrongPage);