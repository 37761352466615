
import styles from './TestimonailPanel.module.scss';
import Photo from "../Photo/Photo";
import Quote from "../Quote/Quote";
import TestimonialModel from "../../../../models/TestimonialModel";
import IComponentProps from "../../../../interfaces/IComponentProps";
interface ITestimonialPanelProps extends IComponentProps{
    htmlId?: string | undefined,
    testimonial: TestimonialModel,
    showQuotes: Boolean
}

export default function TestimonialPanel(props:ITestimonialPanelProps){
    let {htmlId, showQuotes, className, testimonial } = props;
    let {byLine = "", quote = "", image = null } = testimonial || {};

    return (
        <div id={htmlId} className={`${styles.root} ${className}`} >
            <Photo {...image} />
            <Quote {...{byLine, quote, showQuotes}}/>
        </div>
    );
};
