import ImageModel from "./ImageModel";
import {Document} from "@contentful/rich-text-types";
import TallTabTypeEnum from "../enums/TallTabTypeEnum";
import LinkGroup from "./LinkGroup";
import CTALinkGroup from "./CTALinkGroup";

export class MainContentBlockModel {
    public readonly id?: string | undefined = "";
    public readonly name?: string | undefined = "";
    public readonly richText?: Document | undefined | null;
    constructor(props:SideContentBlockModel | null | undefined) {
        const {id, name, richText} = props || {};
        this.id = id;
        this.name = name;
        this.richText = richText;
    }
}

export class SideContentBlockModel {
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined = "";
    public readonly name?: string | undefined = "";
    public readonly richText?: Document | undefined | null;
    public readonly tabDescription?: string | undefined = "";
    public readonly tabHeader?: string | undefined = "";
    public readonly type?: string | undefined = "";
    constructor(props:SideContentBlockModel | null | undefined) {
        const {htmlId, id, name, richText, tabDescription, tabHeader, type} = props || {};
        this.htmlId = htmlId;
        this.id = id;
        this.name = name;
        this.richText = richText;
        this.tabDescription = tabDescription;
        this.tabHeader = tabHeader;
        this.type = type;
    }
}

export default class TabTallModel {
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined = "";
    public readonly keyword?: string;
    public readonly callToActionGroup?: CTALinkGroup;
    public readonly linkGroups?: LinkGroup | null | undefined;
    public readonly relatedLinkGroup?: LinkGroup | null | undefined;
    public readonly tabDescription?: string;
    public readonly tabHeader?: string;
    public readonly icon?: ImageModel | null | undefined;
    public readonly selectedIcon?: ImageModel | null | undefined;
    public readonly image?: ImageModel | null | undefined;
    public readonly name?: string;
    public readonly type?: TallTabTypeEnum;
    public readonly mainContentBlock?: MainContentBlockModel | null | undefined;
    public readonly sidePanelBlock?: SideContentBlockModel | null | undefined;
    constructor(props:TabTallModel | null) {
        const {keyword = "", id = "", htmlId, selectedIcon, tabHeader, mainContentBlock, tabDescription, sidePanelBlock, callToActionGroup,relatedLinkGroup, linkGroups, image, icon, name, type} = props || {};
        this.keyword = keyword;
        this.callToActionGroup = new CTALinkGroup(callToActionGroup);
        this.linkGroups = new LinkGroup(linkGroups);
        this.tabDescription = tabDescription;
        this.name = name;
        this.tabHeader = tabHeader;
        this.htmlId = htmlId;
        this.image = new ImageModel(image);
        this.icon = icon;
        this.selectedIcon = selectedIcon;
        this.relatedLinkGroup = new LinkGroup(relatedLinkGroup);
        this.mainContentBlock = new MainContentBlockModel(mainContentBlock);
        this.sidePanelBlock = new SideContentBlockModel(sidePanelBlock);
        this.type = type;
        this.id = id;
    }
}