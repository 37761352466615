/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */

import envDataConfig from "../../../EnvDataConfig/EnvDataConfig";
/* eslint-disable no-sequences */
!function() {
    var p = window.posh = {};
    if (p.called) return void (window.console && console.error &&
        console.error("Posh script already included"));
    p.called = true, p.methods = [ "open", "close" ],
        p.methods.forEach(function(m) {
            p[m] = function() {};
        }), p.load = function(d, o) {
        p.options = o;
        var w = document.createElement("script");
        w.src = "https://js.poshdevelopment.com/widget/entry.js";
        w.id = "__chat-entry__", w.setAttribute('deploymentid', d);
        var l = document.getElementsByTagName("script")[0];
        l.parentNode.insertBefore(w, l);
    };
}();
posh.load(envDataConfig.poshId, {
    widgetConfig: {},
});