
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import SubEventModel from "../../../models/SubEventModel";
import styling from "./BlogNewsEvents.module.scss";
import SmartLink from "../_shared/SmartLink/SmartLink";
import { formatDate } from "./BlogNewsEventsCard";

export function SubEvent({ eventName, eventLocation, startDate, endDate, image,
    registrationLink, directionsLink }: SubEventModel & IComponentProps) {

    return (
        <div className={`${styling['single-event']}`}>
            <div className={`${styling['event-details']}`}>
                <h2>{eventName}</h2>
                <div className={`${styling['meta']}`}>
                    <span>{formatDate(false, undefined, startDate, endDate)}</span>
                    <span>{eventLocation}</span>
                </div>
                <div className={`${styling['avatar']} d-block d-md-none`}>
                    <img src={image?.imageURL} alt={image?.title} />
                </div>
                <br />
                <p>
                    {registrationLink && <SmartLink {...registrationLink} className={`${styling["smartlink_button"]}`} />}
                    {directionsLink && <SmartLink {...directionsLink} className={`${styling["smartlink_button"]}`} />}
                </p>
            </div>
            <div className={`${styling['avatar']} d-none d-md-block`}>
                <img src={image?.imageURL} alt={image?.title} />
            </div>
        </div>
    );
}
export default withErrorBoundary(SubEvent);
