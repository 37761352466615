import ContentTypesEnum from "../enums/ContentTypesEnum";

export default class CalculatorTopicModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly contentType?: ContentTypesEnum | undefined;
    public readonly calculatorCode?: string;
    public readonly htmlId?: string | undefined = "";

    constructor(topicCalculatorModel: Readonly<CalculatorTopicModel> | undefined | null){
        const {htmlId = "", calculatorCode, contentType} = topicCalculatorModel || {};
        this.contentType = contentType;
        this.htmlId = htmlId;
        this.calculatorCode = calculatorCode || "";
    }
}