import TabTallModel from "./TabTallModel";

export default class TabsTallModel {
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string;
    public readonly htmlId?: string | undefined = "";
    public readonly keyword: string;
    public readonly header?: string | undefined;
    public readonly description?: string | undefined;
    public readonly name?: string | undefined;
    public readonly tabs?: TabTallModel[] | undefined;
    constructor(props: TabsTallModel | null | undefined) {
        const {keyword = "", htmlId, tabs, name, header, description, id = ""} = props || {};
        this.id = id;
        this.keyword = keyword;
        this.htmlId = htmlId;
        this.header = header;
        this.name = name;
        this.description = description;
        this.tabs = tabs?.map(tab => new TabTallModel(tab)) || [];
    }
}