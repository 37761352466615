
import withErrorBoundary from '../../../_shared/helpers/withErrorBoundary';
import { IComparisonViewProps } from "../IComparisonViewProps";
import styling from "../CompareTable.module.scss";
import ComparisonCard from "./ComparisonCard";


export function MobileComparisonViewRender(props: IComparisonViewProps){
    let {compareTable, maxComparisons, defaultRowCount} = props;
    let {table } = compareTable;
    let { columnDefinition = [], numberOfRows = defaultRowCount, tableItems } = table ?? {};
    return <div className={`container ${styling["container"]} d-lg-none`}>
        <div className="row">
            <div className="col-12">
                { tableItems?.slice(0,maxComparisons).map((row: any, index: number) => {

                    const mobileModel = {
                        row,
                        columnDefinition,
                        numberOfRows
                    }
                    return(
                        <ComparisonCard key={index} {...mobileModel} />
                    )
                })}
            </div>
        </div>
    </div>;
}
MobileComparisonViewRender.displayName = "MobileComparisonView";
export default withErrorBoundary(MobileComparisonViewRender);


