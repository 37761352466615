
import IComponentProps from "../../../interfaces/IComponentProps";
import BlogPageModel from "../../../models/BlogPageModel";

export default function BlogPage(props: BlogPageModel & IComponentProps){
    return (
        <div style={{position:"relative", height:"100%", padding:"160px"}}>
            <section>
                <h1>Blog Page</h1>
            </section>
        </div>
    );
}
