import IClientServerModel from "../interfaces/IClientServerModel";
import {Document} from "@contentful/rich-text-types";
import ImageModel from "./ImageModel";
import ImageListItemModel from "./ImageListItemModel";
import ImageListModel from "./ImageListModel";

export interface IContentBlock{
    name: string;
    richText: Document;
}

export interface ImageListItemModelContentArray {
    content?: ImageListItemModel[];
}

export default class FooterSupportModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id?: string | undefined;
    public readonly htmlId: string | undefined = "";
    public readonly logos?: ImageModel[] | null | undefined;
    public readonly contentBlocks: IContentBlock[] | undefined = [];
    public readonly imageLists?: ImageListModel[] | undefined;

    constructor(contentfulFooterModel: Readonly<FooterSupportModel> | undefined | null){
        const {htmlId = "", logos, contentBlocks, imageLists, id } = contentfulFooterModel || {};
        this.id = id;
        this.htmlId = htmlId;
        this.contentBlocks = contentBlocks;
        this.logos = logos?.map((logo: Readonly<ImageModel> | null | undefined) => new ImageModel(logo));
        this.imageLists = imageLists?.map(item => {
            return  new ImageListModel(item);
        });
    }
}

