export interface IAssociativeArray<T1, T2>{
    set(key:T1, value:T2):IAssociativeArray<T1, T2>,
    get(key:T1):T2,
    clear: (() => void)
    entries:(() => {key: T1, value: T2}[]),
    keys:() => T1[],
    values:() => T2[]
}

export default function createAssociativeArray<T1 ,T2>(sourceObject?: IAssociativeArray<T1,T2>){
    const associativeArray = sourceObject || {} as IAssociativeArray<T1,T2>;
    const keys: Set<T1> = sourceObject?.keys ? new Set(sourceObject?.keys()) : new Set();
    // @ts-ignore
    associativeArray.set = (key:T1, value:T2):IAssociativeArray=>{
        // @ts-ignore
        associativeArray[key] = value;
        if(!keys.has(key)){
            keys.add(key);
        }
        return associativeArray;
    };
    // @ts-ignore
    associativeArray.get = (key:T1):T2 | null=>{
        // @ts-ignore
        return keys.has(key) ? (associativeArray[key] || null) : null;
    };

    // @ts-ignore
    associativeArray.entries = ()=>{
        return Object.entries(associativeArray).map(([key, value]) => {
            return {key, value};
        }).filter(entry => {
            let {key} = entry;
            return associativeArray.keys().some(k=>{
                // @ts-ignore
                return key === k;
            });
        });
    }

    associativeArray.keys = ()=>{
        return Array.from(keys);
    }

    associativeArray.values = ()=>{
        return associativeArray.entries().map(({value})=>value);
    }

    associativeArray.clear = ()=>{
        keys.clear();
    }

    return associativeArray as IAssociativeArray<T1,T2>;
}