
import "./ImageList.module.scss";
import ImageListItem from "./ImageListItem/ImageListItem";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import ImageListModel from "../../../models/ImageListModel";
import styles from "./ImageList.module.scss";
import IComponentProps from "../../../interfaces/IComponentProps";
import ContentBlock from "../ContentBlock/ContentBlock";

export function ImageListRender(props: ImageListModel & IComponentProps)  {

    const {content, className='', htmlId, contentBlock, header, subheader, id} = props || {};
    const hasIntro = header || subheader;

    return (
        <div id={htmlId} className={`layout ${styles['image-list']} ${className}`} data-contentid={id}>
            <div className="container">
                { hasIntro &&
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className={`${styles['intro']} text-center`}>
                            {header && <h2>{header}</h2>}
                            {subheader &&<p>{subheader}</p>}
                        </div>
                    </div>
                </div>
                }
                { contentBlock && 
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className={`${styles['intro']} text-center`} >
                            <ContentBlock {...contentBlock} />
                        </div>
                    </div>
                </div>}
                {content &&
                    <div className={`${styles['image-list-layout']} ${styles['image-list-count-'+ content?.length]} row justify-content-center`}>
                    {
                        content?.map((imageItem, index) => {
                            // sometimes we get a plain image, other times it's a topic-Image with an image prop
                            const isOnlyImage = !!!imageItem.image;
                            return ( isOnlyImage ? 
                                <ImageListItem 
                                    key={index} 
                                    image={imageItem}
                                />:
                                <ImageListItem
                                    key={index}
                                    {...imageItem}
                                /> 
                            );
                        })
                    }
                </div>}
            </div>
        </div>);
}
ImageListRender.displayName = "ImageList";
export default withErrorBoundary(ImageListRender);