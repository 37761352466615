export type GeoData = {
    city?: string;
    country?: string;
    region?: string;
    regionCode?: string;
}

export const retrieveGeo = async (endpoint?: string): Promise<GeoData> => {
    if(!endpoint) return {};
    const response = await fetch(endpoint);
    return response.json();
}