import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import IComponentProps from "../../../../interfaces/IComponentProps";
import SubFooterModel from "../../../../models/SubFooterModel";
import LinkList from "../../../components/_shared/LinkList/LinkList";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import "./Footer.module.scss";

export function SubFooter(props: (SubFooterModel & IComponentProps)){
    let {links= [], socialLinks = [], contentBlock } = props;

    return (<div id="lower-footer">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-8">
                <LinkList links={links} linkClassName="text-nowrap" />
            </div>
            <div className="col-12 col-lg-4">
                <LinkList className={"social"} links={socialLinks}/>
            </div>
        </div>
        <div className="row g-0">
            <div className="col-12">
                <div className="footer-disclosures"> 
                    <div className="disclosure" >
                        { contentBlock?.richText && documentToReactComponents(contentBlock.richText, ContentfulReactRenderOptions)}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>);
}
export default withErrorBoundary(SubFooter);