
import styles from './TestimonialCarousel.module.scss';
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import TestimonialPanel from "./TestimonialPanel/TestimonialPanel";
import "slick-carousel/slick/slick.css"
import Carousel from '../_shared/Carousel/Carousel'
import TestimonialCarouselModel from "../../../models/TestimonialCarouselModel";
import TestimonialModel from "../../../models/TestimonialModel";
import IComponentProps from "../../../interfaces/IComponentProps";
import PrevArrowIcon from "./images/carousel-arrow-prev.svg";
import NextArrow from "./images/carousel-arrow-next.svg";

function TestimonialCarousel(props: TestimonialCarouselModel & IComponentProps){
    
    let {id, className="", header, showQuotes, content=[], htmlId, backgroundImage} = props;
    let { imageURL:backgroundImageURL } = backgroundImage || {};
    let backgroundImageStyle = backgroundImageURL ? {backgroundImage:`url(${backgroundImageURL})`} : {};
    return content.length ? (
        <div id={htmlId} className={`${styles['c03-small-carousel']} ${className} layout`} style={backgroundImageStyle} data-contentid={id}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro text-center">
                            <h2>{header}</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-11 col-lg-10">
                        <div className={styles.carousel}>
                            <Carousel nextIcon={NextArrow} prevIcon={PrevArrowIcon}>
                                {content?.map((testimonial:TestimonialModel, index) => {
                                    return <TestimonialPanel id={htmlId} {...{showQuotes, testimonial}} key={`TestimonialPanel-${index}`}/>
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : <></>;
}
export default withErrorBoundary(TestimonialCarousel);
