
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import ITabComponentProps from "../ITallTabComponetProps";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import LinkList from "../../_shared/LinkList/LinkList";
import SmartLink from "../../_shared/SmartLink/SmartLink";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";

export function TabSplitLinkPanel(props: ITabComponentProps){
    let {tabDescription, isSelected, mainContentBlock, sidePanelBlock, relatedLinkGroup, callToActionGroup, index} = props;
    let {links:relatedLinks, label} = relatedLinkGroup || {};
    let {richText:richTextLeft, id:idLeft} = mainContentBlock || {};
    let {richText:richTextRight, id:idRight} = sidePanelBlock || {};
    
    return <>
        <div role="tabpanel" aria-labelledby={`tab-${index}`} aria-hidden={!isSelected}>
            <div className="accordion-desc">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <p>{tabDescription}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container tab-panel-content">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                        <div className="tab-panel-content-inner" data-contentid={idLeft}>
                            { richTextLeft && documentToReactComponents(richTextLeft, ContentfulReactRenderOptions)}
                        </div>
                        {callToActionGroup && callToActionGroup?.primaryLinks?.map((linkData, index)=>{
                            return <SmartLink {...linkData} type={LinkTypeEnum.PrimaryButton} key={index} />;
                        })}
                        {callToActionGroup && callToActionGroup?.secondaryLinks?.map((linkData, index)=>{
                            return <SmartLink {...linkData} type={LinkTypeEnum.SecondaryButton} key={index} />;
                        })}
                    </div>
                    <div className="col-12 col-lg-1 d-flex flex-row">
+                        <hr />
                    </div>
                    <div className="col-12 col-lg-4">
                        {richTextRight && <div className="tab-panel-content-inner right-side" data-contentid={idRight}>
                            { richTextRight && documentToReactComponents(richTextRight, ContentfulReactRenderOptions)}
                        </div>}
                        <LinkList links={[]}/>
                    </div>
                </div>
                {(label || relatedLinks?.length) ? <div className="row justify-content-center mt-5">
                    <div className="col-12 col-lg-11">
                        <div className="horizontal-list-links">
                            <h3>{label}</h3>
                            <LinkList links={relatedLinks}/>
                        </div>
                    </div>
                </div> : <></>}
            </div>
        </div>
    </>;
}

TabSplitLinkPanel.displayName = "TabSplitLinkPanel";
export default withErrorBoundary(TabSplitLinkPanel);