
import IComponentProps from "../../../../interfaces/IComponentProps";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import SmartLink from "../SmartLink/SmartLink";
import styles from "./LinkList.module.scss"

interface ILinkListProps extends IComponentProps{
    links: Array<SmartLinkModel> | null | undefined,
    linkClassName?: string | undefined | null,
    linkRender?: (link: SmartLinkModel, index: number) => JSX.Element,
    dataCTA?: string | undefined
}

export default function LinkList(props:ILinkListProps){
    const {links = [], id, className, linkClassName, linkRender, dataCTA} = props;
    const hasLinks = !!links?.length;
    return hasLinks ? (<div id={id} className={`${styles.linkList} link-list ${className || ""}`}>
        {links && <ul>
            {
                links.map((link: SmartLinkModel, index: number) => {
                    return (<li key={index}>
                        {linkRender === null ||  linkRender === undefined?
                            <SmartLink key={index} {...{...link, className: `${linkClassName || ""}`, dataCTA}}/> :
                            linkRender(link, index)
                        }
                    </li>)
                })
            }
        </ul>}
    </div>): <></>;
}
