import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import DisclosureItemModel from "../../../models/DisclosureItemModel";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";

interface ILegalTexts{
    legalTexts: DisclosureItemModel[]
}
function LegalText({legalTexts}: ILegalTexts){
    return <>
        {
            legalTexts.map((legalText: DisclosureItemModel, index)=> legalText?.disclosureText ? 
            <div key={index} data-contentid={legalText.id}>
                {
                    documentToReactComponents(legalText.disclosureText, ContentfulReactRenderOptions)
                }
            </div>:<></>)
        }
    </>
}
export default LegalText;