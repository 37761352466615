
import NavMenuItemModel from "../../../../models/NavMenuItemModel";
import Promotional from "../../../components/Promotional/Promotional";
import PromotionTypesEnum from "../../../../enums/PromotionTypesEnum";
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { mobileMenuToggleAction } from "../../../../redux/slices/globalSlice";

interface IMenuItem extends NavMenuItemModel {
    index: Readonly<number>,
    activeIndex: Readonly<number> | Readonly<null>,
    setActiveIndex?: { (index: number | null): void },
    backClick?: {(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void}
    callToAction?: SmartLinkModel | null
}

export default function SubMenu (props : IMenuItem){
    const {links, index, activeIndex, titleLink, backClick, callToAction, promoContent} = props;
    const isVisible = activeIndex === index;
    const itemId = `mobileSubMenu${index}`;
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(mobileMenuToggleAction(null));
    }
    return (!(links && links.length && isVisible) ? <Fragment/> : (<ul className={`${activeIndex === index ? 'active' : ''}`}>
            <li className="header">
                <h2>{titleLink?.defaultLinkText}</h2>
                <SmartLink {...{...titleLink, onClick:backClick, className:'back'}}/>
            </li>
            {links.map((link, index) => (<li key={index} id={itemId}>
                <SmartLink {...{...link, className:`title ${link?.url ? '' : 'disabled'}`, onClick}}>
                    {link.linkText || link.defaultLinkText}
                </SmartLink>
            </li>))}
            <li className="cta">
                {callToAction && <div>
                    <SmartLink {...{...callToAction, type:LinkTypeEnum.PrimaryButton}}/>
                </div>}
            </li>
            <li className="promo-wrapper">
                {promoContent && <Promotional content={promoContent} type={PromotionTypesEnum.PromoMobileMenu} className={"menu_column_promo"}/>}
            </li>
        </ul>)
    );
}