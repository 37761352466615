
import Logo from "../Logo/Logo";
import SmartLinkModel from "../../../../models/SmartLinkModel";
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";

interface IOwnProps{
    mobileCTA?: SmartLinkModel | null,
    logo: SmartLinkModel | null | undefined,
    submenuStatusClassName: string,
    onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined,
    isLoginModalVisible: boolean,
}
const showLogin = process.env.REACT_APP_HIDE_LOGIN?.valueOf() === 'true' ? false : true;

export default function MenuBar(props: IOwnProps) {
    let {mobileCTA, submenuStatusClassName, onClick, logo } = props;
    return (
        <div className="container">
            <div className="row align-items-center">
                <Logo {...logo}/>
                <div className="col-8 col-lg-9 col-xl-9 d-flex align-items-center justify-content-end">
                    {showLogin && <SmartLink className="mobile-login" {...mobileCTA}/>}
                    <nav className="mobile-menu">
                        <div id="navicon" className={`${submenuStatusClassName}`} onClick={onClick} tabIndex={0}>
                            <span/><span/><span/><span/>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};