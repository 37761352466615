import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
    isModalOpen: false,
    modalType: "Apply",
    dialogTitle: ""
}
interface IModal{
    modalType?: string;
    dialogTitle?: string | null;
    body?: any;
    header?: any;
    subHeader?: any;
    image?: any;
    altText?: string | null;
    link?: any;
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
        openModal: (previousState:any, action: PayloadAction<IModal>) => {
            return {
                ...previousState,
                isModalOpen: true,
                ...action.payload
            };
        },
        closeModal: (previousState:any, action: PayloadAction<null>) => {
            return {
                ...previousState,
                isModalOpen: false
            }; 
       },
  },
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer