
import "./ModalDialog.module.scss";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "../../../_assets/x-close-blue.svg"
import styles from "./ModalDialog.module.scss";

interface IModalDialogProps{
    header: JSX.Element | string | null | undefined,
    body: React.ReactNode | string | null | undefined,
    isOpen:boolean,
    onRequestClose:Function,
    className?:String
}

export default function FaqModal(props: IModalDialogProps) {
    let {header, body, isOpen, onRequestClose, className} = props;
    return (
    <Dialog 
      className={`${styles.dialog} ${className} modalDialog}`} 
      aria-labelledby="customized-dialog-title" 
      open={isOpen} 
      onClose={()=>onRequestClose()} 
      style={{padding:'10px'}}
      classes={{
        paper: "mf-modal faq-modal",
      }}
      >
        <DialogTitle className="dialog-title" classes={{root: "ps-0"}}>
            {header}
        </DialogTitle>
        <IconButton aria-label="close" onClick={()=>onRequestClose()} classes={{root: "closeButton p-0"}} className={styles.closeButton}>
                <img src={CloseIcon} alt="close" width={44} height={44}/>
        </IconButton>
        <DialogContent dividers className={'dialogBody'} classes={{dividers: "border-bottom-0"}}>
            {body}
        </DialogContent>
    </Dialog>);
}