
import IComponentProps from "../../../interfaces/IComponentProps";
import ContactPageModel from "../../../models/ContactPageModel";
import CategoryCardList from "../../components/CategoryCardList/CategoryCardList";
import Disclosures from "../../components/Disclosures/Disclosures";
import Anchors from "../../components/Anchors/Anchors";
import { PromoAccordions } from "../../components/PromoAccordions/PromoAccordions";
import { Accordions } from "../../components/Accordions/Accordions";
import AccordionsModel from '../../../models/AccordionsModel';
import { useDispatch } from "react-redux";
import { updateNavigationTheme } from "../../../redux/slices/pageContentSlice";
import DynamicForm from "../../components/Form/DynamicForm";

export default function ContactPage(props: ContactPageModel & IComponentProps) {
    let { htmlId, className, promo, form, anchors, navigationTheme, categoryCards, accordions = [], disclosures = [] } = props;
    const dispatch = useDispatch();
    dispatch(updateNavigationTheme(navigationTheme!))
    return (
        <div id={htmlId} className={className}>
            <section>
                <PromoAccordions {...promo}/>
            </section>
            <section>
                <DynamicForm {...form} />
            </section>
            <section>
                <Anchors {...anchors} />
            </section>
            <section>
                <CategoryCardList {...categoryCards}/>
            </section>
            <section>
                {accordions.map((accordion: AccordionsModel, key: number) => {
                    return <div key={key}>
                            <Accordions {...accordion} />
                        </div>
                })}
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}