import {Document} from "@contentful/rich-text-types";
import IClientServerModel from "../interfaces/IClientServerModel";
import CardModel from "./CardModel";
import ImageModel from "./ImageModel";
import CategoryCardTypeEnum from "../enums/CategoryCardTypeEnum";
import ImageSizeEnum from "../enums/ImageSizeEnum";

export default class CategoryCardListModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string | undefined = "";
    public readonly htmlId: string | undefined = "";
    public readonly header: string | null | undefined;
    public readonly pz?: string | undefined = "";
    public readonly subHeader?: Document | undefined;
    public readonly content: Array<CardModel> = [];
    public readonly background: ImageModel | undefined;
    public readonly categoryType: CategoryCardTypeEnum | undefined;
    public readonly imageSize: ImageSizeEnum | undefined;
    constructor(props: Readonly<CategoryCardListModel> | undefined | null){
        let {id = "", htmlId = "", imageSize, background, categoryType, content = [], header, subHeader} = props || {};
        this.id = id;
        this.htmlId = htmlId;
        this.pz = props?.pz;
        this.background = background ? new ImageModel(background) : undefined;
        this.content = content?.map(card => new CardModel(card));
        this.categoryType = categoryType ?? CategoryCardTypeEnum.Default;
        this.header = header;
        this.subHeader = subHeader;
        this.imageSize = imageSize;
    }
}
