import ProductDetailModel from "./ProductDetailModel";
import IClientServerModel from "../interfaces/IClientServerModel";

export default class ProductCarouselModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly id: string | undefined = "";
    public readonly htmlId?: string | undefined = "";
    public readonly header: string = "";
    public readonly content: ProductDetailModel[];
    constructor(props:Readonly<ProductCarouselModel> | null | undefined){
        const {id = "", htmlId = "", header = "", content = []} = props || {};
        this.id = id;
        this.htmlId = htmlId;
        this.header = header;
        this.content = content;
    }
}


