import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";

export default class AnchorsModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly id: string | undefined;
    public readonly anchorLinks?: Array<SmartLinkModel> | undefined = [];
    constructor(props: Readonly<AnchorsModel> | undefined | null){
        let {htmlId = "", id, anchorLinks} = props || {};
        anchorLinks = anchorLinks || [];
        this.htmlId = htmlId;
        this.id = id;
        this.anchorLinks = anchorLinks.map((contentfulLink: any) => new SmartLinkModel(contentfulLink));
    }
}

