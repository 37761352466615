
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import stylesBase from '../../components/Promotional/_HeroBase.module.scss';
import styling from './PromoAccordions.module.scss';
import PromoAccordionsModel from "../../../models/PromoAccordionsModel";
import ContentBlock from "../ContentBlock/ContentBlock";
import AccordionGroup from "../_shared/AccordionGroup/AccordionGroup";


export function PromoAccordions({contentBlock, accordionGroup, id, header}: PromoAccordionsModel & IComponentProps) {

    return (
        <div className={`${stylesBase["c-hero"]} ${stylesBase["c-hero-gradient"]}`} data-contentid={id}>
            <div className="container">
                <div className="row align-items-start">
                    <div className="col-12 col-lg-6">
                        <div className={`hero-content`}>
                        <h1>{header}</h1>
                            <ContentBlock {...contentBlock} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className={`${stylesBase["hero-accordions"]} ${styling["hero-accordions"]}`}>
                            <h2>{accordionGroup?.groupName}</h2>
                            <AccordionGroup {...accordionGroup} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withErrorBoundary(PromoAccordions);
