import React, {useState} from 'react';
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import FAQModel from '../../../models/FAQModel';
import "./FAQ.module.scss";
import SmartLink from "../_shared/SmartLink/SmartLink";
import {FaqItemContainer} from "./FaqItemContainer";
import { connect } from 'react-redux';
import IResponsive from '../../../interfaces/IResponsive';
import { RootState } from '../../../redux/store';
import { globalContentDuck_IReducerStateModel } from '../../../redux/slices/globalSlice';

export const mapStateToProps = (state: RootState): IResponsive => {
    const global: globalContentDuck_IReducerStateModel = state.global || {};
    const {windowWidth} = global;
    return {
        windowWidth
    }
}

export function FAQRender(props: (FAQModel & IComponentProps & IResponsive)){
    let {content, htmlId, header, subHeader, link, className, windowWidth, id} = props;
    const [isFaqListExpanded, setIsFaqListExpanded] = useState(false);
    const toggleFaqListExpansion = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>)=> {
        e.preventDefault();
        setIsFaqListExpanded(!isFaqListExpanded);
    }

    let faqExpansionLimit = 4;
    let hasFaqData = (content || []).length > 0;
    let faqList = isFaqListExpanded ? content || [] : (content || []).slice(0,faqExpansionLimit);

    return (!hasFaqData ? <></> : <div id={htmlId} className={`layout faqs ${className}`} data-contentid={id}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-11">
                    <div className="faqs-container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10">
                                <div className="intro text-center">
                                    <h2>{!link ? header : <SmartLink {...{...link, linkText: header}} />}</h2>
                                    <p>{subHeader}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row faqs-items">
                            {faqList.map((item, index)=> <FaqItemContainer {...{...item, index, key: index, windowWidth}} />)}
                        </div>
                        {((content || []).length > faqExpansionLimit) &&
                            <div className="row">
                                <div className="col-12 text-center">
                                    <SmartLink {...{linkText: isFaqListExpanded ? 'View Less' : 'View More', onClick:toggleFaqListExpansion, className:'load-btn collapsed' } } />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
export default connect(mapStateToProps,) (withErrorBoundary(FAQRender));