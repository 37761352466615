import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import BannerAlertModel from "../../../../models/BannerAlertModel";
import "./BannerAlerts.module.scss";
import { useDispatch } from "react-redux";
import { Alert } from "./Alert";
import { useAlertRequestQuery } from "../../../../redux/_shared/helpers/vystarApi";
import { hideAlertById } from "../../../../redux/slices/globalSlice";


interface IAlertBanner{
    overriddenIds: Array<string>
}

export function BannerAlertsRender(props: IAlertBanner & IComponentProps) {
    let { overriddenIds } = props || {};

    const dispatch = useDispatch();

    let alerts: BannerAlertModel[] = [];
    const alertAPI = useAlertRequestQuery(null);
    if(alertAPI.isSuccess && alertAPI.status === "fulfilled"){
        alerts = alertAPI.data;
    }

    const hasAlerts = !!alerts.length;

    let filteredAlerts: Array<BannerAlertModel> = alerts.filter(a => !overriddenIds?.includes(a.id || ""))

    return (hasAlerts ? <div id="alerts" role={"list"}>
            {filteredAlerts.map((alert:BannerAlertModel) => 
                <Alert 
                    {...alert} 
                    key={alert.id} 
                    onClose={()=>{
                        if(alert.id){
                            dispatch(hideAlertById(alert.id));
                        }
                    }}
                />)}
        </div> : <></>);
};


BannerAlertsRender.displayName = "BannerAlerts";
export default withErrorBoundary(BannerAlertsRender);