import React, {useState} from 'react';
import {Link as ReactLink} from "react-router-dom";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import SmartLinkModel, { clickEvent } from "../../../../models/SmartLinkModel";
import styles from "./SmartLink.module.scss"
import "./SmartLink.module.scss"
import IContainerComponentProps from "../../../../interfaces/IContainerComponentProps";
import LinkDataTypeEnum from "../../../../enums/LinkDataTypeEnum";
import LinkTargetValueEnum from "../../../../enums/LinkTargetValueEnum";
import ExternalModal from "../Dialogs/ExternalModal";
import generatePublicPrivateClassNames from '../_helpers/generatePublicPrivateClassNames';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../redux/slices/modalSlice';

function SmartLink(props: SmartLinkModel & IContainerComponentProps ){
    
    const {icon, children, onMouseOver, target = LinkTargetValueEnum.Default, tabIndex,
        dataType, linkText, defaultLinkText, type, accessibilityDescription,
        htmlId, className, showRedirectModal, externalLink, dataCTA} = props || {};

    const dispatch = useDispatch();

    let { onClick } = props || {};
    let url:string = props?.url ?? "";
    if (url.includes("modal:")){
        const modalType = url.split(":")[1];
        url="#";
        onClick = (e: clickEvent) => {
            e.preventDefault();
            dispatch(openModal({modalType}));
        };
    }

    let urlType = "";
    const isFullUrl = (url || "").startsWith("https://" || "https://" || "ftp://" || "ftps://");
    if(isFullUrl) {
        urlType = LinkDataTypeEnum.FullURL;
    }

    if ((showRedirectModal || externalLink) && urlType === LinkDataTypeEnum.FullURL) {
        onClick = (e: clickEvent) => {
            e.preventDefault();
            setIsRedirectModalVisible(true);
        };
    }

    const {imageURL} = icon || {};
    const displayText = linkText || defaultLinkText || "";
    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    let linkClassName = "";
    let isLinkTextVisible = true;
    switch (type) {
        case LinkTypeEnum.PrimaryButton:
            linkClassName = generatePublicPrivateClassNames(["smartlink_button", "smartlink_buttonprimary"], styles);
            break;
        case LinkTypeEnum.SecondaryButton:
            linkClassName = generatePublicPrivateClassNames(["smartlink_button", "smartlink_buttonsecondary"], styles);
            break;
        case LinkTypeEnum.Icon:
            linkClassName = generatePublicPrivateClassNames(["smartlink_default"], styles);
            isLinkTextVisible = false;
            break;
        case LinkTypeEnum.Piped:
            linkClassName = generatePublicPrivateClassNames(["smartlink_default"], styles);
            break;
        case LinkTypeEnum.Default:
            linkClassName = generatePublicPrivateClassNames(["smartlink_default"], styles);
    }


    let linkDataTypeClassName = "";
    switch (dataType) {
        case LinkDataTypeEnum.AnchorLink:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_anchorlink"], styles);
            break;
        case LinkDataTypeEnum.ModalLink:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_modallink"], styles);
            break;
        case LinkDataTypeEnum.FullURL:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_fullurl"], styles);
            break;
        case LinkDataTypeEnum.RelativeURL:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_relativeurl"], styles);
            break;
        case LinkDataTypeEnum.Email:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_email"], styles);
            break;
        case LinkDataTypeEnum.Phone:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_phone"], styles);
            break;
        case LinkDataTypeEnum.Scripted:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_scripted"], styles);
            break;
        default:
            linkDataTypeClassName = generatePublicPrivateClassNames(["smartlink_unspecified"], styles);
    }


    let displayContent =  <>
        {imageURL && imageURL && <img alt={""} src={imageURL}/>}
        {isLinkTextVisible && displayText}
    </>;
    let sharedProps = {
        id:htmlId,
        onMouseOver,
        className: `${generatePublicPrivateClassNames(["smartlink"], styles)} ${dataCTA ? dataCTA: ""}  ${linkClassName} ${linkDataTypeClassName} ${className ?? ""}`.replace(/  +/g, " "),
        title:accessibilityDescription,
        onClick,
        target: target?.toString(),
        tabIndex
    }
    if (dataType === LinkDataTypeEnum.RelativeURL || dataType === LinkDataTypeEnum.AnchorLink) {
        if (dataType === LinkDataTypeEnum.RelativeURL) {
            url = url.startsWith('/') ? url : `/${url}`
        }
        let isAnchor = dataType === LinkDataTypeEnum.AnchorLink;
        const onAnchorClick = isAnchor ? (e:React.MouseEvent<HTMLAnchorElement>)=>{
            e.preventDefault();
            const targetId = url.split("#")[1]
            const element = document.getElementById(targetId);
            if(element){
                const y = element.getBoundingClientRect().top + window.scrollY - 140;  // about 60 in padding and 80 in anchor height to compensate for
                window.scrollTo({top: y, behavior: 'smooth'});
            }
            onClick && onClick(e);
        } : undefined;
        return (
            !!(displayText || imageURL || children) ?
                <ReactLink {...{...sharedProps}} onClick={onAnchorClick || sharedProps.onClick} to={url || ""}>
                    {!!children ? <>{children}</> : displayContent}
                </ReactLink> : <></>
        );
    }
    else{
        return (
            !!(displayText || imageURL || children) ?
                <>
                    <a {...{...sharedProps}} href={url || ""}>
                        {!!children ? <>{children}</> : displayContent}
                    </a>
                    {isRedirectModalVisible &&
                        <ExternalModal className={"smartLinkModal"} isOpen={isRedirectModalVisible}
                           header={"You are leaving vystarcu.org... "}
                           body={"By accessing the noted link you will be leaving VyStar Credit Union's website and entering a website hosted by another party. Although VyStar Credit Union has approved this as a reliable partner site, please be advised that you will no longer be subject to, or under the protection of, the privacy and security policies of the VyStar Credit Union website. We encourage you to read and evaluate the privacy and security policies on the site you are entering, which may be different than those of VyStar Credit Union."}
                           onRequestConfirmation={(e:any)=>{
                               if(sharedProps.target === LinkTargetValueEnum.CurrentTab) {
                                   window.location.href = url;
                               }
                               else{
                                   window.open(url);
                               }
                               setIsRedirectModalVisible(false);
                           }}
                           onRequestClose={()=>{
                               setIsRedirectModalVisible(false);
                           }}
                        />
                    }
                </>: <></>);
    }
}
export default SmartLink;


