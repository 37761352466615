
export default class SitemapItemModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly url?: string | undefined;
    public readonly sitemapCategory?: string | undefined;
    public readonly sitemapName?: string | undefined;

    constructor(props: Readonly<SitemapItemModel> | undefined | null){
        const {url, sitemapCategory, sitemapName } = props || {};
        this.url = url;
        this.sitemapCategory = sitemapCategory;
        this.sitemapName = sitemapName;
    }
}
