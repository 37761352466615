
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import RichContentModel from '../../../models/RichContentModel';
import Share from '../Share/Share';
import SideBar from '../SideBar/SideBar';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentfulReactRenderOptions from '../../_shared/ContentfulReactRenderOptions';
import styleBase from "./RichContent.module.scss";

export function RichContentRender(props: (RichContentModel & IComponentProps)){
    let { body, sidebarLinkGroup, contentTitle } = props;

    return (              
    <div className={`layout padding-top-190 ${styleBase['content-block']} ${styleBase['generic']} ${styleBase['simplified']}`}>
        <article>
          <div className={"container"} >
              <div className="row mx-auto">
                  <div className="col-lg-8">
                        <h1>{contentTitle}</h1>
                        {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                  </div>
                  <div className="col-lg-3 offset-lg-1 d-none d-lg-block">
                      <Share />
                      <SideBar {...sidebarLinkGroup} />
                  </div>
              </div>
          </div>
      </article>
  </div>);
}
export default withErrorBoundary(RichContentRender);