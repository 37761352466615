
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import LandingPageModel from "../../../models/LandingPageModel";
import CategoryCardList from '../../components/CategoryCardList/CategoryCardList';
import TallTabs from "../../components/TallTabs/TallTabs";
import Disclosures from '../../components/Disclosures/Disclosures';

export default function LandingPage(props: LandingPageModel & IComponentProps){
    let { htmlId, className, hero, category, tallTabs, disclosures} = props;
    let { background } = category ?? {}
    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero}/>
            </section>
            <section>
                <TallTabs {...tallTabs}/>
            </section>
            <section style={{backgroundImage: `url(${background?.imageURL})`}}>
                <CategoryCardList {...category} />
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
