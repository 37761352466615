
import IComponentProps from "../../../../interfaces/IComponentProps";
import PromoSimpleBase from "../PromoSimpleBase/PromoSimpleBase";
import PromotionModel from "../../../../models/PromotionModel";
import styles from './PromoSecondary.module.scss';
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";
import CalculateNavigationTheme from "../../../_shared/helpers/CalculateNavigationTheme";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateNavigationTheme } from "../../../../redux/slices/pageContentSlice";

export default function PromoSecondary(props: PromotionModel & IComponentProps)  {
    let {className = "", theme, primary, ...otherProps} = props;

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    const themeClassName = (theme === ThemeTypeEnum.Dark) ? styles.dark : styles.light;
    props = {...otherProps, primary, theme, className: `${className} ${styles['c-promo-secondary']} ${themeClassName}`};
    return <PromoSimpleBase {...props}></PromoSimpleBase>;
}