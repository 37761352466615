
import NavMenuItemModel from "../../../../models/NavMenuItemModel";
import SubMenu from "./SubMenu";
import SmartLink from "../../../components/_shared/SmartLink/SmartLink";
import LinkTypeEnum from "../../../../enums/LinkTypeEnum";
import { Fragment } from "react";

interface IMenuItem extends NavMenuItemModel{
    index: Readonly<number>,
    activeIndex: Readonly<number> | Readonly<null>,
    setActiveIndex?: { (index: number | null): void },
    indexCount: Readonly<number>,
}

export default function MenuItem (props : IMenuItem){
    const {index, activeIndex, label, links, promoContent, callToAction,
        titleLink, setActiveIndex = (index: number | null) => undefined} = props || {};
    const backClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> )=>{
        setActiveIndex(null);
        event.preventDefault();
    }


    const isActive = (index === activeIndex);
    const toggleOn = () => setActiveIndex(index);
    const toggleOff = () => setActiveIndex(null);

    const hasSubmenu = !!links && links?.length > 0;
    const isCTAButton = !hasSubmenu && !titleLink;
    const onClick = (e:React.UIEvent)=>{
        if(hasSubmenu){
            e.preventDefault();
            if(index !== activeIndex){
                toggleOn();
            }
            else if(index === activeIndex){
                toggleOff();
            }
        }
    }
    let url = hasSubmenu ? '' : (titleLink?.url || callToAction?.url || '');
    let linkText = label || (titleLink?.url && titleLink?.linkText) || (callToAction?.url && callToAction?.linkText);
    let className = `${hasSubmenu ? 'hasSubMenu' : ''}`;
    let type = isCTAButton ? LinkTypeEnum.PrimaryButton : LinkTypeEnum.Default;
    return (
        <Fragment>
            <li className={`menu_item ${isActive ? 'focus' : ''}`} >
                <SmartLink {...{onClick, className, url, type }} aria-controls="resources-menu" aria-haspopup="true">
                    {linkText || ""}
                </SmartLink>
                {hasSubmenu &&
                    <SubMenu {...{isActive, label, index, links, activeIndex, promoContent, titleLink, callToAction, backClick}} />
                }
            </li>
        </Fragment>
    );
}

