import ContentTypesEnum from "../enums/ContentTypesEnum";
import IClientServerModel from "../interfaces/IClientServerModel";
import AccordionGroupModel from "./AccordionGroupModel";
import ContentBlockModel from "./ContentBlockModel";
import SmartLinkModel from "./SmartLinkModel";

export default class AccordionsModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly id: string | undefined;
    public readonly header: string | undefined;
    public readonly topSectionText: string | undefined;
    public readonly bottomSectionText: string | undefined;
    public readonly contentType: ContentTypesEnum | undefined;
    public readonly contentBlock?: ContentBlockModel;
    public readonly accordionGroup?: AccordionGroupModel | undefined;
    public readonly linkLocation?: SmartLinkModel | undefined;
    public readonly backgroundColor?: string | undefined;

    constructor(props: Readonly<AccordionsModel> | undefined | null){
        const {htmlId = "", contentType, id, header, accordionGroup, backgroundColor,
            bottomSectionText, topSectionText, contentBlock, linkLocation} = props || {};

        this.htmlId = htmlId;
        this.id = id;
        this.header = header;
        this.contentType = contentType;
        this.backgroundColor = backgroundColor;
        this.bottomSectionText = bottomSectionText;
        this.topSectionText = topSectionText;
        this.accordionGroup = new AccordionGroupModel(accordionGroup);
        this.contentBlock = contentBlock;
        this.linkLocation = linkLocation;
    }
}