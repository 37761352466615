
import ColumnDefinitionModel from "../../../../models/ColumnDefinitionModel";
import { ITableItem } from "../../../../models/CompareTableDetailModel";
import IRowData from "./IRowData";

//getTableRows will return an array of rows, which is an array of IRowData column data
export default function getTableRows(columnDefinition: ColumnDefinitionModel[] | null | undefined, tableItems: ITableItem[] | null | undefined) {
    let rows = (columnDefinition?.map((columnDefinition: ColumnDefinitionModel) => {
        let columns = tableItems?.map((row: ITableItem) => {
            const fieldKey = columnDefinition?.fieldKey;
            return row[fieldKey]?.value;
        }) || [];

        return {
            label: columnDefinition?.label,
            columns
        } as IRowData;

    }) || []);
    return rows;
}