import { useEffect, useRef } from "react";
import stylesBase from "../../Locator/Locator.module.scss";

function LocationItem(props: any){

    const { drivingKey, drivingInstruction } = props;

    let divRef = useRef<HTMLDivElement>(null); 

    useEffect(()=>{
        if(divRef?.current){
            divRef.current.innerHTML = drivingInstruction["instructions"];
        }
    },[divRef, drivingInstruction]);

    return (
        <div className={`${stylesBase["google-item"]}`} key={drivingKey.toString()}>
            <div className={`${stylesBase['google-instruction']}`} ref={divRef} />
            <div className={`${stylesBase['google-distance']}`}>
                {drivingInstruction["distance"].text}
            </div>
            <div className={`${stylesBase['google-duration']}`}>
                {drivingInstruction["duration"].text}
            </div>
        </div>
    )
}
export default (LocationItem);