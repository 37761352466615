import { AdvancedMarker, InfoWindow } from "@vis.gl/react-google-maps";
import { ILocation, ILocatorMarkers } from "./LocationInterfaces";
import stylesBase from "./Locator.module.scss";
import vystarBranch from "../../_assets/branch-marker.png"
import vystarATM from "../../_assets/atm-vcu-marker.png";
import surchargeFreeATM from "../../_assets/atm-surcharge-marker.png";
import { HtmlDiv } from "./HtmlDiv";

const chooseIcon = (isBranch: boolean, isDepositAtm: boolean, isSurchargeFree: boolean) =>{
    return isBranch ? vystarBranch : isDepositAtm ? vystarATM : isSurchargeFree ? surchargeFreeATM : undefined;
}

export const openTodayHeading = (today: number) => {
    var gsDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if(today===0){
        return "Closed on Sunday's";
    }
    return "Open Today: " + gsDayNames[today];
}
export const openTodayText = (today: number, monday: string, tuesday: string, wednesday: string, thursday: string, friday: string, saturday: string, sunday: string) => {
    switch (today){
        case 1:
            return monday;
        case 2:
            return tuesday;
        case 3:
            return wednesday;
        case 4:
            return thursday;
        case 5:
            return friday;
        case 6:
            return saturday;
        default:
            return sunday;
    }
}
export const isOpenToday = (locationType: string, dayOfWeek: number) => {
    return locationType === "BRANCH" && dayOfWeek !== 0;
}

export function LocatorMarkers(props: ILocatorMarkers){

    const { locations = [], activeIndex, dayOfWeek, updateLocation, locationIndexUpdate, map, valuesAdd } = props;

    return (locations.length ? (<div>{locations?.map((location: ILocation, locationIndex: number)=>{

        const { latitude, longitude, isBranch, isDepositAtm, isSurchargeFree, name, address, city, state, zipcode, locationType,
            monday, tuesday, wednesday, thursday, friday, saturday, sunday, driveThruHours, retailOutlet} = location;

        const showInfoWindow = activeIndex === locationIndex;
        const openTodayString = openTodayText(dayOfWeek, monday, tuesday, wednesday, thursday, friday, saturday, sunday);
        const openTodayHeader = openTodayHeading(dayOfWeek);
        const isOpen = isOpenToday(locationType, dayOfWeek);
        const hasPosition = !!(latitude && longitude);

        return (<div key={locationIndex}>
                    { hasPosition && <AdvancedMarker
                        position={{lat: latitude, lng: longitude}}
                        onClick={(e: any) => {
                            const scrollList = document.getElementById("locations-list");
                            const scrollItem = document.getElementById(`location-${locationIndex}`);
                            if(scrollList && scrollItem){
                                scrollList.scrollTo({top: scrollItem.offsetTop, behavior: 'smooth'});
                            }
                            updateLocation(location);
                            locationIndexUpdate(locationIndex);
                            const payload = {
                                isUpdateFromListOrMarker: true
                            }
                            valuesAdd(payload);
                            map?.panTo({lat: latitude, lng: longitude});
                        }}
                    >
                        <img src={chooseIcon(isBranch, isDepositAtm, isSurchargeFree)} alt="VyStar Icon"/>
                    </AdvancedMarker>}
                    {showInfoWindow &&
                        <InfoWindow
                            position={{ lat: latitude, lng: longitude }}
                            pixelOffset={[0,-26]}
                        >
                        <div className="gm-style-iw-d" style={{ maxHeight: 205 }}>
                            <div>
                                <div>
                                    <strong className={`${stylesBase["bolder"]}`}>{name}</strong>
                                </div>
                                {retailOutlet && <HtmlDiv className={`${stylesBase["under-maint"]}`} innerHTML={'* ' + retailOutlet} />}
                                <div>
                                    <div>
                                        <span className={`${stylesBase["get-directions-address"]}`}>{address}</span>
                                    </div>
                                    <div>
                                        <span className={`${stylesBase["get-directions-city"]}`}>{city} </span>,
                                        <span className={`${stylesBase["get-directions-state"]} ms-1`}>{state}</span>
                                        <span className={`${stylesBase["get-directions-zipcode"]}`}>{zipcode}</span>
                                    </div>
                                    { isOpen && <div>
                                        <br />
                                        <span className={`${stylesBase["get-directions-today"]}`}>
                                            <strong className={`${stylesBase["bolder"]}`}>
                                                {openTodayHeader}
                                                <br />
                                            </strong>{openTodayString}
                                        </span>
                                        <br />
                                    </div>}
                                    { !!driveThruHours && <div>
                                        <br />
                                        <span className="get-directions-additionalinfo1">
                                            <b className={`${stylesBase["bolder"]}`}>
                                                Drive-Thru Hours
                                            </b><br />
                                            <HtmlDiv innerHTML={driveThruHours} />
                                        </span>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        </InfoWindow>
                    }
                </div>)})}</div>)
    :<></>)
}