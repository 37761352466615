
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import AccordionsModel from "../../../models/AccordionsModel";
import styles from "./Accordions.module.scss";
import AccordionGroup from "../_shared/AccordionGroup/AccordionGroup";
import ContentBlock  from "../ContentBlock/ContentBlock";
import SmartLink from "../_shared/SmartLink/SmartLink";

export function Accordions({accordionGroup, header, topSectionText, bottomSectionText, contentBlock, linkLocation, backgroundColor, htmlId, id}: AccordionsModel & IComponentProps) {
    const smartLinkTop = linkLocation ?{...linkLocation, defaultLinkText:topSectionText} : undefined;
    const cta = linkLocation ? {...linkLocation, defaultLinkText:bottomSectionText} : undefined;
    const accordionGroupModel = {...accordionGroup, cta}

    return (
        <div className={`layout ${styles['two-thirds']} ${"bg-"+backgroundColor}`} id={htmlId} data-contentid={id}>
            <div className="container">
                {/* <!-- Intro --> */}
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="intro text-center">
                            <h2> { header }</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className={`${styles['content-left']}`}>
                            <ContentBlock {...contentBlock} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-7">
                        <div className={`${styles['content-right']}`}>
                            {smartLinkTop && <h3>
                                <SmartLink {...smartLinkTop} />
                            </h3>}
                            <AccordionGroup {...accordionGroupModel} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withErrorBoundary(Accordions);
