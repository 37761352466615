import { Personalize } from "@uniformdev/context-react";
import Promotional from "../Promotional/Promotional";
import { MapPersonalizationArray } from "../../_shared/helpers/MapPersonalizationArray";

//@ts-ignore
export const PersonalizedPromo = ({variatians}) => {
  // This code block handles the data for the Custom Plugin for 
  // Google Analytics for Promos
  const variations = (variatians as any[]).map(entry => {
    if(entry.pz) {
      entry.criteria = MapPersonalizationArray(entry.pz.crit);
    }

    entry.id = JSON.stringify({
      original: entry.id,
      entry: {
        contentType: entry.htmlId,
        entryId: entry.id,
        name: entry.content.header,
      },
      criteria: entry.criteria,
    });

    return entry;
  })

  // This code block handles the personalization for Promos
  return (
    <Personalize
      variations={variations}
      name="Personalized Promo"
      component={Promotional}
    />
  );
}