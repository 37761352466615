import { useEffect, useState } from "react";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import BlogNewsEventModel from "../../../models/BlogNewsEventModel";
import styling from "./BlogNewsEvents.module.scss";
import BlogNewsEventsCard from "./BlogNewsEventsCard";
import BlogNewsEventsItemModel from "../../../models/BlogNewsEventsItemModel";
import HorizontalScroll from "../_shared/HorizontalScroll/HorizontalScroll";
import SmartLink from "../_shared/SmartLink/SmartLink";
import LinkTypeEnum from "../../../enums/LinkTypeEnum";
import LinkDataTypeEnum from "../../../enums/LinkDataTypeEnum";
import {connect} from "react-redux";
import { clickEvent } from "../../../models/SmartLinkModel";
import setHidden from "../../_shared/helpers/setHidden";
import { useTopicByCategoryRequestMutation } from "../../../redux/_shared/helpers/vystarApi";
import { RootState } from "../../../redux/store";
import { addValues } from "../../../redux/slices/pageContentSlice";

interface IOwnProps{
    allType1Count: number, 
    allType2Count: number, 
    allType3Count: number,
    type1Max: number,
    type2Max: number,
    type3Max: number,
    type1Count: number,
    type2Count: number,
    type3Count: number,
    blogNewsEventsContainer: BlogNewsEventsItemModel[][],
    contentType: string,
    defaultTab: string,
    shouldUpdate: boolean
}

interface IMapDispatchProps{
    addBlogNewsEvents: Function,
}

export const mapStateToProps = (state: RootState, _: any): any => {
    const { allType1Count, allType2Count, allType3Count, type1Max, type2Max, type3Max, blogNewsEventsContainer, shouldUpdate,
        type1Count, type2Count, type3Count } = state.pageContent;
    return { allType1Count, allType2Count, allType3Count, type1Max, type2Max, type3Max, blogNewsEventsContainer, shouldUpdate, type1Count, type2Count, type3Count };
};

export const mapDispatchToProps = (dispatch: any, _: any):IMapDispatchProps => {
    return {
        addBlogNewsEvents: (payload: any)=>{
            dispatch(addValues(payload));
        }    
    };
};

let defaultTabLabels = ["All", "News", "Events", "Blog posts"];
let alternateTabLabels = ["All", "Stories", "News", "Videos"];

// sort events by start date for better UX
const sortDate = (items: BlogNewsEventsItemModel[]) => [...items].sort((a:any, b:any) => {
    return new Date(a.startDate ?? a.publicationDate ?? 0) > new Date(b.startDate ?? b.publicationDate ?? 0) ? -1 : 1
});

const getInitialFilter = (defaultTab: string, isTrad: boolean) => {
    const stringIndex = sessionStorage.getItem("HorizontalScroll");
    const currentIndex = stringIndex && parseInt(stringIndex);
    if(currentIndex)return currentIndex;
    if(defaultTab === "All") return 0;
    if(isTrad){
        if(defaultTab === "topicNews") return 1;
        if(defaultTab === "topicEvent") return 2;
        if(defaultTab === "topicBlog") return 3;
    }else{
        if(defaultTab === "topicBlog-Stories") return 1;
        if(defaultTab === "topicNews") return 2;
        if(defaultTab === "topicBlog-Videos") return 3;
    }
    return 0;
}
const getApiParams = (isTrad: boolean, selectedIndex: number, allType1Count: number, allType2Count: number, 
    allType3Count: number, type1Count: number, type2Count: number, type3Count: number, type1Max: number, type2Max: number, type3Max: number) => {

        const isAllTab = selectedIndex === 0;
        const take = isAllTab ? 2 : 6;
        // there's an algo to handle running out of a type, we take less of that type, in our case 1 instead of 2
        // if we get to 0 items left on the server, there's an algo to remove the request
        const leftOfType1 = type1Max - allType1Count;
        const leftOfType2 = type2Max - allType2Count;
        const leftOfType3 = type3Max - allType3Count;
        let takeLessOfOne: number | undefined = undefined;
        let takeLessOfTwo: number | undefined = undefined;
        let takeLessOfThree: number | undefined = undefined;
        if(selectedIndex === 0){
            if(leftOfType1 < take){
                takeLessOfOne = leftOfType1;
            }
            if(leftOfType2 < take){
                takeLessOfTwo = leftOfType2;
            }
            if(leftOfType3 < take){
                takeLessOfThree = leftOfType3;
            }
        }

        let requestArray = [
            { 
                contentType: isTrad ? "topicNews": "topicBlog",
                category: isTrad ? null : "Stories",
                tab: 1,
                skip: isAllTab ? allType1Count : type1Count,
                take: takeLessOfOne || take
            },
            {   
                contentType: isTrad ? "topicEvent": "topicNews",
                category: null,
                tab: 2,
                skip: isAllTab ? allType2Count : type2Count,
                take: takeLessOfTwo || take
            },
            {
                contentType: "topicBlog",
                category: isTrad ? null : "Videos",
                tab: 3,
                skip: isAllTab ? allType3Count : type3Count,
                take: takeLessOfThree || take
            }
        ];
        if(selectedIndex === 0){
            if(takeLessOfOne === 0){
                // if you run out of the first item, request more of the last 2
                requestArray.shift();
                requestArray[0].take += 1;
                requestArray[1].take += 1;
                if(takeLessOfTwo === 0){
                    requestArray.shift();
                }
            }
            if(takeLessOfThree === 0){
                // pop off last type when empty
                requestArray.pop();
                if(requestArray[0]?.tab === 2){
                    requestArray[0].take = leftOfType2;
                }
                if(requestArray[0]?.tab === 3){
                    requestArray[0].take = leftOfType3;
                }
            }
        }
        return selectedIndex === 0 ? requestArray : [requestArray[selectedIndex-1]];
}
const shouldReadMore = (index: number, allType1Count: number, allType2Count: number, allType3Count: number, type1Max: number, type2Max: number, type3Max: number,
    type1Count: number, type2Count: number, type3Count: number) => {
    switch (index){
        case 0:
            return allType1Count < type1Max || allType2Count < type2Max || allType3Count < type3Max;
        case 1:
            return type1Count < type1Max;
        case 2:
            return  type2Count < type2Max;
        case 3:
            return type3Count < type3Max;
        default:
            return true;
    }
}
const defaultOrAlternateConfig = (config: string[])=>{
    let isTradSetup = true;
    config.forEach((configItem:string)=>{
        if(configItem.includes("topicBlog-Stories") || configItem.includes("topicBlog-Videos")){
            isTradSetup = false;
        }
    });
    return isTradSetup;
}

const BlogNewsEvents = (props: BlogNewsEventModel & IComponentProps & IMapDispatchProps & IOwnProps) => {
    
    let { contents = [], defaultTab, type1Max, type2Max, type3Max, addBlogNewsEvents, blogNewsEventsContainer, 
    allType1Count, allType2Count, allType3Count, type1Count, type2Count, type3Count, shouldUpdate } = props; 
    console.log("props", props);         
    const isTradSetup = defaultOrAlternateConfig(contents);
    const initialFilterBy = getInitialFilter(defaultTab, isTradSetup);  
    const [selectedIndex, setSelectedIndex] = useState(initialFilterBy);
    const tabLabels =  isTradSetup ? defaultTabLabels : alternateTabLabels;
    const apiParams = getApiParams(isTradSetup, selectedIndex, allType1Count, allType2Count, allType3Count,type1Count, type2Count, type3Count, type1Max, type2Max, type3Max);
    const [getTopic] = useTopicByCategoryRequestMutation();

    useEffect(()=>{

        let { type1Max, type2Max, type3Max, allType1Count, allType2Count, allType3Count, type1Count, type2Count, type3Count, shouldUpdate } = props;          

        if(shouldUpdate){
            getTopic(apiParams).then((resp: any)=>{
                resp.data.bneItems.forEach((bne:any)=>{
                    if(isTradSetup){
                        switch(selectedIndex){
                            case 0:
                                if(bne.contentType === "topicNews"){
                                    allType1Count += 1;
                                }else if(bne.contentType === "topicEvent"){
                                    allType2Count += 1;
                                }else if(bne.contentType === "topicBlog"){
                                    allType3Count += 1;
                                }
                                break;
                            case 1:
                                type1Count += 1;
                                break;
                            case 2:
                                type2Count += 1;
                                break;
                            case 3:
                                type3Count += 1;
                                break;
                            default:
                                break;
                        }
                    }else{
                        switch(selectedIndex){
                            case 0:
                                if(bne.type === "Stories"){
                                    allType1Count += 1;
                                }else if(bne.contentType === "topicNews"){
                                    allType2Count += 1;
                                }else if(bne.type === "Videos"){
                                    allType3Count += 1;
                                }
                                break;
                            case 1:
                                type1Count += 1;
                                break;
                            case 2:
                                type2Count += 1;
                                break;
                            case 3:
                                type3Count += 1;
                                break;
                            default:
                                break;
                        }  
                    }
                });
                let mutableContainer = [...blogNewsEventsContainer];
                mutableContainer[selectedIndex] = (mutableContainer[selectedIndex] ?? []).concat(resp.data.bneItems)
                const { typeOneCount, typeTwoCount, typeThreeCount } = resp.data.aggregateCount;
                if(typeOneCount){
                    type1Max = typeOneCount;
                }
                if(typeTwoCount){
                    type2Max = typeTwoCount;
                }
                if(typeThreeCount){
                    type3Max = typeThreeCount;
                }
                addBlogNewsEvents({ blogNewsEventsContainer: mutableContainer, type1Max, type2Max, type3Max, allType1Count, allType2Count, allType3Count,
                    type1Count, type2Count, type3Count });
            });
            addBlogNewsEvents({shouldUpdate: false});
        }
    },[ addBlogNewsEvents, isTradSetup, props, selectedIndex, shouldUpdate, blogNewsEventsContainer, getTopic, apiParams ]);

    return (
        <div className={`layout ${styling["social-content"]}`}>
            <div className={`${styling["social-filters"]}`}>
                <div className={`container ${styling["container"]}`}>
                    <div className="row">
                        <div className="col-12">
                            <HorizontalScroll className={`${styling["scrollable-horizontal"]} ${styling["scroller"]}`}>
                                <div className={`${styling["link-list"]}`}>
                                    <ul>
                                        {tabLabels.map((tabName:string, i: number)=>{
                                                return (<li key={i} className={`${i === selectedIndex && styling["active"]}`}>
                                                <SmartLink
                                                    defaultLinkText={tabName}
                                                    htmlId={`tab-${i + 1}`}
                                                    onClick={(e:clickEvent)=>{
                                                        e.preventDefault();
                                                        setSelectedIndex(i);
                                                        addBlogNewsEvents({shouldUpdate: true});
                                                        sessionStorage.setItem("HorizontalScroll", i.toString());
                                                    }}
                                                    className={`${styling["smartlink_default"]}`}
                                                />
                                            </li>
                                        );
                                        })}
                                    </ul>
                                </div>
                            </HorizontalScroll>
                        </div>
                    </div>
                </div>
            </div>
            {blogNewsEventsContainer.map((bneContainer: any, i: number) => {
                const bneSorted = sortDate(bneContainer);
                return (
                    <div 
                        className={`container ${setHidden(i, selectedIndex)}`}
                        key={i}
                    >
                        <div className="row">
                            {bneSorted.map((bneItem, key)=>{
                                return (<BlogNewsEventsCard key={key} { ...new BlogNewsEventsItemModel(bneItem)} />);
                            })}
                        </div>
                        {shouldReadMore(i,allType1Count, allType2Count, allType3Count, type1Max, type2Max, type3Max, type1Count, type2Count, type3Count) &&<div className="row load-more">
                            <div className="col-12 text-center">
                                <SmartLink 
                                    type={LinkTypeEnum.PrimaryButton} 
                                    dataType={LinkDataTypeEnum.Scripted} 
                                    defaultLinkText={"Load More"} 
                                    onClick={(e:clickEvent)=>{
                                        e.preventDefault();
                                        addBlogNewsEvents({shouldUpdate: true});
                                    }}
                                    />
                            </div>
                        </div>}
                </div>                                
            )})}
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps) (withErrorBoundary(BlogNewsEvents));