enum LinkTargetValueEnum{
    // @ts-ignore
    Default = "_self",
    // @ts-ignore
    CurrentTab = "_self",
    // @ts-ignore
    NewTab = "_blank",
}

export default LinkTargetValueEnum;
