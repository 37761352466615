
import IComponentProps from "../../../interfaces/IComponentProps";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import ProductStepwiseDetailModel from "../../../models/ProductStepwiseDetailModel";
import CardSimple from "../_shared/CardSimple/CardSimple";
import LinkList from "../_shared/LinkList/LinkList";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import ImageModel from "../../../models/ImageModel";
import styles from "./ProductStepwiseDetails.module.scss";

export function ProductStepwiseDetailsRender(props: ProductStepwiseDetailModel & IComponentProps)  {
    let {className = '', htmlId, content, showStepNumber, id} = props || {};
    let {steps, header, backgroundImage, subheader, callToActionButtons = []} = content || {};
    const {
        imageURL: backgroundImageURL,
        description: backgroundDescription
    } = backgroundImage || new ImageModel(null);

    const shouldShowBackground = !!backgroundImage;
    const backgroundStyle = shouldShowBackground ? {backgroundImage:`url(${backgroundImageURL})`} : {};

    return (<div aria-label={backgroundDescription} id={htmlId} className={`bg-blue bg-image layout ${className} ${styles['stepwise']}`} style={backgroundStyle} data-contentid={id}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                    <div className={`${styles["intro"]} intro text-center`}>
                        <h2>{header}</h2>
                        {subheader && documentToReactComponents(subheader, ContentfulReactRenderOptions)}
                    </div>
                </div>
            </div>
            <div className={`row justify-content-center ${styles['card-container']}`}>
                {steps && steps.map((step, index) => {
                    return <div className="column" key={index} >
                        <CardSimple stepNumber={index + 1} {...step} showStepNumber={showStepNumber} styleOverrides={styles} />
                    </div>
                })}
            </div>
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <LinkList linkClassName={null} links={callToActionButtons} />
                </div>
            </div>
        </div>
    </div>);
}

ProductStepwiseDetailsRender.displayName = "ProductStepwiseDetails";
export default withErrorBoundary(ProductStepwiseDetailsRender);