
import RadioGroup from "@mui/material/RadioGroup";
import IComponentProps from "../../../interfaces/IComponentProps";
import Checkbox from "../../Synapse/components/atoms/Checkbox";
import FormInput from "../../Synapse/components/atoms/FormInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";

// TO DO: ADD TO FIELD TYPE, ALL THREE LAYERS
// REMOVE SUB TYPE FROM CHECKBOX AND TEXT FIELD
export enum FieldType {
    AlphanumericTextField = "Alphanumeric Text Field",
    TextArea = "Text Area",
    PhoneNumber = "Phone Number",
    MemberNumber = "Member Number",
    EmailAddress = "Email Address",
    RadioButtons = "Radio Buttons",
    LeftCheckbox = "Left Checkbox",
    RightCheckbox = "Right Checkbox",
}
export interface IMapStateToProps {
    formValue: string
    formFields: object
    addRadioValue: Function
}
export interface IFormField{
    htmlId: string;
    name: string;
    fieldName: string;
    labelName: string;
    fieldType: FieldType;
    fieldRequired: boolean;
    handleChange: (value: string | boolean, name: string) => void;
    shouldReset: boolean;
    options: string[];
    formValue: any;
}


const firstNameMaxLength = 40;
const lastNameMaxLength = 40;
const phoneNumberMaxLength = 15;
const emailMaxLength = 40;

const maxLength = (fieldName: string) => {
    switch (fieldName){
        case "firstName":
            return firstNameMaxLength;
        case "lastName":
            return lastNameMaxLength;
        case "phoneNumber":
            return phoneNumberMaxLength;
        case "email":
            return emailMaxLength;
        default:
            return 1000;
    }
}

export default function FormField(props: (IFormField & IComponentProps & IMapStateToProps)){

    const {htmlId, labelName, fieldName, fieldType, fieldRequired, handleChange, shouldReset, options = [], formValue } = props;

    switch(fieldType){
        case "Radio Buttons":

            return (
                <div className="radio-buttons">
                    <FormLabel id={fieldName} className="form-label">{labelName}</FormLabel>
                    <RadioGroup
                        aria-labelledby={fieldName}
                        value={formValue || ""}
                        defaultValue=""
                        name={fieldName}                           
                        onChange={(e)=>{
                            handleChange(e.target.value, e.target.name);                          
                        }}
                    >
                        {options.map((option: string, key: number)=>{
                            return (
                                <FormControlLabel 
                                    key={key} 
                                    value={option} 
                                    control={<Radio />} 
                                    label={option} 
                                    className="text-dark"
                                />
                            )
                        })}
                    </RadioGroup>
                </div>
        );
        case "Left Checkbox":
            return (
                <Checkbox 
                    position="right"
                    value={formValue}
                    onChecked={(e)=>{
                        handleChange(e, fieldName)}
                    }
                >
                    {labelName}
                </Checkbox>
            )
        case "Right Checkbox":
            return (
                <Checkbox 
                    position="left"
                    value={formValue}
                    onChecked={(e)=>{
                        handleChange(e, fieldName)}
                    }
                >
                    {labelName}
                </Checkbox>
            )
        case "Text Area":
            return (
                <FormInput
                    id={htmlId}
                    isRequired={fieldRequired}
                    label={labelName}
                    maxLength={1000}
                    minLength={2}
                    name={labelName}
                    type={fieldType}
                    onChange={(e)=>handleChange( e, fieldName)}
                    shouldReset={shouldReset}       
                />
            )
        default:
            return (
                <FormInput
                    id={htmlId}
                    isRequired={fieldRequired}
                    label={labelName}
                    maxLength={40}
                    minLength={2}
                    name={labelName}
                    type={fieldType}
                    onChange={(e)=>handleChange( e, fieldName)}
                    shouldReset={shouldReset}       
                />
            )
    }
};
