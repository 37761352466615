import { Fragment, Component, ReactNode } from "react";
import withErrorBoundary from '../../../_shared/helpers/withErrorBoundary';
import {Document} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import SmartLink from "../SmartLink/SmartLink";
import SmartLinkModel from "../../../../models/SmartLinkModel";

export type ContentfulValueType = (
    typeof String |
    typeof Boolean |
    typeof Number |
    typeof Object |
    typeof BigInt |
    typeof Document
);

export type ReactOutput = (
    typeof Component |
    ReactNode |
    typeof Fragment
)

interface IContentValueProps{
    value: ContentfulValueType | null | undefined,
    defaultString: string
}

export function ContentValueRender({value,  defaultString = ""}:IContentValueProps): ReactOutput {
    let renderValue: Component | ReactNode = <>defaultString</>;// default, will be assigned to null or undefined
    // note: markdown isn't supported by this component at the moment comes in as a string;
    // Markdown could be supported in the future but will need to be encapsulated in an
    // object with value and type properties
    if(value === null || value === undefined) {
        return <>{defaultString}</>;
    }
    if(typeof(value) === "number" || typeof(value) === "string" || typeof(value) === 'bigint'){
        const textValue = (value as any)?.toString();
        return <>{textValue}</>;
    }
    else if(typeof(value) === "boolean") {
        const textValue = value ? "Yes" : "No";
        return <>{textValue?.toString()}</>; //per product owner... if other text is needed use string
    }
    else if(typeof(value) === "object"){
        let objectValue = value as any;
        if(objectValue?.nodeType === "document"){
            return documentToReactComponents(objectValue as Document, ContentfulReactRenderOptions)
        }
        else{//assuming a clientside data model, a type name, possibly containing .[subtype]
            let isObjectValue = typeof objectValue?.type === "string";
            let type = isObjectValue ? ((objectValue?.type ?? "")?.split('.') || [])[0] || "" : "";
            switch(type.toLowerCase()){
                case 'link': {
                    let componentProps = objectValue as SmartLinkModel;
                    return <SmartLink {...componentProps}/>;
                }
                default: {
                    return <>{defaultString}</>;
                }
            }
        }
    }
    return renderValue;
}

ContentValueRender.displayName = "ContentValue";
export default withErrorBoundary(ContentValueRender);


