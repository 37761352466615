export default function ApplyModalBody() {
    return (
        <div className={`mb-0 apply-modal-content`}>
            <div>
                <h3>Log in to Apply</h3>
                <p>
                    Get the loan process started in just a few simple steps. Log
                    in and find the “Apply for a Loan” in the menu to get
                    started.
                </p>
                <p>
                    If you haven't already done so,{" "}
                    <a href="https://online.vystarcu.org/#/identity/enroll.memberInformation">
                        enroll in Online or Mobile Banking
                    </a>
                </p>
                <div className="cta-btn">
                    <a
                        href="https://online.vystarcu.org/"
                        className="smartlink smartlink_button smartlink_buttonprimary"
                    >
                        Log In
                    </a>
                </div>
            </div>
            <div>
                <h3>Become a Member</h3>
                <p>
                    Join VyStar Credit Union to unlock benefits that help you
                    bank better. Once you join, applying for a loan is easy.
                </p>
                <p>
                    Explore VyStar{" "}
                    <a href="https://vystarcu.org/why-join/member-benefits">
                        Membership Benefits
                    </a>
                </p>
                <div className="cta-btn">
                    <a href="https://vystarcu.org/why-join/member-benefits"> </a>
                    <a
                        href="https://apply.vystarcu.org/"
                        className="smartlink smartlink_button smartlink_buttonprimary"
                    >
                        Join Now
                    </a>
                </div>
            </div>
        </div>
    );
}
