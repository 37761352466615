import React, {useState} from "react";
import withErrorBoundary from '../../../_shared/helpers/withErrorBoundary';
import styling from "../CompareTable.module.scss";
import SmartLink from "../../_shared/SmartLink/SmartLink";
import { ITableItem } from "../../../../models/CompareTableDetailModel";
import { IComparisonViewProps } from "../IComparisonViewProps";
import IRowData from "./IRowData";
import getTableRows from "./getTableRows";
import DynamicValue from "../../_shared/ContentValue/ContentValue";
import SmartLinkModel from "../../../../models/SmartLinkModel";

export function DesktopComparisonViewRender(props: IComparisonViewProps){
    let {compareTable, maxComparisons, defaultRowCount} = props;
    let { table } = compareTable;
    let { columnDefinition = [], tableItems, numberOfRows = defaultRowCount } = table ?? {};

    const [isExpanded, setIsExpanded] = useState(false);
    const columnCount = (tableItems?.length || 0) <= maxComparisons ? tableItems?.length : maxComparisons;

    const tableHeaders = tableItems?.map((row: ITableItem) => {
        return row.heading;
    });

    const allTableRows:Array<IRowData> = getTableRows(columnDefinition, tableItems);
    const bodyRows = allTableRows.filter(row => row?.label?.toLowerCase() !== 'cta');
    const footerRow = allTableRows.find(row => row?.label?.toLowerCase() === 'cta');

    return <div className="container d-none d-lg-block">
        <div className="row">
            <div className="col-12">
                <table className={`${styling["compare"]}`}>
                    <thead>
                    <tr>
                        <th></th>
                        {tableHeaders?.slice(0, maxComparisons)
                            .map((data, key: number) => {
                                return (
                                    <th key={key} scope="col">{typeof data === "object" ? <SmartLink {...new SmartLinkModel(data)} className="blue"/> : data }</th>
                                );
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {bodyRows.slice(0,isExpanded ? bodyRows.length : numberOfRows)
                        .map((row, rowIndex) => {
                            return <tr key={rowIndex}>
                                <th scope="row">{row.label}</th>
                                {row.columns?.slice(0, maxComparisons)
                                    .map((value, colIndex) => {
                                        return <td key={colIndex}>
                                            <DynamicValue value={value} defaultString={"-"}/>
                                        </td>
                                    })
                                }
                            </tr>
                        })}
                    <tr
                        className={`${styling["expand-rows"]} ${
                            isExpanded ? styling["expanded"] : ""
                        }`}
                    >
                        <td></td>
                        {(columnDefinition.length - (footerRow ? 1 : 0)) > numberOfRows &&
                        <td colSpan={columnCount}>
                            <a
                                className={`${styling["expand-rows"]} ${styling["view-more"]}`}
                                aria-label="view more table rows"
                                onClick={() => setIsExpanded(!isExpanded)}
                                style={{ cursor: "pointer" }}
                            >
                                { isExpanded ? "View less" : "View more"}
                            </a>
                        </td>}
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td></td>
                        {footerRow?.columns?.slice(0, maxComparisons)?.map((linkData: any, key:number)=>{
                                const smartLinkModel = new SmartLinkModel(linkData);
                                return (
                                    <td key={key}>
                                        <SmartLink {...smartLinkModel} />
                                    </td>
                                );
                            })
                        }
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
}

DesktopComparisonViewRender.displayName = "DesktopComparisonView";
export default withErrorBoundary(DesktopComparisonViewRender);
