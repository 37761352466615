import IComponentProps from "../../../interfaces/IComponentProps";
import PageNotFoundPageModel from '../../../models/PageNotFoundPageModel';
import stylesBase from '../../components/Promotional/_HeroBase.module.scss';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from '../../_shared/ContentfulReactRenderOptions';
import withErrorBoundary from '../../_shared/helpers/withErrorBoundary';
import { usePageNotFoundRequestQuery } from '../../../redux/_shared/helpers/vystarApi';
import { useDispatch } from "react-redux";
import { updateNavigationTheme } from "../../../redux/slices/pageContentSlice";


export function PageNotFoundPage(props?: IComponentProps & PageNotFoundPageModel){
    const dispatch = useDispatch();
    dispatch(updateNavigationTheme('Light'!))
    const pageNotFoundApi = usePageNotFoundRequestQuery(null);
    let pageNotFoundModel : PageNotFoundPageModel | null = null;
    if(pageNotFoundApi.isSuccess && pageNotFoundApi.status === "fulfilled"){
        const { data } = pageNotFoundApi;
        // @ts-ignore
        pageNotFoundModel = new PageNotFoundPageModel(data[0]);
    }
    let { leftContent, rightContent } = pageNotFoundModel ?? {leftContent:null, rightContent:null };

    return (
        <section>
            <div className={`${stylesBase['c-hero']} ${stylesBase['c-hero-text']} ${stylesBase['c-hero-404']} dark`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="hero-content">
                                {leftContent && documentToReactComponents(leftContent, ContentfulReactRenderOptions)}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className={`layout ${stylesBase['boxed-content']}`} >
                                {rightContent && documentToReactComponents(rightContent, ContentfulReactRenderOptions)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default withErrorBoundary(PageNotFoundPage);
