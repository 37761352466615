
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import TallTabTypeEnum from "../../../../enums/TallTabTypeEnum";
import {TabSplitLinkPanel} from "./TabSplitLinkPanel";
import {TabSplitImagePanel} from "./TabSplitImagePanel";
import ITabComponentProps from "../ITallTabComponetProps";

export function TabDetailPanel(props: ITabComponentProps){
    
    const {type = TallTabTypeEnum.Invalid, icon, tabHeader, onTabSelected, isSelected, index} = props;

    let Panel = <></>;

    if(type === TallTabTypeEnum.SplitLink){
        Panel = <TabSplitLinkPanel {...props}/>;
    }else if(type === TallTabTypeEnum.SplitImage) {
        Panel = <TabSplitImagePanel {...props}/>;
    }

    return <>
        <button onClick={onTabSelected} className="accordion-button test" role="tab" aria-controls={`panel-${index}`} aria-selected={isSelected}>
            <div><img src={icon?.imageURL} alt="" aria-hidden="true"/></div>
            <div>{tabHeader}</div>
        </button>
        {(isSelected) ? <>
            {Panel}
        </> : <></>}
    </>;
}

TabDetailPanel.displayName = "TallTab";
export default withErrorBoundary(TabDetailPanel);