
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import BlogNewsEventsItemModel from "../../../models/BlogNewsEventsItemModel";
import styling from "./BlogNewsEvents.module.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import { formatDate } from "./BlogNewsEventsCard";
import SmartLink from "../_shared/SmartLink/SmartLink";
import SubEventModel from "../../../models/SubEventModel";
import { SubEvent } from "./SubEvent";
import LinkList from "../_shared/LinkList/LinkList";
import AuthorList from "../AuthorList/AuthorList";


const BlogNewsEventsContent = (props: BlogNewsEventsItemModel) => {
    const { content: pageContent, title, eventName, eventLocation, subEvents, registrationLink, directionsLink } = props;
    const { description, content, sidebar, authors, publicationDate, startDate, endDate } = pageContent?.body;
    const hasAuthors = !!authors?.writtenBy?.length;
    const writerImage = hasAuthors ? authors.writtenBy[0].image?.imageURL : undefined;
    const writerAltText = hasAuthors ? authors?.writtenBy[0].image?.title : undefined;
    const needsImage = !!writerImage && !!!eventName;
    return (
        <div className={`layout ${styling['content-block']} news`}>
            <article>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 d-none d-lg-block">
                            {/* <!-- Avatar --> */}
                            {
                                needsImage && <div className={`${styling["avatar"]}`}>

                                    <img
                                        src={writerImage}
                                        alt={writerAltText ?? ""}
                                    />

                                </div>
                            }
                            {/* <!-- Sidebar --> */}
                            {
                                sidebar && <div className={`${styling["sidebar"]}`}>
                                    <h2>{sidebar.fields.label}</h2>
                                    <LinkList links={sidebar.fields.links} className="ps-0" />
                                </div>
                            }
                        </div>
                        {/* <!-- Content --> */}
                        <div className="col-lg-8">
                            <div className={`${styling["content-block-article"]}`}>
                                <div className={` ${styling["title"]} ${styling["title-event"]}`}>
                                    <h1>
                                        {title ?? eventName}
                                    </h1>
                                    <div className={`${styling["meta"]}`}>
                                        <span>{formatDate(true, publicationDate, startDate, endDate)}</span>
                                        <span>
                                            {
                                                // @ts-ignore
                                                eventLocation ? eventLocation : <AuthorList writtenBy={authors?.writtenBy!} reviewedBy={authors?.reviewedBy!} editedBy={authors?.editedBy!} name={authors?.name} label={authors?.label} role={authors?.role} />
                                            }
                                        </span>
                                    </div>
                                    {/* <!-- Share: ShareThis Mobile--> */}
                                </div>

                                <div className={`${styling['article-content']}`}>
                                    {!!content && documentToReactComponents(content, ContentfulReactRenderOptions)}
                                    {description && documentToReactComponents(description, ContentfulReactRenderOptions)}
                                    <p>
                                        {registrationLink && <SmartLink {...registrationLink} className={`${styling["smartlink_button"]}`} />}
                                        {directionsLink && <SmartLink {...directionsLink} className={`${styling["smartlink_button"]}`} />}
                                    </p>
                                    {subEvents && <div className={`${styling['single-events']}`}>
                                        {subEvents?.map((sb: SubEventModel) => <SubEvent {...sb} />)}
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 d-none d-lg-block">
                            {/* <!-- Share: ShareThis --> */}
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
};
export default withErrorBoundary(BlogNewsEventsContent);
