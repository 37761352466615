
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import ProductStepwiseDetails from "../../components/ProductStepwiseDetails/ProductStepwiseDetails";
import CTABarProductDetail from "../../components/CTABarProductDetail/CTABarProductDetail";
import ProductDetailSimplePageModel from "../../../models/ProductDetailSimplePageModel";
import CategoryCardList from '../../components/CategoryCardList/CategoryCardList';
import Anchors from '../../components/Anchors/Anchors';
import Feature from '../../components/Feature/Feature';
import FAQ from '../../components/FAQ/FAQ';
import Disclosures from '../../components/Disclosures/Disclosures';
import { Calculator } from "../../components/Calculator/Calculator";
import { isDinkyTownCalc } from "../../_shared/helpers/DinkyTownCalculators";
import { ChimneyCalculator } from "../../components/ChimneyCalculator/Calculator";

export default function ProductDetailSimplePage(props: ProductDetailSimplePageModel & IComponentProps){
    let { htmlId, className="", hero, stepwise, callToActionBar, frequentlyAskedQuestions, anchors, feature,
        categoryRelated, disclosures, calculator } = props;
    let topicCalculator = calculator?.topicCalculator || {};
    let {calculatorCode} = topicCalculator || {};
    return (
        <div id={htmlId} {...{ className }}>
            <section>
                <Promotional {...hero}/>
            </section>
            <section>
                <Anchors {...anchors}/>
            </section>
            <section className={"bg-lightblue"} id="features">
                <Feature {...feature}/>
            </section>
            <section>
                <ProductStepwiseDetails {...stepwise}/>
            </section>
            <section className="bg-gray">
                {calculatorCode && isDinkyTownCalc(calculatorCode) ? 
                    <Calculator {...{...calculator, topicCalculator}}/> : 
                    <ChimneyCalculator {...{...calculator, topicCalculator}}/>}
            </section>
            <section id={"faqs"}>
                <FAQ { ...frequentlyAskedQuestions }/>
            </section>
            <section>
                <CTABarProductDetail {...callToActionBar}/>
            </section>
            <section>
                <CategoryCardList {...categoryRelated}/>
            </section>
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
