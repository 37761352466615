
import ImageListItemModel from "../../../../models/ImageListItemModel";
import ImageModel from "../../../../models/ImageModel";
import IComponentProps from "../../../../interfaces/IComponentProps";
import styles from "../ImageList.module.scss";

export default function ImageListItem (props : ImageListItemModel &  IComponentProps){
    let { htmlId, className = "", subText = "", link, image = new ImageModel(null) } = props;
    
    const {
        imageURL,
        description : imageDescription,
        id
    } = image || new ImageModel(null);

    let InnerItem = () => <div className={`${styles['item']} ${className}`}>
        <div className={`${styles['item-badge']}`}>
            <img src={imageURL}
                 alt={imageDescription}/>
        </div>
        <div className={`${styles['item-content']}`}>
            {subText}
        </div>
    </div>;
    let url = link?.url;
    return (
        <div className={`${styles['image-list-item']} ${className}`} id={htmlId} data-contentid={id}>
            {!!url ?
                <a href={url}><InnerItem/></a> :
                <InnerItem/>
            }
        </div>
    )
}