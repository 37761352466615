import React, {Fragment} from 'react';
import styles from './Photo.module.scss';
import IComponentProps from "../../../../interfaces/IComponentProps";
import ImageModel from "../../../../models/ImageModel";

export default function Photo(props: ImageModel & IComponentProps){
    const {description="", imageURL="", className=""} = props || {};
    const isHidden = !imageURL;
    return (isHidden ? <Fragment/> :
            <div className={`${styles.root} ${className}`}>
                <img src={imageURL} alt={description}/>
            </div>);
};
