import IClientServerModel from "../interfaces/IClientServerModel";
import {Document} from "@contentful/rich-text-types";

export default class DisclosureItemModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly name: string;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined = "";
    public disclosureText?: Document | null;
    constructor(props:DisclosureItemModel | null){
        const {
            htmlId = "",
            disclosureText = null,
            id,
            name = ""
        } = props || {};

        this.htmlId = htmlId;
        this.disclosureText = disclosureText;
        this.id = id;
        this.name = name;
    }
}


