import IClientServerModel from "../interfaces/IClientServerModel";
import {Document} from "@contentful/rich-text-types";
import SideBarModel from "./SideBarModel";

export default class RichContentModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly body: Document | null | undefined;
    public readonly sidebarLinkGroup?: SideBarModel | null | undefined;
    public readonly contentTitle?: string | null| undefined;

    constructor(contentfulSideBarModel: Readonly<RichContentModel> | undefined | null){
        const {htmlId = "", body, sidebarLinkGroup, contentTitle} = contentfulSideBarModel || {};
        this.htmlId = htmlId;
        this.body = body;
        this.sidebarLinkGroup = new SideBarModel(sidebarLinkGroup);
        this.contentTitle = contentTitle;
    }
}