enum PromotionTypesEnum{
    // @ts-ignore
    Default = "Promo.Default",
    // @ts-ignore
    PromoSecondary = "Promo.Secondary",
    // @ts-ignore
    PromoTwoThirds = "Promo.TwoThirds",
    // @ts-ignore
    PromoShort = "Promo.Short",
    // @ts-ignore
    Hero = "Hero.Default",
    // @ts-ignore
    HeroSmall = "Hero.Small",
    // @ts-ignore
    PromoDesktopMenu = "Promo.DesktopMenu",
    // @ts-ignore
    PromoMobileMenu = "Promo.MobileMenu"
}

export default PromotionTypesEnum;
