import React, { useState } from "react";
import IComponentProps from "../../../../interfaces/IComponentProps";
import stylesBase from '../../Promotional/_HeroBase.module.scss';
import styling from '../../PromoAccordions/PromoAccordions.module.scss';
import AccordionGroupModel from "../../../../models/AccordionGroupModel";
import AccordionItemModel from "../../../../models/AccordionItemModel";
import { ContentBlock } from "../../ContentBlock/ContentBlock";
import ContentTypesEnum from "../../../../enums/ContentTypesEnum";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";

import SmartLinkModel from "../../../../models/SmartLinkModel";
import SmartLink from "../SmartLink/SmartLink";

interface ISmartLinkModel {
    cta: SmartLinkModel | undefined;
}


const AccordionGroup = ({accordionItems=[], id, cta }: AccordionGroupModel & IComponentProps & ISmartLinkModel) => {

    const [activeIndices, setActiveIndices] = useState<number[]>([]);

    return (!accordionItems?.length) ? <></> :
        <div className={`${stylesBase["accordion"]} ${styling["accordion"]} js_accordion`} data-contentid={id}>
            <ul role="tablist">
                {accordionItems.map(({answer:richText, question, htmlId, id}: AccordionItemModel, i: number) => {
                    const contentBlock = { richText, htmlId, id: String(htmlId || i), name:String(htmlId || i), contentType: ContentTypesEnum.ContentBlock}
                    const hasFocus = activeIndices.includes(i);
                    return (
                        <li key={i} role={"tab"} id={htmlId} data-contentid={id}>
                            <Accordion 
                                onChange={()=> hasFocus ? setActiveIndices([...activeIndices].filter((e) => e !== i)): setActiveIndices([...activeIndices, i])}
                                classes={{
                                    root: "bg-transparent shadow-none",
                                }}
                              >
                                <AccordionSummary
                                    aria-controls={htmlId}
                                    aria-hidden={hasFocus ? false : true }
                                    id={htmlId}
                                    classes={{
                                        root: `${styling["accordion_title"]} ${stylesBase["accordion_title"]} ${hasFocus ? `${styling["is_expanded"]} ${stylesBase["is_expanded"]}`: ""}`,
                                        content: 'm-0'
                                    }}
                                >
                                    {question}
                                </AccordionSummary>
                                <AccordionDetails aria-labelledby={htmlId} classes={{
                                    root: `${stylesBase["accordion_panel"]} ${styling["accordion_panel"]} d-block`
                                }}>
                                    <ContentBlock {...contentBlock} />
                                </AccordionDetails>
                            </Accordion>
                        </li>
                    )})}
            </ul>
            {cta && <SmartLink {...cta} />}
        </div>;
}
export default withErrorBoundary(AccordionGroup);
