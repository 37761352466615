
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../../interfaces/IComponentProps";
import BannerAlertModel from "../../../../models/BannerAlertModel";
import closeImage from "../../../_assets/x-close-blue-circle.svg";
import IClosable from "../../../../interfaces/IClosable";
import LinkList from "../../../components/_shared/LinkList/LinkList";
import AlertTypeEnum from "../../../../enums/AlertTypeEnum";

export function Alert(props: BannerAlertModel & IComponentProps & IClosable)  {
    const {onClose = () => undefined, type, id} = props || {};
    const {header, body, icon, links} = props?.content || {};
    const alertClass = type === AlertTypeEnum.Danger ? 'alert-danger' : 'alert-primary';
    return <div className={`alert ${alertClass} is-visible`} role="listitem" data-contentid={id}>
        <div role={"alert"} className="container">
            <div className="row">
                <div className="col-12">
                    <div className="alert-content">
                        <div className="icon">
                            <img src={icon?.imageURL} alt="" aria-hidden="true"/>
                        </div>
                        <div className="title">{header}</div>
                        <p>{body}</p>
                        <LinkList links={links}/>
                        <button type="button" className="close-alert" onClick={()=>onClose()}>
                            <img src={closeImage} alt="" aria-hidden="true"/>
                            <span className="visually-hidden">Close Alert</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
Alert.displayName = "CategoryCardList";
export default withErrorBoundary(Alert);