import React, { useEffect, useRef } from 'react';
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";

interface ISmartTab {
    tabIndex: number;
    activeIndex: number;
    label: string;
    htmlId: string;
    onClick: any;
}
const setTabIndex = (i: number, activeIndex: number) =>
    i === activeIndex ? 0 : -1;
const setAriaSelected = (i: number, activeIndex: number) =>
    i === activeIndex ? true : false;

export function SmartTab({label, tabIndex, activeIndex, htmlId, id, onClick}: (ISmartTab & IComponentProps)){

    const buttonRef = useRef<HTMLButtonElement>(null);
    
    useEffect(()=>{
        if(activeIndex === tabIndex){
            buttonRef?.current?.focus();
        }
    },[activeIndex, tabIndex, buttonRef]);

    return (<button
        ref={buttonRef}
        role="tab"
        aria-selected={setAriaSelected(
            tabIndex,
            activeIndex
        )}
        aria-controls={htmlId}
        id={`tab-${tabIndex + 1}`}
        data-contentid={id}
        tabIndex={setTabIndex(
            tabIndex,
            activeIndex
        )}
        onClick={onClick}
        key={tabIndex}
    >
      {label}
    </button>);
}
export default withErrorBoundary(SmartTab);