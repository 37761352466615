import { useEffect } from 'react';
import {
    Hits,
    connectHits,
} from "react-instantsearch-dom";
import SearchHighlight from './SearchHighlight';
import styling from "./Search.module.scss";
import debounce from "lodash.debounce";
import aa from 'search-insights';
import envDataConfig from '../../../EnvDataConfig/EnvDataConfig';

interface IHits {
    hits: Hits[];
}

const sendEventDebounced = debounce(() => {
    const algoliaUserToken = sessionStorage.getItem("algoliaUserToken") || undefined;
    window.dataLayer?.push({ event: 'Hits Viewed', "Algolia Insights User Token": algoliaUserToken });
    const objectIDs = Array.from(document.querySelectorAll('[data-insights-object-id]')).map((element:any)=> element.dataset["insightsObjectId"] as string);
    const filters = Array.from(document.querySelectorAll('[data-insights-filter]')).map((element:any)=> element.dataset["insightsFilter"] as string);
    aa('viewedObjectIDs', {
        userToken: algoliaUserToken,
        index: envDataConfig.AlgoliaIndex,
        eventName: 'Search Hits Viewed',
        objectIDs: objectIDs
      });
    aa('viewedFilters', {
        userToken: algoliaUserToken,
        index: envDataConfig.AlgoliaIndex,
        eventName: 'Search Viewed Filters',
        filters: filters
    });
}, 2000);

function MyHits({ hits }: IHits) {

    useEffect(()=>{
        sendEventDebounced();
    },[hits])

    return (
        <div id="hits" className={`${styling["hits"]}`}>
            <div>
                <div className={`${styling["ais-Hits"]}`}>
                    <ol className={`${styling["ais-Hits-list"]} ps-0`}>
                        {!!!hits.length && <li>No results</li>}
                        { hits.map((hit: any) => {
                            return (
                                <li key={hit.objectID}
                                    data-insights-object-id={hit.objectID}
                                    data-insights-position={hit.__position}
                                    data-insights-query-id={hit.__queryID}
                                >
                                    <div>
                                        <p className={`${styling["title"]}`} >
                                            <a href={hit.url}>{hit.title}</a>
                                        </p>
                                        <p style={{marginBottom:15}}>
                                            <SearchHighlight 
                                                hit={hit}
                                                attribute="url"
                                                isURL={true}
                                            />
                                        </p>
                                        <SearchHighlight
                                            hit={hit}
                                            attribute="description"
                                            isURL={false}
                                        />
                                    </div>
                                </li>
                            )}
                        )}
                    </ol>
                </div>
            </div>
        </div>
    );
}
export default connectHits(MyHits);