
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import ContentBlockModel from "../../../models/ContentBlockModel";
import styles from "./ContentBlock.module.scss";
import styling from "../RichContent/RichContent.module.scss";

const ContentBlockDynamic = (contentBlock: ContentBlockModel)=>{
    
    return (
        <div className="container" id={contentBlock.htmlId} data-contentid={contentBlock.id}>
            <div className="row mx-auto">
                <div className="col-12">
                    <div className={`${styling["content-block"]} ${styles["content-block"]}`}>
                        { !!contentBlock.richText && documentToReactComponents(contentBlock.richText, ContentfulReactRenderOptions)}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default withErrorBoundary(ContentBlockDynamic);