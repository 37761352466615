import React, { MouseEventHandler } from 'react'
import styles from './Button.module.scss'

export type buttonSizes = 'large' | 'medium' | 'small'
export type buttonTypes = 'primary' | 'secondary' | 'text' | 'toggle'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  block?: boolean
  rounded?: boolean
  size?: buttonSizes
  type?: buttonTypes
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  type,
  size,
  style,
  block,
  onClick,
  rounded,
  disabled,
  children,
  className,
  ...rest
}) => (
  <button
    type='button'
    style={style}
    onClick={onClick}
    disabled={disabled}
    className={[
      className,
      styles.button,
      type && styles[type],
      size && styles[size],
      block && styles.block,
      rounded && styles.rounded
    ].join(' ')}
    {...rest}
  >
    {children}
  </button>
)

Button.defaultProps = {
  type: 'primary',
  size: 'small'
}

export default Button