import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import '../RatesFilter/RatesFilter.module.scss';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import DisclosureItemModel from "../../../models/DisclosureItemModel";
import ErrorBoundary from "../_shared/ErrorBoundary/ErrorBoundary";
import styling from "./Disclosures.module.scss";

export function Disclosure(disclosure: DisclosureItemModel | undefined){
    return <ErrorBoundary>
        {disclosure?.disclosureText ? <div className={`${styling['disclosure-font']} ${styling['disclosure-padding-top']} bg-gray`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-11">
                        {disclosure && documentToReactComponents(disclosure?.disclosureText, ContentfulReactRenderOptions)}
                    </div>
                </div>
            </div>
        </div> : <></>}
    </ErrorBoundary>
}

export function Disclosures({disclosures}:  {disclosures: DisclosureItemModel[] | undefined}) {


    return disclosures?.length ? (
        <div className={`${styling['disclosure-font']} ${styling['disclosure-padding-top']} bg-gray`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-11">
                        { disclosures?.map(
                            (
                                disclosure, index)=>disclosure?.disclosureText &&
                                <div key={index} data-contentid={disclosure.id}>
                                    {
                                        documentToReactComponents(disclosure.disclosureText, ContentfulReactRenderOptions)
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    ):<></>;
}
export default withErrorBoundary(Disclosures);
