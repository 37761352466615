import { configureStore } from '@reduxjs/toolkit'
import { vystarApi } from './_shared/helpers/vystarApi';
import pageContentSlice from './slices/pageContentSlice';
import globalSlice from './slices/globalSlice';
import systemSlice from './slices/systemSlice';
import modalSlice from './slices/modalSlice';

const store = configureStore({
  devTools: true,
  reducer: {
    [vystarApi.reducerPath]: vystarApi.reducer,
    pageContent: pageContentSlice,
    global: globalSlice,
    system: systemSlice,
    modal: modalSlice
  },
  middleware: (gDM) => gDM().concat(vystarApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

