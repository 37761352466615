import IClientServerModel from "../interfaces/IClientServerModel";
import BioCardModel from "./BioCardModel";
import SmartLinkModel from "./SmartLinkModel";

export default class BioCardListModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public readonly label?: string | undefined = "";
    public readonly content?: BioCardModel[] | undefined | null;
    public readonly ctaLink?: SmartLinkModel | null | undefined;

    constructor(contentfulBioCardModel: Readonly<BioCardListModel> | undefined | null){
        let {htmlId = "", content = [], ctaLink, label = "", id} = contentfulBioCardModel || {};
        this.htmlId = htmlId;
        this.id = id;
        this.label = label;
        this.content = content?.map(card=>new BioCardModel({...card, label}));
        this.ctaLink = new SmartLinkModel(ctaLink);
    }
}
