import IClientServerModel from "../interfaces/IClientServerModel";
import CompareTableDetailModel from "./CompareTableDetailModel";

export default class CompareTableModel implements IClientServerModel{

    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly id: string | undefined;
    public readonly header?: string | null | undefined;
    public readonly description?: string | null | undefined;
    public readonly table?: CompareTableDetailModel | null | undefined;

    constructor(props: Readonly<CompareTableModel> | undefined | null){
        let {htmlId = "", id, header, description, table} = props || {};
        this.htmlId = htmlId;
        this.id = id;
        this.header = header;
        this.description = description;
        this.table = new CompareTableDetailModel(table);
    }
}

