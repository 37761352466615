import IClientServerModel from "../interfaces/IClientServerModel";
import NavMenuItemModel from "./NavMenuItemModel";
import SmartLinkModel from "./SmartLinkModel";

export default class NavMenuModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined;
    public readonly content?: Array<NavMenuItemModel> | undefined;
    public readonly path?: string | undefined;
    public readonly logo?: SmartLinkModel;
    public readonly logoSecondary?: SmartLinkModel;
    constructor(props: NavMenuModel) {
        let {content, logo, logoSecondary, path} = props;
        this.content = content?.map(i => new NavMenuItemModel(i));
        this.logo = new SmartLinkModel(logo);
        this.logoSecondary = new SmartLinkModel(logoSecondary);
        this.path = path;
    }
}

export const _clientType = NavMenuModel.constructor.name;

