import { useState } from "react";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import BioCardModel from "../../../models/BioCardModel";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import SmartLink from "../_shared/SmartLink/SmartLink";
import "./BioCardContainer.module.scss";
import bioImageDefault from "./sample-bio-default.png";
import "./BioCardContainer.module.scss";
import { clickEvent } from "../../../models/SmartLinkModel";
import DynamicModal from "../_shared/Dialogs/DynamicModal";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/slices/modalSlice";

export function BioCard(props: BioCardModel & IComponentProps) {

    const {htmlId, altText, image, header, subHeader, link, body, id, label} = props;
    const dispatch = useDispatch();

    return (<div className="col-12 col-md-6 col-lg-4" id={htmlId} data-contentid={id}>
                <div className={`bio`}>
                    <img
                        src={!!image?.imageURL ? image.imageURL : bioImageDefault}
                        alt={altText ?? ""}
                    />
                    <div className="card-body">
                        <div className="bio-name">
                            <a onClick={(e:clickEvent)=> {
                                e.preventDefault();
                                dispatch(openModal({
                                    modalType: "Bio", 
                                    dialogTitle: label,
                                    link,
                                    body, 
                                    altText, 
                                    header, 
                                    subHeader, 
                                    image: { imageURL: image?.imageURL}}));
                            }}>
                                {header}
                            </a>                        
                        </div>
                        <div className="bio-title">{subHeader}</div>
                        {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                        <p></p>
                        <SmartLink {...link} />
                    </div>
                </div>
            </div>
    );
}
export default withErrorBoundary(BioCard);
