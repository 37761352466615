import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";
import {Document} from "@contentful/rich-text-types";

export default class SubFooterModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly label: string | undefined = "";
    public readonly links: Array<SmartLinkModel> | undefined = [];
    public readonly socialLinks: Array<SmartLinkModel> | undefined = [];
    public readonly contentBlock?: {richText:Document};

    constructor(contentfulQuestionAnswerModel: Readonly<SubFooterModel> | undefined | null){
        const {htmlId = "", label = "", links = [], socialLinks, contentBlock} = contentfulQuestionAnswerModel || {}
        this.htmlId = htmlId;
        this.label = label;
        this.links = links?.map((contentfulLink: any) => new SmartLinkModel(contentfulLink));
        this.socialLinks = socialLinks?.map(link => new SmartLinkModel(link));
        this.contentBlock = contentBlock;
    }
}

