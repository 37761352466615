import IClientServerModel from "../interfaces/IClientServerModel";
import {Document} from "@contentful/rich-text-types";
import ImageModel from "./ImageModel";
import CardModel from "./CardModel";
import ensureBoolean from "../redux/_shared/helpers/ensureBoolean";
import SmartLinkModel from "./SmartLinkModel";

export interface IProductStepwiseDetailContent {
    readonly subheader?: Document | undefined;
    readonly header?: string | undefined;
    readonly backgroundImage?: ImageModel | null | undefined;
    readonly steps?: Array<CardModel> | null | undefined;
    readonly callToActionButtons?: SmartLinkModel[];
}

interface IStepNumber{
    showStepNumber?: boolean | boolean
}


export default class ProductStepwiseDetailModel implements IClientServerModel, IStepNumber{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id: string | undefined;
    public readonly showStepNumber?: boolean = false;
    public readonly content?: IProductStepwiseDetailContent;
    constructor(props:Readonly<ProductStepwiseDetailModel> | null | undefined) {
        const {htmlId, showStepNumber, id} = props || {};
        let {backgroundImage, header, subheader, steps, callToActionButtons} = props?.content || {};
        this.htmlId = htmlId;
        this.id = id;
        this.showStepNumber = ensureBoolean(showStepNumber);
        this.content = {
            backgroundImage: new ImageModel(backgroundImage),
            header: header,
            subheader: subheader,
            steps: steps,
            callToActionButtons: callToActionButtons?.map(link=>{
                return new SmartLinkModel(link);
            })
        }
    }
}
