
import stylesBase from "../_PromotionalBase.module.scss";
import styles from "./PromoTwoThirds.module.scss";
import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";
import PromotionModel from "../../../../models/PromotionModel"
import ImageModel from "../../../../models/ImageModel";
import SideAlignmentEnum from "../../../../enums/SideAlignmentEnum";
import LinkList from "../../_shared/LinkList/LinkList";
import CalculateNavigationTheme from "../../../_shared/helpers/CalculateNavigationTheme";
import { useDispatch } from "react-redux";
import { updateNavigationTheme } from "../../../../redux/slices/pageContentSlice";
import { useEffect } from "react";

export function PromoTwoThirdsRender(props: PromotionModel & IComponentProps)  {
    const { id, htmlId, primary, className = "", textAlignment, theme, content = {}} = props;
    const { header = "", links = [],
        image = new ImageModel(null), body
    } = content;
    const layoutAlignmentClassName = textAlignment === SideAlignmentEnum.Left ? styles["reverse"] : "";

    const {
        imageURL: backgroundImageURL,
        description: backgroundDescription
    } = image || new ImageModel(null);

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    const themeClassName = (theme === ThemeTypeEnum.Dark) ? styles["dark"] : styles["light"];
    return (
        <div id={htmlId} style={{backgroundImage:`url(${backgroundImageURL})`}} aria-label={backgroundDescription} className={`layout ${className} ${layoutAlignmentClassName} ${stylesBase['c-promo']} ${styles['c-promo-two-thirds']} ${themeClassName}`} data-contentid={id}>
            <div className={`${stylesBase['container']} container`}>
                <div className="row">
                    <div className="col-12 col-lg-7 col-xl-5">
                        <div className={`${styles['promo-content']}`}>
                            <div>
                                <h3>{header}</h3>
                                {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                            </div>
                            <LinkList links={links}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
PromoTwoThirdsRender.displayName = "PromoTwoThirds";
export default withErrorBoundary(PromoTwoThirdsRender);