import IClientServerModel from "../interfaces/IClientServerModel";

export interface IRateName{
    name?: string | undefined;
}

export interface IRatePersonalAutoVehicleTerm {
    apyApr?: string | undefined;
    term?: string | undefined;
}

export interface IRateBusinessDeposit {
    minBalanceToEarnDividends?: string | undefined;
    dividendRate?: string | undefined;
    apyApr?: string | undefined;
    monthlyServiceCharge?: string | undefined;//?
    minDailyBalanceToAvoidServiceCharge?: string | undefined;
}

export interface IRateBusinessLoan {
    apyApr?: string | undefined;
}

export interface IRateCD{
    term?: string | undefined;
    apyApr?: string | undefined;
    dividendRate?: string | undefined;
    minBalanceToEarnDividends?: string | undefined;
}

export interface  IRatePersonalChecking{
    minBalanceToEarnDividends?: string | undefined;
    dividendRate?: string | undefined;
    apyApr?: string | undefined;
}

export interface IRatePersonalCreditCard {
    apyApr?: string | undefined;
}

export interface IRatePersonalHomeEquityAndLineOfCredit{
    combinedLoanToValue?: string | undefined;
    points?: string | undefined;
    initialRate?: string | undefined;
    principleAndInterestPayments?: string | undefined;
    apyApr?: string | undefined;
    term?: string | undefined;
}

export interface IRatePersonalMortgageLoan {
    fullyIndexedRate?: string | undefined;
    maxInterestRate?: string | undefined;
    term?: string | undefined;
    apyApr?: string | undefined;
    initialRate?: string | undefined;
    points?: string | undefined;
    principleAndInterestPayments?:string | undefined;
}

export interface IRatePersonalLoanAndLineOfCredit{
    apyApr?: string | undefined;
    term?: string | undefined;
}

export interface IRatePersonalSavingsIraHsaRates{
    apyApr?: string | undefined;
    dividendRate?: string | undefined;
    minBalanceToEarnDividends?: string | undefined;
    term?: string | undefined;
}

export interface IRateLine extends IClientServerModel, IRateName,
    IRatePersonalAutoVehicleTerm,
    IRateBusinessDeposit,
    IRateBusinessLoan,
    IRateCD,
    IRatePersonalChecking,
    IRatePersonalCreditCard,
    IRatePersonalHomeEquityAndLineOfCredit,
    IRatePersonalMortgageLoan,
    IRatePersonalLoanAndLineOfCredit,
    IRatePersonalSavingsIraHsaRates
    {
        minBalanceToEarnDividends?: string | undefined;
        dividendRate?: string | undefined;
        apyApr?: string | undefined;
        monthlyServiceCharge?: string | undefined;
        minDailyBalanceToAvoidServiceCharge?: string | undefined;
        timeToFirstRateAdjustment?: string | undefined;
        term?: string | undefined;
        initialRate?: string | undefined;
        points?: string | undefined;
        maxInterestRate?: string | undefined;
        fullyIndexedRate?: string | undefined;
        principleAndInterestPayments?: string | undefined;
        combinedLoanToValue?: string | undefined;
    }


export default class RateLineModel implements IRateLine {
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly name?: string | undefined = "";
    public readonly minBalanceToEarnDividends?: string | undefined = "";
    public readonly dividendRate?: string | undefined = "";
    public readonly apyApr?: string | undefined = "";
    public readonly monthlyServiceCharge?: string | undefined = "";
    public readonly minDailyBalanceToAvoidServiceCharge?: string | undefined = "";
    public readonly timeToFirstRateAdjustment?: string | undefined = "";
    public readonly term?: string | undefined = "";
    public readonly initialRate?: string | undefined = "";
    public readonly points?: string | undefined = "";
    public readonly maxInterestRate?: string | undefined = "";
    public readonly fullyIndexedRate?: string | undefined = "";
    public readonly principleAndInterestPayments?: string | undefined = "";
    public readonly combinedLoanToValue?: string | undefined = "";

    constructor(props:RateLineModel | null){
        const {
            htmlId = "",
            minBalanceToEarnDividends = "",
            dividendRate = "",
            apyApr = "",
            monthlyServiceCharge = "",
            minDailyBalanceToAvoidServiceCharge = "",
            timeToFirstRateAdjustment = "",
            term = "",
            initialRate = "",
            points = "",
            maxInterestRate = "",
            fullyIndexedRate = "",
            principleAndInterestPayments = "",
            combinedLoanToValue = ""
        } = props || {};

        this.htmlId = htmlId;
        this.name = htmlId;
        this.minBalanceToEarnDividends = minBalanceToEarnDividends;
        this.dividendRate = dividendRate;
        this.apyApr = apyApr;
        this.monthlyServiceCharge = monthlyServiceCharge;
        this.minDailyBalanceToAvoidServiceCharge = minDailyBalanceToAvoidServiceCharge;
        this.timeToFirstRateAdjustment = timeToFirstRateAdjustment;
        this.term = term;
        this.initialRate = initialRate;
        this.points = points;
        this.maxInterestRate = maxInterestRate;
        this.fullyIndexedRate = fullyIndexedRate;
        this.principleAndInterestPayments = principleAndInterestPayments;
        this.combinedLoanToValue = combinedLoanToValue;
    }
}


