import CategoryCardListModel from "./CategoryCardListModel";

export default class PersonalizedCategoryModel {
    public readonly contentType?: string;
    public readonly id: string | undefined = "";
    public readonly name?: string | undefined = "";
    public readonly variatians: CategoryCardListModel[];
    constructor(props:Readonly<PersonalizedCategoryModel> | null | undefined) {
        let {contentType = "", id = "", name = "", variatians = []} = props || {};
        this.contentType = contentType;
        this.id = id;
        this.name = name;
        this.variatians = variatians?.map(variatians => new CategoryCardListModel(variatians)) || []; }
    }