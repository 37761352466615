import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../interfaces/IComponentProps";
import CompareTableModel from "../../../models/CompareTableModel";
import styling from "./CompareTable.module.scss";
import DesktopComparisonView from "./DesktopComparisonView/DesktopComparisonView";
import MobileComparisonView from "./MobileComparisonView/MobileComparisonView";

export function CompareTable(props: CompareTableModel & IComponentProps) {
    const maxComparisons = 4;
    const defaultRowCount = 5;
    const { header: compareHeader, description, table, id, htmlId } = props;
    const header = table?.header || compareHeader;
    const compareTable = props;
    const footerLabelName = "cta";

    return (
        <div className={`layout ${styling["table-compare"]} bg-blue`} data-contentid={id} id={htmlId}>
            {/* <!-- Intro --> */}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 col-xl-6">
                        <div className={`${styling["intro"]} text-center intro`}>
                            <h2>{header}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Desktop Table View --> */}
            <DesktopComparisonView {...{maxComparisons, defaultRowCount, compareTable, footerLabelName}} />

            {/* <!-- Mobile Cards View --> */}
            <MobileComparisonView {...{maxComparisons, defaultRowCount, compareTable, footerLabelName}}/>
        </div>
    );
}
export default withErrorBoundary(CompareTable);
