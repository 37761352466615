import LayoutType from "../enums/LayoutEnum";
import ColumnDefinitionModel from './ColumnDefinitionModel';
import {Document} from "@contentful/rich-text-types";
import SmartLinkModel from "./SmartLinkModel";


export interface IFieldData{
    value: Document | string, //TODO: jdh - maybe more types
    label: string
}

export interface ITableItem{
    // @ts-ignore
    heading: SmartLinkModel | string | null,//the only prop that does no return IFieldData, ts-ignored
    [key:string]: IFieldData //property indexer
}

export default class CompareTableDetailModel{

    public readonly _clientType?: string = this.constructor.name;
    public readonly columnDefinition?: ColumnDefinitionModel[] | undefined;
    public readonly tableItems?: ITableItem[] | null | undefined;
    public readonly header?: string | null | undefined;
    public readonly numberOfRows?: number | undefined = 4;
    public readonly type?: LayoutType | undefined = LayoutType.Vertical;

    constructor(props: Readonly<CompareTableDetailModel> | undefined | null){
        const { columnDefinition = [], tableItems = [], numberOfRows, type, header} = props || {};
        this.columnDefinition = columnDefinition?.map(c=> new ColumnDefinitionModel((c)));
        this.tableItems = tableItems;//Fully Dynamic data, can't have concrete class
        this.numberOfRows = numberOfRows;
        this.header = header;
        this.type = type;
    }
}


