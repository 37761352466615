enum LinkTypeEnum{
    // @ts-ignore
    Default = "Link.Default",
    // @ts-ignore
    PrimaryButton = "Link.Button.Primary",
    // @ts-ignore
    SecondaryButton = "Link.Button.Secondary",
    // @ts-ignore
    Piped = "Link.Piped",
    // @ts-ignore
    Icon = "Link.Icon",
}

export default LinkTypeEnum;
