import { FieldType } from '../../../../components/Form/FormField'
import { ErrorMessages } from './FormInput'

export interface RegexTypes {
  FieldType: RegExp
}

export const getFormattedErrors = (label: string, minLength: number, maxLength: number) => {
  const formattedLabel = label.replace('(optional)', ' ')
  const defaultErrorMessages: ErrorMessages = {
    minMax: `${formattedLabel} must be between ${minLength} and ${maxLength} characters`,
    exactLength: `${formattedLabel} must be ${maxLength} characters long.`,
    requiredField: `${formattedLabel} is a required field`
  }
  return defaultErrorMessages
}

export const defaultRegex = (fieldType: FieldType) => {
  switch(fieldType){
    case FieldType.AlphanumericTextField:
      return /^([A-Za-z0-9\n\W* ]*)$/
    case FieldType.TextArea:
      return /^([A-Za-z0-9._\n\W* ]*)$/
    case FieldType.PhoneNumber:
      return /^([0-9()\- ]*)$/
    case FieldType.MemberNumber:
      return /^(?:[0-9 ]*)$/
    case FieldType.EmailAddress:
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    default: 
      return /^([A-Za-z-' ]*)$/
  }
}