import QuestionAnswerModel from "./QuestionAnswerModel";

class AccordionItemModel extends QuestionAnswerModel{
    public readonly id: string | undefined;
    constructor(props:AccordionItemModel | null){
        super(props);
        const { id } = props || {};
        this.id = id;
    }
};

export default AccordionItemModel;