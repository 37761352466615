export default function ensureBoolean(contenfulValue: Readonly<string | boolean | number | undefined | null>):boolean{
    let value:boolean = false;
    if(!!contenfulValue){
        switch(typeof contenfulValue){
            case "boolean":
                value = contenfulValue;
                break;
            case "number":
                value = (contenfulValue > 0)
                break;
            case "string":
                const lowerCaseContenfulValue = contenfulValue.toLowerCase();
                const validLowerCaseTrueValues = ["on", "true", "t", "yes", "y"];
                value = validLowerCaseTrueValues.includes(lowerCaseContenfulValue)
        }
    }
    return value
}