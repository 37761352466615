import {
    REACT_APP_API_BASE_URL,
    REACT_APP_CONTENT_ACCESS_RESTRICTIONS_ENABLED,
    REACT_APP_GTM_TAG,
    REACT_APP_API_BASE_URL_LOCAL,
    REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    REACT_APP_POSH_ID,
    REACT_APP_VYCHAT_TYPE,
    REACT_APP_HIDE_SEARCH,
    REACT_APP_HIDE_LOGIN,
    REACT_APP_SITE_CACHE_GLOBAL_TIMEOUT,
    REACT_APP_SITE_CACHE_PAGE_TIMEOUT,
    REACT_APP_ALGOLIA_INDEX,
    REACT_APP_ALGOLIA_APP_ID,
    REACT_APP_ALGOLIA_API_KEY,
    REACT_APP_SITE_TAG,
    REACT_APP_RECAPTCHA_API_KEY,
    REACT_APP_RECAPTCHA_API_SITE_KEY,
    REACT_APP_RECAPTCHA_API_SECRET_KEY,
    REACT_APP_SENDGRID_CLIENT_KEY,
    REACT_APP_UNIFORM_API_KEY,
    REACT_APP_UNIFORM_PROJECT_ID,
    REACT_APP_UNIFORM_LOCATION_API,
    REACT_APP_APPLY_LINK
} from "./_envSettings";

import { getQueryStringParameter } from "./_shared/queryStringHelper";
import VyChatTypeEnum from "../enums/VyChatTypeEnum";

const storage = window.sessionStorage;

export default class envDataConfig {

    static get showSearch():boolean{
        return REACT_APP_HIDE_SEARCH?.toLowerCase() === 'true';
    }

    static get showLogin():boolean{
        return REACT_APP_HIDE_LOGIN?.toLowerCase() === 'true';
    }

    static get ReCaptchaAPIKey():string{
        return REACT_APP_RECAPTCHA_API_KEY;
    }
    
    static get ReCaptchaAPISiteKey():string{
        return REACT_APP_RECAPTCHA_API_SITE_KEY;
    }

    static get ReCaptchaAPISecretKey():string{
        return REACT_APP_RECAPTCHA_API_SECRET_KEY;
    }

    static get SendGridClientKey():string{
        return REACT_APP_SENDGRID_CLIENT_KEY;
    }
    // set cache to one second if null
    static get cachePageTimeout():number{
        return parseInt(REACT_APP_SITE_CACHE_PAGE_TIMEOUT) || 1000;
    }

    static get cacheGlobalTimeout():number{
        return parseInt(REACT_APP_SITE_CACHE_GLOBAL_TIMEOUT)  || 1000;
    }

    static get baseUrl():string{
        const baseUrl = REACT_APP_API_BASE_URL?.endsWith('/') ? REACT_APP_API_BASE_URL : `${REACT_APP_API_BASE_URL}/`;
        return baseUrl;
    }

    static get baseUrlLocal():string{
        const baseUrlLocal = REACT_APP_API_BASE_URL_LOCAL?.endsWith('/') ? REACT_APP_API_BASE_URL_LOCAL : `${REACT_APP_API_BASE_URL_LOCAL}/`;
        return baseUrlLocal;
    }

    static get accessToken() {
        let cachedToken = storage.getItem("accessToken");
        let queryStringAccessToken = getQueryStringParameter("token");
        if (queryStringAccessToken && cachedToken !== queryStringAccessToken) {
            storage.setItem("accessToken", queryStringAccessToken);
        }
        return queryStringAccessToken || cachedToken || "";
    }

    static get chatType(): VyChatTypeEnum{
        return (Object.entries(VyChatTypeEnum).map(([,value])=>value).find( value =>{
            return value?.toString().toLowerCase() === REACT_APP_VYCHAT_TYPE.toLowerCase();
        }) || VyChatTypeEnum.None) as VyChatTypeEnum;
    }

    static get isPoshLivePersonEnabled():boolean{
        return !!REACT_APP_POSH_ID && this.chatType === VyChatTypeEnum.PoshLivePerson;
    }
    static get isGliaEnabled():boolean{
        return !!REACT_APP_POSH_ID && this.chatType === VyChatTypeEnum.Glia;
    }

    static get isLivePersonOnlyEnabled():boolean{
        let isPoshLivePersonMissingId = !REACT_APP_POSH_ID && this.chatType === VyChatTypeEnum.PoshLivePerson;
        let isPoshOnly = this.chatType === VyChatTypeEnum.LivePerson;
        return isPoshLivePersonMissingId || isPoshOnly;
    }

    static get poshId():string{
        return REACT_APP_POSH_ID
    }

    static get isGTMEnabled(): boolean {
        return !!REACT_APP_GTM_TAG;
    }

    static get GTMTag(): string {
        return REACT_APP_GTM_TAG;
    }
    static get SiteTag(): string {
        return REACT_APP_SITE_TAG;
    }
    static get AlgoliaIndex(): string {
        return REACT_APP_ALGOLIA_INDEX;
    }
    static get AlgoliaAppId(): string {
        return REACT_APP_ALGOLIA_APP_ID;
    }
    static get ApplyLink(): string {
        return REACT_APP_APPLY_LINK;
    }
    static get AlgoliaApiKey(): string {
        return REACT_APP_ALGOLIA_API_KEY;
    }

    static get isTokenAccessRequired(): boolean {
        return REACT_APP_CONTENT_ACCESS_RESTRICTIONS_ENABLED?.toLowerCase() === "true";
    }

    static get apimSubscriptionKey(): string {
        let subscriptionKey = REACT_APP_OCP_APIM_SUBSCRIPTION_KEY?.replace('#{Ocp-Apim-Subscription-Key}#', '')
        return subscriptionKey;
    }

    static get UniformApiKey():string{
        return REACT_APP_UNIFORM_API_KEY;
    }

    static get UniformProjectId():string{
        return REACT_APP_UNIFORM_PROJECT_ID;
    }

    static get UniformLocationAPI():string{
        return REACT_APP_UNIFORM_LOCATION_API;
    }
}