import IClientServerModel from "../interfaces/IClientServerModel";
import ContentBlockModel from "./ContentBlockModel";
import AccordionGroupModel from "./AccordionGroupModel";
import ContentTypesEnum from "../enums/ContentTypesEnum";



export default class PromoAccordionsModel implements IClientServerModel{
    public readonly htmlId?: string | undefined = "";
    public readonly header?: string | undefined = "";    
    public readonly contentType?: ContentTypesEnum;
    public readonly id?: string | undefined;
    public readonly _clientType?: string = this.constructor.name;
    public readonly contentBlock?: ContentBlockModel | undefined;
    public readonly accordionGroup?: AccordionGroupModel | undefined;

    constructor(props: Readonly<PromoAccordionsModel> | undefined | null){
        const {htmlId = "", header, id, contentBlock, accordionGroup} = props ?? {};
        this.htmlId = htmlId;
        this.header = header;
        this.id = id;
        this.contentType = ContentTypesEnum.PromoAccordions;
        this.accordionGroup = new AccordionGroupModel(accordionGroup);
        this.contentBlock = contentBlock ? new ContentBlockModel(contentBlock) : undefined;
    }
}