// @ts-ignore

// @ts-ignore
import baseStyles from "./_VideoPlayer.module.scss";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../../interfaces/IComponentProps";
import VideoPlayerModel from "../../../../models/VideoPlayerModel";

const VideoPlayer = ({htmlId, caption, videoLink, $id}: VideoPlayerModel & IComponentProps) => (
  <div className={`${baseStyles["inline-video"]} inline-video`} id={htmlId} data-contentid={$id}>
      <div className={baseStyles["iframe-container"]} >
        <iframe 
          className={baseStyles["responsive-iframe"]} 
          src={videoLink}
          title={caption}                                                              
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen 
          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-presentation"
        />
      </div>   
      { caption && <div className={`${baseStyles["caption"]}`}>{caption}</div>}
    </div>
);
export default withErrorBoundary(VideoPlayer);
