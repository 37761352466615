
import BlogNewsEventsItemModel, { BlogNewsEventType } from '../../../models/BlogNewsEventsItemModel';
import styling from "./BlogNewsEvents.module.scss";
import "../_shared/SmartLink/SmartLink.module.scss";
import SmartLinkModel from "../../../models/SmartLinkModel";
import SmartLink from "../_shared/SmartLink/SmartLink";
import LinkTypeEnum from "../../../enums/LinkTypeEnum";

export const formatDate = (addPrefix: boolean, publicationDate?: string, startDate?:string, endDate?:string) => {
    if(startDate && endDate){
        const sd = new Date(startDate);
        const ed = new Date(endDate);
        const startDay =  sd.toLocaleDateString('en-US',{day:'numeric'});
        const endDay =  ed.toLocaleDateString('en-US',{day:'numeric'});
        const startMonth =  sd.toLocaleDateString('en-US',{month:'long'});
        const endMonth =  ed.toLocaleDateString('en-US',{month:'long'});
        const startYear =  sd.toLocaleDateString('en-US',{year:'numeric'});
        const endYear =  sd.toLocaleDateString('en-US',{year:'numeric'});
        if(startMonth === endMonth && startYear === endYear){
            if(startDay === endDay){
                return startMonth + " " + startDay + ", " + startYear + " " + sd.toLocaleTimeString('en-US',{hour12:true, hour:'numeric', minute:'numeric'}).replace(":00", "") + " - " + ed.toLocaleTimeString('en-US',{hour12:true, hour:'numeric', minute:'numeric'}).replace(":00", "");
            }
            return startMonth + " " + startDay + "-" + endDay + ", " + endYear;
        }
        return startMonth + " " + startDay + ", " + startYear + " - " + endMonth + " " + endDay + ", " + endYear;
    }
    if(publicationDate){
        let ret = new Date(publicationDate).toLocaleDateString('en-US',{month:'long', day:'numeric', year:'numeric'});
        if(!addPrefix){
            return ret;
        }
        return "Published " + ret;
    }
    return "";
}

const addBaseUrl = (type: BlogNewsEventType | undefined, slug: string, siteTag: string | undefined) => {
    let url = "/personal/resources/"
    switch(type) {
        case BlogNewsEventType.Blog:
            return url + "blog/" + slug;
        case BlogNewsEventType.News:
            return GetNewsUrl(siteTag) + slug;
        case BlogNewsEventType.Event:
            return url + "events/" + slug;
        case BlogNewsEventType.Videos:
            return "videos/archive/" + slug;
        case BlogNewsEventType.Stories:
            return "stories/archive/" + slug;
        default:
            return url + "blog/" + slug;
    }
}

const GetNewsUrl = (siteTag: string | undefined) => {
    switch(siteTag) {
        case 'websiteVCU':
            return '/personal/resources/news/';
        case 'websiteVCF':
            return '/news/archive/';
        default:
            return  '/personal/resources/news/';
    }
}

const BlogNewsEventsCard = (props: BlogNewsEventsItemModel) => {

    const { id, cardImage, publicationDate = '', siteTag, title, slug = "", cardSummary, pdfAttachment, registrationLink, 
        type, eventName, startDate, endDate }  = props;
    const url = addBaseUrl(type, slug, siteTag);
    const cardClass = type === BlogNewsEventType.Event ? `${styling['event']}` : `${styling['news']}`;
    const signUpModelOverride = {
        type: LinkTypeEnum.Default, 
        defaultLinkText: "Event Details",
        url: registrationLink?.url ?? url,
        showRedirectModal: registrationLink?.showRedirectModal
    }

    return (<div className={`col-12 col-md-6 col-lg-4 ${styling['social-content']}`}  data-contentid={id}>
        <div className={`${styling['card']} ${cardClass}`}>
            <div className={`${styling['card-image']}`} style={{backgroundImage: `url(${cardImage?.imageURL})`}} />
            <div className={`${styling['card-body']}`}>
                <div className={`${styling['date']}`}>{formatDate(false, publicationDate, startDate, endDate)}</div>
                <h3 className={`${styling['card-title']}`}>
                    <SmartLink {...new SmartLinkModel({ url, defaultLinkText:title ?? eventName })} />
                </h3>
                <p>{cardSummary}</p>
                {
                    type === BlogNewsEventType.Blog && <SmartLink url={url} defaultLinkText="Read More" />
                }
                { pdfAttachment && 
                    <SmartLink {...{ url:pdfAttachment.pdfurl, accessibilityDescription:pdfAttachment.title, defaultLinkText:"Download PDF" }} />
                }
                {
                    type === BlogNewsEventType.Event && <SmartLink { ...new SmartLinkModel(signUpModelOverride)} />
                }
            </div>
        </div>
    </div>
    );
}
export default BlogNewsEventsCard;