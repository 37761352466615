import IClientServerModel from "../interfaces/IClientServerModel";
import AccordionItemModel from "./AccordionItemModel";

export default class AccordionGroupModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string;
    public readonly id?: string | undefined;
    public readonly groupName?: string |  undefined;
    public readonly accordionItems?: AccordionItemModel[] | undefined;

    constructor(props: any){
        const {htmlId = "", accordionItems, groupName, id } = props ?? {};
        this.htmlId = htmlId;
        this.id = id;
        this.groupName = groupName;
        this.accordionItems = accordionItems?.length ? accordionItems.map((ac:AccordionItemModel)=> new AccordionItemModel(ac)) : undefined;
    }
}