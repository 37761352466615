import CategoryCardListModel from "./CategoryCardListModel";
import PromotionModel from "./PromotionModel";
import ProductStepwiseDetailModel from "./ProductStepwiseDetailModel";
import CTABarProductModel from "./CTABarProductModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import AnchorsModel from "./AnchorsModel";
import ImageModel from "./ImageModel";
import FeatureModel from "./FeatureModel";
import FAQModel from "./FAQModel";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import DisclosureItemModel from "./DisclosureItemModel";
import CalculatorModel from "./CalculatorModel";
import IEnrichment from "../interfaces/IEnrichment";

export default class ProductDetailSimplePageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;  
    public readonly htmlId?: string | undefined = "";  

    public readonly backgroundImage?: ImageModel | null = null;

    public readonly hero?: PromotionModel | null  | undefined;              
    public readonly stepwise?: null | Readonly<ProductStepwiseDetailModel>; 
    public readonly anchors?: AnchorsModel;
    public readonly feature?: FeatureModel;
    public readonly frequentlyAskedQuestions?: FAQModel;
    public readonly callToActionBar?: null | Readonly<CTABarProductModel>;
    public readonly categoryRelated?: null | Readonly<CategoryCardListModel>;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly calculator?: CalculatorModel | null;
    public readonly title?: string;
    public readonly disclosures?: DisclosureItemModel[];
    
    constructor(props:Readonly<ProductDetailSimplePageModel> | null | undefined) {
        const {htmlId, hero, stepwise, callToActionBar, templateType, frequentlyAskedQuestions, anchors, 
            feature, categoryRelated, backgroundImage, navigationTheme, calculator, title, description, enrichments = [], keywords = [], disclosures = []} = props || {};
        this.htmlId = htmlId;
        this.backgroundImage = new ImageModel(backgroundImage);
        this.hero = new PromotionModel(hero ?? null);
        this.stepwise = new ProductStepwiseDetailModel(stepwise ?? null);
        this.callToActionBar = new CTABarProductModel(callToActionBar ?? null);
        this.templateType = templateType || null;
        this.anchors = new AnchorsModel(anchors);
        this.calculator = new CalculatorModel(calculator);
        this.feature = new FeatureModel(feature);
        this.frequentlyAskedQuestions = new FAQModel(frequentlyAskedQuestions);
        this.categoryRelated = new CategoryCardListModel(categoryRelated);
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description;
        this.title = title;
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
    }
}

