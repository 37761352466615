enum ScrollDirectionEnum{
    // @ts-ignore
    Up = "Up",
    // @ts-ignore
    Down = "Down",
    // @ts-ignore
    None = "None"
}

export default ScrollDirectionEnum;
