import CategoryCardListModel from "./CategoryCardListModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import SideBarModel from "./SideBarModel";
import ImageModel from "./ImageModel";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import DisclosureItemModel from "./DisclosureItemModel";
import IEnrichment from "../interfaces/IEnrichment";
import FormModel from "./FormModel";

export default class GenericPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly body?: string | null | undefined;
    public readonly category?: null | Readonly<CategoryCardListModel>;
    public readonly sidebarLinkGroup?: SideBarModel | null | undefined;
    public readonly backgroundImage?: ImageModel | null = null;
    public readonly contentTitle?: string | null | undefined;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description?: string;
    public readonly title?: string;
    public readonly disclosures?: DisclosureItemModel[];
    public readonly form?: FormModel;

    constructor(props:Readonly<GenericPageModel> | null | undefined) {
        const { htmlId, templateType, sidebarLinkGroup, category, body, contentTitle, form,
            title, description, enrichments = [], keywords = [], disclosures = []} = props || {};
        this.htmlId = htmlId;
        this.category = new CategoryCardListModel(category ?? null);
        this.sidebarLinkGroup = new SideBarModel(sidebarLinkGroup);
        this.templateType = templateType || null;
        this.body = body;
        this.contentTitle = contentTitle;
        this.navigationTheme = ThemeTypeEnum.Dark;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description;
        this.title = title;
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
        this.form = new FormModel(form);
    }
}

