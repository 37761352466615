import IComponentProps from "../../../interfaces/IComponentProps";
import styling from "./RatesFilter.module.scss";

interface IDataTableProps{
    headerTitles: string[],
    bodyRows: (string[])[],
    isCreditCard: boolean
}

export default function DataTable(props: IDataTableProps & IComponentProps)  {
    let {className = "", headerTitles, bodyRows, isCreditCard} = props;
    const creditCardRowClass = isCreditCard ? styling["credit-card-row"] : undefined;
    
    return <table className={`${className}`}>
        <thead>
        <tr className={creditCardRowClass}>
            {headerTitles.map((label, index)=>{
                return <th scope="col" key={index}>{label}</th>;
            })}
        </tr>
        </thead>
        <tbody>
            {bodyRows.map((rowData, index) => {
                return <tr key={index} className={creditCardRowClass}>
                    {rowData.map((item, index) => {
                        return <td key={index}>{item}</td>;
                    })}
                </tr>
            })}
        </tbody>
    </table>
}