enum ContentTypesEnum {
    // @ts-ignore
    None = "",
    // @ts-ignore
    Anchor = "componentAnchors",
    // @ts-ignore
    Accordions = "componentAccordions",
    // @ts-ignore
    ProductCarousel = "componentCarouselLarge",
    // @ts-ignore
    TestimonialCarousel = "componentCarouselTestimonial",
    // @ts-ignore
    CategoryCardList = "componentCategory",
    // @ts-ignore
    CTABarProductDetail = "componentCtaBar",
    // @ts-ignore
    FAQ = "componentFaQs",
    // @ts-ignore
    Feature = "componentFeature",
    // @ts-ignore
    ImageList = "componentImageList",
    // @ts-ignore
    Promotional = "componentPromo",
    // @ts-ignore
    PromoAccordions = "componentPromoAccordions",
    // @ts-ignore
    ProductStepwiseDetail = "componentStepwise",
    // @ts-ignore
    CompareTable = "componentTableCompare",
    // @ts-ignore
    TabsTall = "componentTabsTall",
    // @ts-ignore
    VideoPlayer = "VideoPlayerModel",
    // @ts-ignore
    ContentBlock = "topicContentBlock",
    ComponentContentBlock = "componentContentBlock",
    Disclosure = "topicDisclosure",
    Calculator = "componentCalculator",
    Sitemap = "componentSitemap",
    PersonalizedPromo = "personalized-Promo",
    PersonalizedCategoryCards = "personalized-Category",
    DynamicForm = "componentForm"
}

export default ContentTypesEnum;
