import React, { useEffect, useState } from 'react'
import styles from './Checkbox.module.scss'
import Button from '../Button'
import { ButtonProps } from '../Button/Button'
import HelperText from '../HelperText'
import CheckmarkNoBg from '../../../assets/icons/Action/CheckmarkNoBg'

export type CheckBoxPosition = 'left' | 'right'
export interface CheckboxProps extends ButtonProps {
  id?: string
  name?: string
  value?: boolean
  children?: React.ReactNode
  position?: CheckBoxPosition
  onChecked?: (value: boolean) => void
  errorMessage?: string
  rounded?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  value,
  children,
  position,
  onChecked,
  style,
  className,
  id,
  name,
  errorMessage,
  rounded,
  ...rest
}) => {
  const [checked, setChecked] = useState(value)

  useEffect(() => {
    setChecked(value)
  }, [value])

  return (
    <div id={id} data-name={name}>
      <Button
        {...rest}
        type='text'
        size='small'
        style={{
          ...style,
          padding: 0,
          width: '100%',
          justifyContent: position === 'left' ? 'flex-end' : 'flex-start'
        }}
        block={false}
        className={[styles.itemContainer, className].join(' ')}
        onClick={() => {
          setChecked((oldvalue) => !oldvalue)
          if (onChecked) onChecked(!checked)
        }}
      >
        {position === 'left' && (
          <span className={[styles.itemText, styles.right].join(' ')}>{children}</span>
        )}
        <div
          className={[
            styles.uncheckedDiv,
            rounded && styles.rounded,
            checked && styles.checked
          ].join(' ')}
        >
          <CheckmarkNoBg width={15} height={15} />
        </div>

        {position === 'right' && (
          <span className={[styles.itemText, styles.left].join(' ')}>{children}</span>
        )}
      </Button>
      {errorMessage && (
        <HelperText text={errorMessage} />
      )}
    </div>
  )
}

export default Checkbox

Checkbox.defaultProps = {
  position: 'left',
  value: false,
  rounded: false
}
