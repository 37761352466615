import EventListenerTypeEnum from "../../../../enums/EventListenerTypeEnum";
import getHeaderDomRefs from "./getHeaderDomRefs";
import store from "../../../../redux/store"
// import {
//     PageResize,
// } from "../../../../redux/ducks/globalContentDuck/globalContentDuck_Actions";
import { pageResize, pageScroll } from "../../../../redux/slices/globalSlice";
const throttle = require('lodash.throttle');

let initializationTrackingId: number;

function PageResizeEvent() {
    let refs = getHeaderDomRefs()

    store.dispatch(pageResize({
        isToolbarInitialized: !!refs.toolbar,
        desktopHeaderOffsetHeight: !!refs.desktopHeader ? refs.desktopHeader.offsetHeight : 0,
        alertsOffsetHeight: !!refs.alerts ? refs.alerts.offsetHeight : 0,
        windowScrollY: window.scrollY || window.pageYOffset,
        windowWidth: window.innerWidth
    }))
}

function PageScrollEvent() {
    let refs = getHeaderDomRefs()
    store.dispatch(pageScroll({
            isToolbarInitialized: !!refs.toolbar,
            desktopHeaderOffsetHeight: !!refs.desktopHeader ? refs.desktopHeader.offsetHeight : 0,
            alertsOffsetHeight: !!refs.alerts ? refs.alerts.offsetHeight : 0,
            windowScrollY: window.scrollY || window.pageYOffset,
            windowWidth: window.innerWidth})
    );
}
let throttledPageLoad = throttle(PageResizeEvent,5, {trailing:true, leading:true});
let throttledPageResizeChange = throttle(PageResizeEvent,5, {trailing:true, leading:true});
let throttledPageScrollChange = throttle(PageScrollEvent,5, {trailing:true, leading:true});

export default function ConfigureHeaderDOM(){
    initializationTrackingId = window.setInterval(()=>{
        let refs = getHeaderDomRefs();
        let toolbarExists = !!refs.toolbar
        if(toolbarExists) {
            window.clearInterval(initializationTrackingId);
            window.addEventListener(EventListenerTypeEnum.Load, throttledPageLoad);
            window.addEventListener(EventListenerTypeEnum.Resize, throttledPageResizeChange);
            window.addEventListener(EventListenerTypeEnum.Scroll, throttledPageScrollChange);
        }

    },100)
}
