import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ILocation, IMapParams } from '../../App/components/Locator/LocationInterfaces';

export const pageContentSlice = createSlice({
  name: 'pageContent',
  initialState: {
    blogNewsEventsContainer: [[],[],[],[]],
    allType1Count: 0,
    allType2Count: 0,
    allType3Count: 0,
    type1Count: 0,
    type2Count: 0,
    type3Count: 0,
    type1Max: 7,  // default is one more than 6 so that the load more button is visible
    type2Max: 7,
    type3Max: 7,
    showCookieBanner: false,
    isTrad: true,
    shouldUpdate: true,
    shouldPersonalize: true,
    navigationTheme: 'Dark',
    location: {
      monday: "", 
      tuesday: "", 
      wednesday: "", 
      thursday: "", 
      friday: "", 
      saturday: "", 
      sunday: "", 
      driveThruHours: "", 
      contactDetail: "",
      name: "",
      latitude: 30.37646, 
      longitude: -81.65507,
      isVystar: false,
      atmDescription: ""
    },
    currentLat: 30.37646,
    currentLng: -81.65507,
    currentZoom: 12,
    locationIndex: -1,
    isDepositOnly: false,
    isVystarBranchFilterActive: false,
    isVystarAtmFilterActive: false,
    isSurchargeFreeAtmFilterActive: false,
    getDirectionsStart: "",
    googleSteps: [],
    startAddress: "", 
    endAddress: "",
    locations: [],
    isUpdateFromListOrMarker: false
  },
  reducers: {
    addValues: (state:any, action:any) => {
        return { ...state, ...action.payload};
    },
    toggleCookieBanner: (state:any, action: PayloadAction<boolean>) => {
      const payload: boolean  = action.payload;
      const showCookieBanner = payload;
      return {...state, showCookieBanner};  
    },
    togglePersonalize: (state:any, action: PayloadAction<boolean>) => {
      const payload: boolean  = action.payload;
      const shouldPersonalize = payload;
      return {...state, shouldPersonalize};  
    },
    updateNavigationTheme: (state:any, action: PayloadAction<string>) => {
      const payload: string  = action.payload;
      const navigationTheme = payload;
      return {...state, navigationTheme};  
    },
    updateLocation: (state:any, action:PayloadAction<ILocation>) => {
      const location = action.payload;
      return { ...state, location};
    },
    updateLocationIndex: (state:any, action:PayloadAction<number>) => {
      const locationIndex = action.payload;
      return { ...state, locationIndex};
    },
    updatePin: (state: any, action:PayloadAction<IMapParams>) => {
      const searchParams = action.payload;
      const { lat : currentLat, lng: currentLng, zoom: currentZoom } = searchParams;
      return { ...state, currentLat, currentLng, currentZoom};
    },
  }
});
export const { addValues, toggleCookieBanner, togglePersonalize, updateNavigationTheme, updateLocation, updateLocationIndex, updatePin } = pageContentSlice.actions

export default pageContentSlice.reducer