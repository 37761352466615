import "./Calculator.scss";
import CalculatorModel from '../../../models/CalculatorModel';
import withErrorBoundary from '../../_shared/helpers/withErrorBoundary';
import { useEffect } from "react";
import ContentBlock from "../ContentBlock/ContentBlock";

declare global {
  interface Window { Chimney: any; }
}

export function ChimneyCalculator(props: CalculatorModel) {
  let { htmlId, id, topicCalculator, header, contentBlock } = props;
  let calculatorCode = topicCalculator.calculatorCode;
  
  useEffect(()=>{
    window.Chimney.calculators.createCalc("sgi", calculatorCode)
  }, [])

  return (
      <div className="calc-layout" data-contentid={id} id={htmlId}>
        <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                  <div className="calc-intro text-center">
                      <h2>{header}</h2>
                      <ContentBlock {...contentBlock} />
                  </div>
              </div>
              </div>
              <div className="row justify-content-center">
                <div className="calc-intro text-center">
                  <div id='sgi' className="chimney_calc" data-guid={calculatorCode} /> 
                </div>
            </div>
        </div>
      </div>
  )
}

export default withErrorBoundary(ChimneyCalculator);