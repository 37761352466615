import eraseCookie from "./eraseCookie";
import setCookie from "./setCookie";

const DoNotTrack = () => {
    // @ts-ignore
    const { doNotTrack, globalPrivacyControl } = navigator;
    if(doNotTrack || globalPrivacyControl){
        setCookie("google-analytics-opt-out", "true", 7300);
    }else{
        eraseCookie("google-analytics-opt-out");
    }
}
export default DoNotTrack;