
import ImageModel from "./ImageModel";
import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";

export default class ImageListItemModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly subText?: string = "";
    public readonly image: ImageModel | undefined | null = null;
    public readonly link?: SmartLinkModel | undefined | null = null;

    constructor(props:ImageListItemModel | null){
        const {htmlId = "", subText = "", image = "", link} = props || {};
        this.htmlId = htmlId;
        this.subText = subText;
        this.image = image ? new ImageModel(image) : null;
        this.link = link ? new SmartLinkModel(link) : null;
    }
}


