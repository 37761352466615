import IClientServerModel from "../interfaces/IClientServerModel";
import SmartLinkModel from "./SmartLinkModel";

export default class CTALinkGroup implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId: string | undefined = "";
    public readonly label?: string | undefined = "";
    public readonly primaryLinks?: SmartLinkModel[] | null | undefined;
    public readonly secondaryLinks?: SmartLinkModel[] | null | undefined;

    constructor(contentfulFAQModel: Readonly<CTALinkGroup> | undefined | null){
        const {htmlId = "", label, primaryLinks, secondaryLinks} = contentfulFAQModel || {};
        this.htmlId = htmlId;
        this.label = label;
        this.primaryLinks = primaryLinks?.map(item => new SmartLinkModel(item)) || [];
        this.secondaryLinks = secondaryLinks?.map(item => new SmartLinkModel(item)) || [];
    }
}

