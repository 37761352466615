import ThemeTypeEnum from "../../../enums/ThemeTypeEnum";

const CalculateNavigationTheme = (heroTheme: ThemeTypeEnum) => {
    var navTheme = ThemeTypeEnum.Default;
    if(heroTheme) {
        if (heroTheme === ThemeTypeEnum.Light) {
            navTheme = ThemeTypeEnum.Dark;
        }
        
        else if (heroTheme === ThemeTypeEnum.Dark) {
            navTheme = ThemeTypeEnum.Light;
        }
        else {
            navTheme = ThemeTypeEnum.Dark
        }
    }
    
    return navTheme;
}
export default CalculateNavigationTheme;