import store from "../redux/store";
import BrowserSupportFeatureEnum from "../enums/BrowserSupportFeatureEnum";
import { browserSupportSettingUpdate } from "../redux/slices/systemSlice";

interface IBrowserSupportSettings{
    [BrowserSupportFeatureEnum.webp]: boolean | null
}

let browserSupportSettings:IBrowserSupportSettings = {
    [BrowserSupportFeatureEnum.webp]: null
}

let completionPromise: Promise<any> | null = null;

const supportsWebp = (function () {

	var index = new Promise(resolve => {
		const image = new Image();
		image.addEventListener('error', () => resolve(false));
		image.addEventListener('load', () => resolve(image.width === 1));
		image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
	}).catch(() => false);

	return index;

}());

class BrowserSupport{
    static async init(){
        if(!completionPromise) {
            completionPromise = supportsWebp.then((supported:any) => {
                const payload = {
                    webp: supported
                }
                store.dispatch(browserSupportSettingUpdate(payload));
                if(supported){
                    sessionStorage.setItem("webp", "?fm=webp&q=80");
                }
                browserSupportSettings[BrowserSupportFeatureEnum.webp] = supported;
            });
        }
        return completionPromise;
    }

    static isSupported(feature:BrowserSupportFeatureEnum):boolean{
        return !!browserSupportSettings[feature];
    }
}

export default BrowserSupport;



