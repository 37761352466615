enum ScriptedDomIdsEnum{
    // @ts-ignore
    DesktopHeader = "desktop-header",
    // @ts-ignore
    Alerts = "alerts",
    // @ts-ignore
    HeaderSpacer = "header-spacer",
    // @ts-ignore
    Toolbar = "toolbar",
    // @ts-ignore
    MobileHeader = 'mobile-header',
    // @ts-ignore
    Main = 'main',

}

export default ScriptedDomIdsEnum;
