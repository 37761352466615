import React from 'react'
import { Base } from '../../../interfaces/base'
import styles from './Alert.module.scss'

export type AlertType = 'success' | 'warning' | 'info' | 'error'
export interface AlertProps extends Base {
  type?: AlertType
  children?: React.ReactNode
  title?: string
}

const Alert: React.FC<AlertProps> = ({
  type = 'warning',
  children,
  style,
  title,
  className
}): JSX.Element | null => {
  return (
    <div
      style={style}
      className={[className && styles[className], styles.alertContainer, styles[type]].join(' ')}
    >
      {title && <span className={styles.alertTitle}>{title}</span>}
      {children}
    </div>
  )
}

export default Alert
