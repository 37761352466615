import React, {useState} from "react";
import IComponentProps from "../../../interfaces/IComponentProps";
import IResponsive from "../../../interfaces/IResponsive";
import QuestionAnswerModel from "../../../models/QuestionAnswerModel";
import FaqItem from "./FaqItem";
import FaqModal from "../_shared/Dialogs/FaqModal";
import Markdown from "markdown-to-jsx";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";

export function FaqItemContainer(props: (QuestionAnswerModel & IResponsive & IComponentProps & {index: number | null})) {
    let {question, answer, index, windowWidth} = props;
    const [modalIndex, setModalIndex] = useState(null as Number|null);

    let questionContent = <Markdown>{question ?? ""}</Markdown>;
    let bodyContent: React.ReactNode = !!answer ? documentToReactComponents(answer, ContentfulReactRenderOptions) : <span/>;
    
    return <>
        <div className="col-12 col-lg-6 faq-item-container">
            <FaqItem question={question} answer={answer} onRequestDetails={()=>setModalIndex(index)} windowWidth={windowWidth}/>
            <FaqModal isOpen={index === modalIndex} header={questionContent} body={bodyContent} onRequestClose={()=>setModalIndex(null)} />
        </div>
    </>;
}
