import PageTemplateEnum from "./enums/PageTemplateEnum";
import HomePage from "./App/pages/_pageTemplates/HomePage";
import ProductDetailComplexPage from "./App/pages/_pageTemplates/ProductDetailComplexPage";
import ProductDetailSimplePage from "./App/pages/_pageTemplates/ProductDetailSimplePage";
import SomethingWentWrongPage from "./App/pages/_pageTemplates/SomethingWentWrongPage";
import PageNotFoundPage from "./App/pages/_pageTemplates/PageNotFoundPage";
import IComponentProps from "./interfaces/IComponentProps";
import IPageRequestData from "./interfaces/IPageRequestData";
import HomePageModel from "./models/HomePageModel";
import ProductDetailSimplePageModel from "./models/ProductDetailSimplePageModel";
import ProductDetailComplexPageModel from "./models/ProductDetailComplexPageModel";
import PageNotFoundPageModel from "./models/PageNotFoundPageModel";
import SomethingWentWrongPageModel from "./models/SomethingWentWrongPageModel";
import CampaignLandingPageModel from "./models/CampaignLandingPageModel";
import CampaignLandingPage from "./App/pages/_pageTemplates/CampaignLandingPage";
import LandingPageModel from "./models/LandingPageModel";
import LandingPage from "./App/pages/_pageTemplates/LandingPage";
import BlogPageModel from "./models/BlogPageModel";
import BlogPage from "./App/pages/_pageTemplates/BlogPage";
import CategoryPage from "./App/pages/_pageTemplates/CategoryPage";
import CategoryPageModel from "./models/CategoryPageModel";
import ComparePage from "./App/pages/_pageTemplates/ComparePage";
import ComparePageModel from "./models/ComparePageModel";
import GenericPageModel from "./models/GenericPageModel";
import GenericPage from "./App/pages/_pageTemplates/GenericPage";
import BiographyPageModel from "./models/BiographyPageModel";
import BiographyPage from "./App/pages/_pageTemplates/BiographyPage";
import RateDetailPage from "./App/pages/_pageTemplates/RatesDetailPage/RateDetailPage";
import RateDetailPageModel from "./models/RateDetailPageModel";
import LocationsPage from "./App/pages/_pageTemplates/LocationsPage";
import LocationsPageModel from "./models/LocationsPageModel";
import ContentLoadingPage from "./App/pages/_pageTemplates/ContentLoadingPage/ContentLoadingPage";
import ContentLoadingPageModel from "./models/ContentLoadingPageModel";
import BlogNewsEventsPageModel from "./models/BlogNewsEventsPageModel";
import BlogNewsEventsPage from "./App/pages/_pageTemplates/BlogNewsEventsPage";
import DynamicPage from "./App/pages/_pageTemplates/DynamicPage";
import DynamicPageModel from "./models/DynamicPageModel";
import ContactPage from "./App/pages/_pageTemplates/ContactPage";
import ContactPageModel from "./models/ContactPageModel";
import AccessRestrictedPage from "./App/pages/AccessRestrictedPage/AccessRestrictedPage";
import AccessRestrictedPageModel from "./models/AccessRestrictedPageModel";
import createAssociativeArray from "./redux/_shared/helpers/createAssociativeArray";
import AnyPageModel from "./redux/_shared/helpers/AnyPageModel.type";

export interface IPageTemplateConfigEntry{
    component: (props: IComponentProps & IPageRequestData) => JSX.Element,
    model: AnyPageModel & Function,
    redirectUrl?: string
}

const _templatePageConfig = createAssociativeArray<PageTemplateEnum, IPageTemplateConfigEntry>()
    .set(PageTemplateEnum.Dynamic, {component: DynamicPage, model:DynamicPageModel})
    .set(PageTemplateEnum.RatesDetail, {component: RateDetailPage, model:RateDetailPageModel})                              
    .set(PageTemplateEnum.Biography, {component: BiographyPage, model:BiographyPageModel})                                  
    .set(PageTemplateEnum.Blog, {component: BlogPage, model:BlogPageModel})                                                 
    .set(PageTemplateEnum.CampaignLanding, {component: CampaignLandingPage, model:CampaignLandingPageModel})                
    .set(PageTemplateEnum.Category, {component: CategoryPage, model:CategoryPageModel})                                     
    .set(PageTemplateEnum.Compare, {component: ComparePage, model:ComparePageModel})                                        
    .set(PageTemplateEnum.Generic, {component: GenericPage, model:GenericPageModel})                                        
    .set(PageTemplateEnum.Home, {component:HomePage, model:HomePageModel})                                                  
    .set(PageTemplateEnum.Landing, {component: LandingPage, model:LandingPageModel})                                        
    .set(PageTemplateEnum.PageNotFound, {component: PageNotFoundPage, model:PageNotFoundPageModel})                         
    .set(PageTemplateEnum.ContentLoading, {component: ContentLoadingPage, model:ContentLoadingPageModel})                   
    .set(PageTemplateEnum.ProductDetailComplex, {component: ProductDetailComplexPage, model:ProductDetailComplexPageModel}) 
    .set(PageTemplateEnum.ProductDetailSimple, {component: ProductDetailSimplePage, model:ProductDetailSimplePageModel})    
    .set(PageTemplateEnum.SomethingWentWrong, {component: SomethingWentWrongPage, model:SomethingWentWrongPageModel})       
    .set(PageTemplateEnum.BlogNewsEvents, {component: BlogNewsEventsPage, model:BlogNewsEventsPageModel}) 
    // @ts-ignore                  
    .set(PageTemplateEnum.Locations, {component: LocationsPage, model:LocationsPageModel})
    .set(PageTemplateEnum.Contact, {component: ContactPage, model:ContactPageModel})
    .set(PageTemplateEnum.AccessRestricted, {component: AccessRestrictedPage, model: AccessRestrictedPageModel})
export default _templatePageConfig;