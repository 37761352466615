
import "slick-carousel/slick/slick.css"
import styles from "./ProductCarousel.module.scss"
import ProductPanel from "./ProductPanel/ProductPanel";
import Carousel from "../_shared/Carousel/Carousel";
import PrevArrowIcon from "./images/product-carousel-arrow-prev.svg";
import NextArrowIcon from "./images/product-carousel-arrow-next.svg";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import ProductCarouselModel from "../../../models/ProductCarouselModel";
import ProductDetailModel from "../../../models/ProductDetailModel";
import { Component } from "react";

export default withErrorBoundary(class ProductCarousel extends Component<ProductCarouselModel, {currentIndex?:number, carouselMovedOnce?:boolean}>{

    private slider: any;
    state = {
        currentIndex:0,
        carouselMovedOnce: false
    };

    render() {
        const {id, htmlId, header, content=[]} = this.props || {};
        let tabLabels = content.some(p => !!p.tabLabel) ?
            content.map(p=>p.tabLabel) : null;
        let getIndexNavFn = (index:number)=> () => {
            this.slider.slickGoTo(index);
        };

        const {currentIndex = 0} = this.state || {};

        return (
            <div id={htmlId} className={`layout  ${styles['c-product-carousel']} no-padding-bottom`} data-contentid={id}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className={`${styles.intro} intro text-center`}>
                                <h2>{header}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={"product-carousel-nav"} >
                                {tabLabels && tabLabels.map((tabLabel, index)=>{
                                    return <button key={tabLabel} onClick={getIndexNavFn(index)} className={`${styles.active} ${(currentIndex === index) ? 'active' : ''}`} type="button" data-slide="1">{tabLabel}</button>
                                })}
                            </div>
                        </div>
                    </div>
                    <Carousel sliderRef={(slider:any) => (this.slider = slider )}
                        className={`row ${styles['product-carousel']}`}
                        prevIcon={PrevArrowIcon}
                        nextIcon={NextArrowIcon}
                        tabLabels={tabLabels}
                        beforeChange = {(current:number, next:number) => {
                            this.setState({ currentIndex: next});
                        }}
                        afterChange = {(current:number) => {
                            this.setState({ currentIndex: current, carouselMovedOnce:true });
                        }}
                    >
                        {content && content.map((productDetail:ProductDetailModel, index: number) =>{
                            return <ProductPanel id={productDetail.htmlId} key={productDetail.htmlId} {...productDetail} isActive={index === this.state.currentIndex} canFocus={this.state.carouselMovedOnce}/>;
                        })}
                    </Carousel>
                </div>
            </div>
        );
    }
});
