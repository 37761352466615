
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import withErrorBoundary from "../../_shared/helpers/withErrorBoundary";
import styles from "./CategoryCardList.module.scss";
import CategoryCardListModel from "../../../models/CategoryCardListModel";
import IComponentProps from "../../../interfaces/IComponentProps";
import CardSimple from "../_shared/CardSimple/CardSimple";
import CategoryCardTypeEnum from "../../../enums/CategoryCardTypeEnum";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import ImageSizeEnum from "../../../enums/ImageSizeEnum";
import ParseContentID from "../../_shared/helpers/ParseContentID";

export function CategoryCardListRender(props: CategoryCardListModel & IComponentProps)  {
    let {id, content, className, htmlId, background, categoryType, header, subHeader, imageSize} = props;
    let categoryTypeClass;
    id = ParseContentID(id);
    let cardSizeClass = (imageSize === ImageSizeEnum.Large) ? styles["card-large"] : styles["card-small"];
    switch(categoryType){
        case CategoryCardTypeEnum.Filled:
            categoryTypeClass = styles["c-category-filled"];
            break;
    }
    return content?.length ? <div id={htmlId} style={{backgroundImage: `url(${background?.imageURL})`}} className={`layout ${cardSizeClass} ${styles['c-category']} ${className} ${categoryTypeClass}`} data-contentid={id}>
        <div className="container">
            <div className="row justify-content-center">
                {header &&
                <div className="col-12 col-lg-8">
                    <div className="intro text-center">
                        <h2>{header}</h2>
                        {subHeader && documentToReactComponents(subHeader, ContentfulReactRenderOptions)}
                    </div>
                </div>}
            </div>
                <div className={`row filled g-0`}>
                    {content?.map((card, index)=>{
                        return  <div className={'column'} key={index}>
                            {<CardSimple {...card} styleOverrides={styles} />}
                        </div>
                    })}
                </div>
        </div>
    </div>:<></>;
}
CategoryCardListRender.displayName = "CategoryCardList";
export default withErrorBoundary(CategoryCardListRender);