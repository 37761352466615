import IClientServerModel from "../interfaces/IClientServerModel";
import DisclosureItemModel from "./DisclosureItemModel";
import RateLineModel from "./RateLineModel";

export default class RateAccountModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly name?: string | undefined = "";
    public readonly accountType?: string | undefined = "";
    public readonly shortDescription?: string | undefined = "";
    public readonly longDescription?: string | undefined = "";
    public readonly filterLabel?: string | undefined = "";
    public readonly allFilterSelection?: string | undefined = "";
    public readonly effectiveDate?: string | undefined = "";
    public readonly advertiesedRateType?: string | undefined = "";
    public readonly accountDetail?: any | undefined = "";
    public readonly lineOfBusiness?: any | undefined = "";
    public readonly rateLines?: RateLineModel[];
    public readonly disclosures: DisclosureItemModel[];
    public readonly legalTexts: DisclosureItemModel[];

    constructor(props:RateAccountModel | null){
        const {
            htmlId = "",
            name = "",
            accountType = "",
            shortDescription = "",
            longDescription = "",
            filterLabel = "",
            allFilterSelection = "",
            effectiveDate = "",
            advertiesedRateType = "",
            accountDetail = null,
            lineOfBusiness = null,
            rateLines = [],
            disclosures = [],
            legalTexts = []
        } = props || {};

        this.htmlId = htmlId;
        this.name = name;
        this.accountType = accountType;
        this.shortDescription = shortDescription;
        this.longDescription = longDescription;
        this.filterLabel = filterLabel;
        this.allFilterSelection = allFilterSelection;
        this.effectiveDate = effectiveDate;
        this.advertiesedRateType = advertiesedRateType;
        this.accountDetail = accountDetail;
        this.lineOfBusiness = lineOfBusiness;
        this.rateLines = rateLines?.map(rate => {
            return new RateLineModel(rate);
        }) || [];
        this.disclosures = disclosures?.map(disclosure => {
            return new DisclosureItemModel(disclosure);
        }) || [];
        this.legalTexts = legalTexts?.map(disclosure => {
            return new DisclosureItemModel(disclosure);
        }) || [];
    }
}


