import IComponentProps from '../../../interfaces/IComponentProps';
import SideBarModel from '../../../models/SideBarModel';
import Share from '../Share/Share';
import SideBar from '../SideBar/SideBar';
import styling from './ComponentContentBlock.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import withErrorBoundary from '../../_shared/helpers/withErrorBoundary';

export interface pageContent {
  id: string,
  htmlId: string,
  name: string,
  content: any,
  sidebar: SideBarModel
}

export function ComponentContentBlock(props: (pageContent & IComponentProps)){
  let { id, htmlId, content, sidebar } = props;
  let componentContent = documentToReactComponents(content.content);
  return (              
  <div id={htmlId} data-contentid={id} className={`layout ${styling['content-block']} ${styling['generic']} ${styling['simplified']}`}>
      <article>
        <div className={"container"} >
            <div className="row mx-auto">
                <div className="col-lg-8">
                      {componentContent}
                </div>
                <div className="col-lg-3 offset-lg-1 d-none d-lg-block">
                    <Share />
                    <SideBar {...sidebar} />
                </div>
            </div>
        </div>
    </article>
</div>);
}
export default withErrorBoundary(ComponentContentBlock);
