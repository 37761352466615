import { Route, BrowserRouter, Routes} from "react-router-dom";
import { showSearch } from './environmentVariables';
import ContentPage from "./pages/ContentPage";
import SearchPage from './pages/SearchPage/SearchPage';
import PageNotFoundPage from "./pages/_pageTemplates/PageNotFoundPage";

const AppRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>
            <Route path="/search-results" element={ showSearch ? <SearchPage />: <PageNotFoundPage />}>                
            </Route>
            <Route path="*" element={<ContentPage/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;
