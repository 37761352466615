import ErrorBoundary from "../../components/_shared/ErrorBoundary/ErrorBoundary";

export default function WithErrorBoundary(FunctionComponent){

    const WrappedComponent = function (props){
        return <ErrorBoundary>
            <FunctionComponent {...props}/>
        </ErrorBoundary>
    };
    WrappedComponent.displayName = (FunctionComponent.displayName || FunctionComponent.name) + "Container";
    return WrappedComponent;
}

