
import IComponentProps from "../../../../interfaces/IComponentProps";
import NavigationItemsModel from "../../../../models/NavigationItemsModel";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import "./Footer.module.scss";
import LinkList from "../../../components/_shared/LinkList/LinkList";

interface INavigationItems {
    navigationItems: Array<NavigationItemsModel>;
}

export function NavigationItems(props: INavigationItems & IComponentProps) {
    const { navigationItems } = props;
    return (
        <div className="row">
            <div className="col-12">
                <div className="grid-columns">
                    {navigationItems?.map((navItem, index) => {
                        return (
                            <div className="item column" key={String(index)}>
                                <div className="header-six">{navItem.label}</div>
                                <LinkList links={navItem?.links} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
export default withErrorBoundary(NavigationItems);
