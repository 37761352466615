import { waitForDomChangeAsync } from "../_shared/wait/wait";
import envDataConfig from "../../EnvDataConfig/EnvDataConfig";

export default async function loadGTMAsync(){    
    require('./GTMInit');
    if(envDataConfig.isGTMEnabled) {
        return waitForDomChangeAsync(() => {
            let gtmScriptRender = document.querySelector('#gtmInit')
            let isComplete = !!gtmScriptRender;
            return isComplete;
        }, 'head', { childList: true })
    }
    else{
        return Promise.resolve();
    }
}