
import stylesBase from '../_PromotionalBase.module.scss';
import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import LinkButtonList from "../../_shared/LinkButtonList/LinkButtonList";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import PromotionModel from "../../../../models/PromotionModel"
import ImageModel from "../../../../models/ImageModel";
import SideAlignmentEnum from "../../../../enums/SideAlignmentEnum";
import styles from "../PromoTwoThirds/PromoTwoThirds.module.scss";
import CalculateNavigationTheme from '../../../_shared/helpers/CalculateNavigationTheme';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateNavigationTheme } from '../../../../redux/slices/pageContentSlice';
import ThemeTypeEnum from '../../../../enums/ThemeTypeEnum';

export function PromoSimpleBaseRender(props: PromotionModel & IComponentProps)  {
    const { id, htmlId, theme, primary, className = "", textAlignment, content = {}} = props;
    const { header = "", secondaryHeader = "", links = [],
        image = new ImageModel(null), body
    } = content || {};
    const layoutAlignmentClassName = textAlignment === SideAlignmentEnum.Left ? "" : "offset";
    const colAlignmentClassName = textAlignment === SideAlignmentEnum.Left ? "" : "offset-lg-6";

    const {
        imageURL: backgroundImageURL,
        description: backgroundDescription
    } = image || new ImageModel(null);
    const backgroundStyle={
        backgroundImage: backgroundImageURL && `url(${backgroundImageURL})`
    };

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    return (<div id={htmlId} aria-label={backgroundDescription} style={backgroundStyle}
                 className={`${layoutAlignmentClassName} ${stylesBase['c-promo']} ${className} layout`} data-contentid={id}>
                <div className={`${styles['container']} container`}>
                    <div className="row align-items-center">
                        <div className={`col-12 col-lg-6 ${colAlignmentClassName}`}>
                            {secondaryHeader && <div className="eyebrow">{secondaryHeader}</div>}
                            <h2>{header}</h2>
                            {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                            <LinkButtonList links={links}/>
                        </div>
                    </div>
                </div>
            </div>);
}
PromoSimpleBaseRender.displayName = "PromoSimpleBase";
export default withErrorBoundary(PromoSimpleBaseRender);