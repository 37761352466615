import React, {useEffect, useRef, useState} from "react";
import IComponentProps from "../../../../interfaces/IComponentProps";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import "./_styles/MobileMenu.module.scss";
import "./_styles/slinkyMenu.module.scss";
import NavMenuModel from "../../../../models/NavMenuModel";
import MenuItem from "./MenuItem";
import NavToolbarModel from "../../../../models/NavToolbarModel";
import IThemedDisplay from "../../../../interfaces/IThemedDisplay";
import MenuBar from "./MenuBar";
import SubMenuTabs from "./SubMenuTabs";
import SubMenuToolbar from "./SubMenuToolbar";
import NavMenuItemModel from "../../../../models/NavMenuItemModel";
import useWindowSize from "../../../../useWindowSize";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";
import { RootState } from "../../../../redux/store";
import { connect } from "react-redux";

interface IMapStateProps {
    navigationTheme: string
}

interface IMobileMenuViewModel extends NavMenuModel, NavToolbarModel, IComponentProps, IThemedDisplay{
    toggleMobileMenu:  ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined,
    setActiveIndex: (index: number | null) => void,
    isMobileSubmenuOpen: boolean,
    activeIndex: number | null,
    activeTabIndex: number,
    isLoginModalVisible: boolean,
    navMenus: NavMenuModel[],
    navigationTheme: string
}

export const mapStateToProps = (state: RootState, ownProps?: any): IMapStateProps => {
    const {navigationTheme} = state.pageContent || {};
    return {
        navigationTheme
    }
}

export function MobileMenuRender(props: IMobileMenuViewModel & IMapStateProps) {    
    let {toggleMobileMenu, navigationTheme, activeIndex, isMobileSubmenuOpen, activeTabIndex: defaultTabIndex} = props;
    let { setActiveIndex,
        navMenus = [], theme, navigationItems, mobileCTA,
        isLoginModalVisible, tabs = [],
    } = props;
    let routingNumber = props?.routingNumber?.value;
    let submenuStatusClassName = isMobileSubmenuOpen ? "open" : "";
    const [selectedNavMenuIndex, setSelectedNavMenuIndex] = useState(0);
    let selectedNavMenu = (navMenus??[])[selectedNavMenuIndex];
    
    let logo = selectedNavMenu?.logo;
    if (navigationTheme === ThemeTypeEnum.Light) {
        logo = selectedNavMenu?.logoSecondary;
    }

    const menuItems = selectedNavMenu?.content || [];
    let hasSpecialCTA =  menuItems[menuItems.length -1]?.callToAction !== null;
    let accountCreationLink = null;
    const initialArray = !!selectedNavMenuIndex ? [new NavMenuItemModel({titleLink:tabs[selectedNavMenuIndex]})] : [];
    let mobileMenuItems = initialArray.concat(menuItems);
    if(hasSpecialCTA) {
        accountCreationLink = mobileMenuItems?.pop()?.callToAction;
    }
    // 1. Get size of window:

    const { height: windowHeight } = useWindowSize();

    // 2. Get height of mobileMenu div:

    const mobileMenuRef = useRef(null);
    const {clientHeight: mobileMenuHeight} = mobileMenuRef.current ?? {clientHeight:undefined};

    // 3. compare window height to mobileMenu after window opens, add/remove styling

    const [mobileMenuStyle, setMobileMenuStyle] = useState({});

    useEffect(() => {
        if ((windowHeight ?? 0) < (mobileMenuHeight ?? 0)){
            if(isMobileSubmenuOpen){
                setMobileMenuStyle({height: windowHeight, overflowY: 'scroll'});
            }
        }else{
            if(!isMobileSubmenuOpen){
                setMobileMenuStyle({});
            }
        }
    }, [ windowHeight, mobileMenuHeight, setMobileMenuStyle, isMobileSubmenuOpen]);
    
    return (
        <div id="mobile-header">
            <MenuBar {...{logo, mobileCTA, submenuStatusClassName,onClick:toggleMobileMenu, isLoginModalVisible }}  />
            <div id={`mobileMenu`} className={`${submenuStatusClassName}`} ref={mobileMenuRef} style={mobileMenuStyle}>
                <div className="mobile_tabs_container">
                    <SubMenuTabs {...{tabs, defaultTabIndex, isSubMenuOpen: isMobileSubmenuOpen}} onTabChange={(index=> setSelectedNavMenuIndex(index))}/>
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-12">
                                <div className="mm-container" data-mm-index="0">
                                    <div id="personal" className="slinky-menu" style={{transitionDuration: "300ms"}}>
                                        <ul style={{transitionDuration: "300ms"}}
                                            className={`menu-items ${activeIndex !== null ? 'submenu-active' : ''}`}>
                                            {mobileMenuItems?.map((i, index) => {
                                                return <MenuItem key={index} {...{
                                                    index,
                                                    indexCount: menuItems.length,
                                                    activeIndex,
                                                    setActiveIndex, ...i
                                                }} />
                                            })}
                                            <SubMenuToolbar links={navigationItems} routingNumber={routingNumber} linkData={accountCreationLink} />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


MobileMenuRender.displayName = "MobileMenu";
export default connect(mapStateToProps) (withErrorBoundary (MobileMenuRender));