import ImageModel from "./ImageModel";
import SmartLinkModel from "./SmartLinkModel";
  
export default class SubEventModel {
    
    public readonly _clientType?: string = this.constructor.name;
    public readonly eventName?: string;
    public readonly startDate?: string;
    public readonly endDate?: string;
    public readonly image?: ImageModel;
    public readonly eventLocation?: string;
    public readonly registrationLink?: SmartLinkModel;
    public readonly directionsLink?: SmartLinkModel;

    constructor(props: Readonly<SubEventModel> | undefined | null){

        const {image, eventName, eventLocation, registrationLink, directionsLink, startDate, endDate} = props ?? {};

        this.eventName = eventName;
        this.eventLocation = eventLocation;
        this.startDate = startDate;
        this.endDate = endDate;
        this.image = new ImageModel(image);
        this.registrationLink = registrationLink ? new SmartLinkModel(registrationLink) : undefined;
        this.directionsLink =  directionsLink ? new SmartLinkModel(directionsLink): undefined;
    }
}