import { IFormField } from "../App/components/Form/FormField";
import { IFormAction, IFormMessaging } from "../App/components/Form/FormInterfaces";
import IClientServerModel from "../interfaces/IClientServerModel";
import { Document as RichText } from "@contentful/rich-text-types";

export interface IGroupForms{
    htmlId: string;
    groupName: string;
    groupDescription: RichText;
    formFields: IFormField[];
}

export default class FormModel implements IClientServerModel{
    public readonly _clientType?: string = this.constructor.name;
    public readonly htmlId?: string | undefined = "";
    public readonly id?: string | undefined;
    public readonly containerName?: string | undefined;
    public readonly captcha?: boolean |  undefined;
    public readonly containerDescription?: RichText | undefined;
    public readonly groupForms?: IGroupForms[] | undefined;
    public readonly formAction?: IFormAction | undefined;
    public readonly formMessages?: IFormMessaging | undefined;
    constructor(contentfulFeatureModel: Readonly<FormModel> | undefined | null){
        const {htmlId = "", id, containerName, containerDescription, groupForms, captcha, formAction, formMessages} = contentfulFeatureModel || {}
        this.htmlId = htmlId;
        this.id = id;
        this.containerName = containerName; 
        this.containerDescription = containerDescription; 
        this.groupForms = groupForms; 
        this.captcha = captcha;
        this.formAction = formAction;
        this.formMessages = formMessages;
    }
}

